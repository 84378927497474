import React, { useContext, useEffect, useState } from "react";
import StepIndicators from "../step-indicators";
import SummaryPanel from "../summary-panel/booking";
import GlobalContext, { GlobalContextType } from "../../contexts/global-provider";
import translate from "../../utils/translate";
import translations from "./translations.json";
import BookingContext, { BookingContextType } from "../../contexts/booking-provider";
import {
  TideItemForEvenementLocatieConnection,
  TideItemForExcursionConnection,
  TideItemForFacility,
  TideItemForHotel,
  TideItemForToernooi,
  TideItemForWebsite,
  TideItemForWedstrijd,
} from "../../types";
import { parse } from "../../utils/component";
import BookingStep1 from "./steps/booking-step1";
import BookingStep2 from "./steps/booking-step2";
import BookingStep3 from "./steps/booking-step3";
import BookingStep4 from "./steps/booking-step4";
import BookingStep5 from "./steps/booking-step5";
import BookingStep6, { PolicyChecks } from "./steps/booking-step6";
import BookingStep7 from "./steps/booking-step7";
import { Image } from "../../types";
import Helmet from "react-helmet";
import Preloader from "../preloader";
import { graphql, navigate, useStaticQuery } from "gatsby";
import { first, isEmpty, last, uniq } from "lodash";
import Link from "../link";
import Icon from "../icon";
import { differenceInDays } from "date-fns";
import { getBookableDates } from "../../services/product-service";
import { ServiceType } from "@qite/tide-client";
import { createDataLayerItemsFromDetails, getSubserviceFromPackage } from "../../utils/package-utils";

interface BookingProps {
  bookingNr?: string;
  bookingAllotmentName?: string;
  bookingAmount?: number;
  website?: TideItemForWebsite;
  event?: TideItemForWedstrijd;
  tournament?: TideItemForToernooi;
  venueId?: string;
  backgroundImage?: Image;
  homeTeamImage?: Image;
  awayTeamImage?: Image;
  cmsHotels?: TideItemForHotel[];
  adultsOnly?: boolean;
}

declare global {
  interface Window {
    dataLayer: any;
  }
}

const Booking: React.FC<BookingProps> = ({
  bookingNr,
  bookingAllotmentName,
  bookingAmount,
  website,
  event,
  tournament,
  venueId,
  backgroundImage,
  homeTeamImage,
  awayTeamImage,
  cmsHotels,
  adultsOnly,
}) => {
  const { language, affiliate, member } = useContext<GlobalContextType>(GlobalContext);
  const {
    setBookEvent,
    bookingSearch,
    bookingPackage,
    setBookingPackage,
    flyInIsOpen,
    roomDistributionErrors,
    noPackageFound,
    setNoPackageFound,
    hotelsLoaded,
    setHotelsLoaded,
    setFlightsLoaded,
    bookingPackageDossier,
    lastSuccesfulBookingPackage,
    activeOutwardDate,
    setActiveOutwardDate,
    activeReturnDate,
    setActiveReturnDate,
    bookableDates,
    setBookableDates,
    hotelPriceChanged,
    setHotelPriceChanged,
    noFlightsFound,
    setNoFlightsFound,
    setSearchAltHotel,
    defaultFlightNotAvailable,
    setDefaultFlightNotAvailable,
    flightPriceChanged,
    setFlightPriceChanged,
    defaultTicketNotAvailable,
    setDefaultTicketNotAvailable,
    setFetchPrice,
    setShouldCheckTickets,
    setShouldCheckFlights,
    activeStep,
    setActiveStep,
    showCachedPrices,
    requestRooms,
    setRequestRooms,
    packageOnlyTicket,
    initialServiceTypes,
    hotels,
    setHotels,
    setResetRooms,
  } = useContext<BookingContextType>(BookingContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [formIsValid, setFormIsValid] = useState<boolean>(false);
  const [policyChecks, setPolicyChecks] = useState<PolicyChecks>({
    identity: false,
    readAndAgree: false,
  } as PolicyChecks);
  const [policyChecksValid, setPolicyChecksValid] = useState(true);
  const [validBookingPackage, setValidBookingPackage] = useState(true);

  // bookable dates ophalen
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    (async () => {
      if (bookingSearch && !noPackageFound) {
        const bookingDetails = bookingPackage?.options.find((o) => o.isSelected);
        const code = bookingDetails ? bookingDetails.code : bookingSearch?.code;
        const allotmentTourCode = bookingDetails ? bookingDetails.allotmentTourCode : bookingSearch?.allotment.tourCode;
        const fromDate = bookingDetails ? bookingDetails.fromDate : bookingSearch?.fromDate;
        const toDate = bookingDetails ? bookingDetails.toDate : bookingSearch?.toDate;
        if (packageOnlyTicket) {
          setActiveOutwardDate(fromDate);
          setActiveReturnDate(toDate);
        } else if (code && allotmentTourCode) {
          try {
            const result = await getBookableDates(code, allotmentTourCode, signal);
            if (result) {
              if (!activeOutwardDate) {
                setActiveOutwardDate(
                  fromDate ? result.outward.find((o) => differenceInDays(new Date(o), new Date(fromDate)) === 0) : last(result.outward)
                );
              }
              if (!activeReturnDate) {
                setActiveReturnDate(toDate ? result.return.find((o) => differenceInDays(new Date(o), new Date(toDate)) === 0) : first(result.return));
              }
              result.outward = uniq(result.outward);
              setBookableDates(result);
            }
          } catch (error) {
            console.log(error);
          }
        }
      }
    })();
    return () => {
      controller.abort();
    };
  }, [bookingSearch]);

  useEffect(() => {
    bookingPackage ? setIsLoading(false) : setIsLoading(true);
  }, [bookingPackage]);

  useEffect(() => {
    if (noPackageFound == true) {
      setIsLoading(false);
    }
  }, [noPackageFound]);

  const fillHotelData = () => {
    if (hotels && !isEmpty(hotels) && cmsHotels) {
      hotels
        .filter((x) => x.hotelSupplier === 0)
        .map((hotel) => {
          const cmsHotel = cmsHotels.find((h) => h.content?.general?.entity?.code == hotel.productCode);
          hotel.description = cmsHotel?.content?.description?.text ?? "";
          hotel.facilities = (cmsHotel?.content?.description?.facilities as TideItemForFacility[])
            ?.map((f) => f.content?.general?.hotelbedscode)
            .join("/");
          hotel.imageUrl = (cmsHotel?.content?.description?.images as Image[])?.map((i) => i?.url).join("%image%");
          hotel.rating = cmsHotel?.content?.general?.tripadvisor ?? "";
          hotel.stars = cmsHotel?.content?.general?.stars ?? "";
          hotel.longitude = cmsHotel?.content?.general?.location?.longitude ?? 0;
          hotel.latitude = cmsHotel?.content?.general?.location?.latitude ?? 0;
          hotel.rooms.map((room) => {
            room.accommodationName =
              cmsHotel?.content?.general?.entity?.accommodations
                ?.find((a) => a?.code == room.accommodationCode)
                ?.localizedNames?.find((ln) => (ln?.languageCode == language ? language : "nl-BE"))?.value ?? room.accommodationName;
            room.regimeName =
              cmsHotel?.content?.general?.entity?.regimes
                ?.find((r) => r?.code == room.regimeCode)
                ?.localizedNames?.find((ln) => (ln?.languageCode == language ? language : "nl-BE"))?.value ?? room.regimeName;
            return room;
          });
          return hotel;
        });
    }
    setHotels(hotels);
  };

  const checkBookingPackageConsistentie = () => {
    const bookingPackageOption = bookingPackage?.options.find((o) => o.isSelected);
    if (!bookingPackageOption) return false;
    const roomIndexes = bookingPackageOption.requestRooms.map((rr) => rr.index);
    if (isEmpty(roomIndexes)) return false;

    const validHotelOptions = roomIndexes?.map((ri) => {
      const optionUnit = bookingPackageOption.optionUnits.find((ou) => ou.index == ri);
      if (!optionUnit) return false;
      const hotelOption = getSubserviceFromPackage(bookingPackage, ServiceType.hotel).find((s) => s.isSelected);
      if (!hotelOption) return false;
      return true;
    });

    return validHotelOptions.every(Boolean);
  };

  useEffect(() => {
    fillHotelData();
  }, [bookingPackage?.options.find((o) => o.isSelected)?.hotels, hotelsLoaded]);

  useEffect(() => {
    if ((bookingNr != undefined && bookingNr != "") || (bookingPackageDossier && bookingPackageDossier.number)) {
      setActiveStep(7);
      setIsLoading(false);
    }
  }, [bookingNr, , bookingPackageDossier]);

  useEffect(() => {
    if (window.dataLayer && ((bookingNr != undefined && bookingNr != "") || (bookingPackageDossier && bookingPackageDossier.number))) {
      if (!member?.agentId) {
        window.dataLayer.push({
          event: "purchase",
          ecommerce: {
            currency: "EUR",
            transaction_id: bookingNr,
            value: bookingAmount,
            affiliation: affiliate,
            agent: undefined,
            travelCompany: {
              adults: requestRooms.flatMap((r) => r.pax.filter((p) => p.age! >= 12)).length,
              children: requestRooms.flatMap((r) => r.pax.filter((p) => p.age! < 12)).length,
            },
            items: createDataLayerItemsFromDetails(bookingPackage),
          },
        });
      } else {
        window.dataLayer.push({
          event: "agent purchase",
          ecommerce: {
            currency: "EUR",
            transaction_id: bookingPackageDossier?.number,
            affiliation: affiliate,
            agent: member.agentId,
            travelCompany: {
              adults: requestRooms.flatMap((r) => r.pax.filter((p) => p.age! >= 12)).length,
              children: requestRooms.flatMap((r) => r.pax.filter((p) => p.age! < 12)).length,
            },
            items: createDataLayerItemsFromDetails(bookingPackage),
          },
        });
      }
      // }
    }
  }, [bookingNr, affiliate, bookingPackageDossier]);

  const handleStepper = (step: number) => {
    setShouldCheckTickets(activeStep === 2 && step === +1 && showCachedPrices);
    setShouldCheckFlights(activeStep === 3 && step === +1 && showCachedPrices);
    if (activeStep === 6 && step === +1 && formIsValid) {
      if (policyChecks.identity && policyChecks.readAndAgree) {
        setPolicyChecksValid(true);
        setIsLoading(true);
        setBookEvent({ canBook: true, agentId: member?.agentId });
      } else {
        setPolicyChecksValid(false);
        return;
      }
    }

    // Step 3 vervoer overslaan als er geen vluchten gevraagd worden
    if (
      (activeStep === 2 && !initialServiceTypes?.includes(ServiceType.flight) && initialServiceTypes?.includes(ServiceType.hotel) && step === +1) ||
      (activeStep === 4 && !initialServiceTypes?.includes(ServiceType.flight) && initialServiceTypes?.includes(ServiceType.hotel) && step === -1)
    ) {
      step = step * 2;
    }

    // Step 3 en 4 vervoer en hotel overslaan als er geen vluchten en hotels gevraagd worden
    if (
      (activeStep === 2 && !initialServiceTypes?.includes(ServiceType.flight) && !initialServiceTypes?.includes(ServiceType.hotel) && step === +1) ||
      (activeStep === 5 && !initialServiceTypes?.includes(ServiceType.flight) && !initialServiceTypes?.includes(ServiceType.hotel) && step === -1)
    ) {
      step = step * 3;
    }

    // Na step 4 controle doen op de verdeling van de kamers en kijken als die correct is naar tide toe
    if (activeStep === 4 && step === +1 && initialServiceTypes?.includes(ServiceType.hotel)) {
      setValidBookingPackage(checkBookingPackageConsistentie());
    }

    setActiveStep(activeStep + step);
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
  };

  const handleSetFormIsValid = (isValid: boolean) => {
    setFormIsValid(isValid);
  };

  const handleSetPolicyCheckboxes = (selectedCheckbox: string) => {
    if (selectedCheckbox == "identity") {
      policyChecks.identity = !policyChecks.identity;
    }
    if (selectedCheckbox == "read_and_agree") {
      policyChecks.readAndAgree = !policyChecks.readAndAgree;
    }
    setPolicyChecks({ ...policyChecks });

    if (policyChecks.identity && policyChecks.readAndAgree) {
      setPolicyChecksValid(true);
    }
  };

  const queryResult = useStaticQuery(graphql`
    query dataQuery {
      allTideItemForExcursion {
        nodes {
          language
          content {
            general {
              title
              description
              productlink {
                code
              }
            }
          }
        }
      }
      allTideItemForEvenementLocatie {
        nodes {
          name
          language
          content {
            general {
              id
              title
              image {
                title
                altText
                url
              }
              floorPlan {
                title
                altText
                url
              }
              gallery {
                title
                altText
                url
              }
            }
            address {
              location {
                latitude
                longitude
              }
            }
          }
          childItems {
            ... on TideItemForCategory {
              __typename
              name
              content {
                general {
                  id
                  gallery {
                    title
                    altText
                    url
                  }
                  floorPlan {
                    altText
                    title
                    url
                  }
                  descriptionVbr
                  descriptionNl
                  descriptionEng
                }
              }
            }
          }
        }
      }
    }
  `);

  const venue = (queryResult.allTideItemForEvenementLocatie as TideItemForEvenementLocatieConnection).nodes.find(
    (e) => e.content?.general?.id == venueId
  );

  const restoreLastSuccessfulPackage = () => {
    const bookingDetails = lastSuccesfulBookingPackage?.options.find((o) => o.isSelected);
    if (lastSuccesfulBookingPackage && bookingDetails) {
      setBookingPackage(lastSuccesfulBookingPackage);
      setActiveOutwardDate(
        bookingDetails.fromDate
          ? !isEmpty(bookableDates)
            ? bookableDates?.outward.find((o) => differenceInDays(new Date(o), new Date(bookingDetails.fromDate)) === 0)
            : bookingDetails.fromDate
          : last(bookableDates?.outward)
      );
      setActiveReturnDate(
        bookingDetails.toDate
          ? !isEmpty(bookableDates)
            ? bookableDates?.return.find((o) => differenceInDays(new Date(o), new Date(bookingDetails.toDate)) === 0)
            : bookingDetails.toDate
          : first(bookableDates?.return)
      );
      setRequestRooms(bookingDetails.requestRooms);
      setNoPackageFound(false);
      setResetRooms(true);
      setHotelsLoaded(true);
      setFlightsLoaded(true);
      setFetchPrice(true);
    }
  };

  const returnToStep = (step: number) => {
    setActiveStep(step);
    setDefaultTicketNotAvailable(false);
    setDefaultFlightNotAvailable(false);
    setFlightPriceChanged(false);
    setHotelPriceChanged(false);
  };

  return (
    <>
      <div className="booking booking--immersive">
        <Helmet bodyAttributes={{ class: `${flyInIsOpen ? "has-overlay" : ""}` }} />

        <div className="booking__background">
          {backgroundImage && backgroundImage.url && (
            <img
              src={backgroundImage.url}
              title={parse(backgroundImage.title)}
              alt={parse(backgroundImage.altText)}
              className="booking__background-media"
            />
          )}
        </div>

        <div className={`booking__container ${activeStep == 7 ? "booking__container--no-summary" : ""}`}>
          <div className="booking__step-indicators">
            <StepIndicators step={activeStep} />
          </div>

          <SummaryPanel event={event} tournament={tournament} activeStep={activeStep} homeTeamImage={homeTeamImage} awayTeamImage={awayTeamImage} />
          {
            {
              1: <BookingStep1 adultsOnly={adultsOnly} />,
              2: <BookingStep2 venue={venue} excursions={(queryResult.allTideItemForExcursion as TideItemForExcursionConnection).nodes} />,
              3: <BookingStep3 />,
              4: <BookingStep4 website={website?.name} venue={venue} />,
              5: <BookingStep5 setFormIsValid={handleSetFormIsValid} isLoading={isLoading} website={website} />,
              6: (
                <BookingStep6
                  policyChecks={policyChecks}
                  handleSetPolicyCheckboxes={handleSetPolicyCheckboxes}
                  policyChecksValid={policyChecksValid}
                />
              ),
              7: !isLoading ? (
                <BookingStep7 bookingNr={bookingNr} bookingAllotmentName={bookingAllotmentName?.replace(/_/g, " ")} />
              ) : (
                <div className="booking__form-panel">
                  <div className="booking__region">
                    <div className="booking__card booking__card--transparent">
                      <Preloader></Preloader>
                    </div>
                  </div>
                </div>
              ),
            }[activeStep]
          }

          <div className="booking__navigator">
            {activeStep > 1 && (
              <button
                disabled={activeStep == 1 || activeStep == 7}
                type="button"
                title={translate(translations, language ? language : "nl-BE", "PREVIOUS")}
                className="cta cta--return booking__navigator--return"
                onClick={() => handleStepper(-1)}
              >
                {translate(translations, language ? language : "nl-BE", "PREVIOUS")}
              </button>
            )}

            <button
              disabled={
                activeStep == 7 ||
                (activeStep == 4 && !hotelsLoaded) ||
                (activeStep == 5 && (!formIsValid || isLoading)) ||
                (roomDistributionErrors.length > 0 && first(roomDistributionErrors)?.key != "singleRooms") ||
                noPackageFound
              }
              type="button"
              title={translate(translations, language ? language : "nl-BE", activeStep == 6 ? "CHECKOUT" : "NEXT")}
              className="cta cta--next"
              onClick={() => handleStepper(+1)}
            >
              {translate(translations, language ? language : "nl-BE", activeStep == 6 ? "CHECKOUT" : "NEXT")}
            </button>
          </div>
        </div>
        {(noPackageFound ||
          noFlightsFound ||
          defaultFlightNotAvailable ||
          defaultTicketNotAvailable ||
          flightPriceChanged ||
          hotelPriceChanged ||
          !validBookingPackage) &&
          !bookingNr && (
            <div className="booking__popup">
              {defaultTicketNotAvailable && !noPackageFound && (
                <div className="popup">
                  <div
                    className="popup__backdrop"
                    onClick={() => {
                      returnToStep(2);
                    }}
                  ></div>
                  <div className="popup__frame">
                    <button
                      type="button"
                      className="popup__close-button"
                      title="Sluiten"
                      onClick={() => {
                        returnToStep(2);
                      }}
                    ></button>
                    <div className="info-message">
                      <div className="match-passed-icon">
                        <Icon name="match-passed" />
                      </div>
                    </div>
                    <div className="heading-seperator">
                      <h1 className="popup__heading">Ai, het standaard ticket is niet meer beschikbaar...</h1>
                    </div>
                    <p>
                      We kunnen een ander ticket selecteren maar dit zal een impact hebben op de prijs van de reis.
                      <br />
                      In het boekingsoverzicht (rechterzijde) ga je de actuele prijs van je reis zien.
                      <br />
                      Onze excuses voor het ongemak.
                    </p>
                    <button
                      onClick={() => {
                        returnToStep(2);
                      }}
                      type="button"
                      className="cta"
                      title="Selecteer een ander ticket."
                    >
                      Selecteer een ander ticket.
                    </button>
                  </div>
                </div>
              )}
              {defaultFlightNotAvailable && !noPackageFound && !defaultTicketNotAvailable && (
                <div className="popup">
                  <div
                    className="popup__backdrop"
                    onClick={() => {
                      returnToStep(3);
                      setFlightsLoaded(true);
                    }}
                  ></div>
                  <div className="popup__frame">
                    <button
                      type="button"
                      className="popup__close-button"
                      title="Sluiten"
                      onClick={() => {
                        returnToStep(3);
                        setFlightsLoaded(true);
                      }}
                    ></button>
                    <div className="info-message">
                      <div className="flight-unavailable-icon">
                        <Icon name="flight-unavailable" />
                      </div>
                    </div>
                    <div className="heading-seperator">
                      <h1 className="popup__heading">Ai, de gekozen vlucht is niet meer beschikbaar...</h1>
                    </div>
                    <p>
                      We kunnen een andere vlucht voor je zoeken maar dit zal een impact hebben op de prijs van de reis.
                      <br />
                      In het boekingsoverzicht (rechterzijde) ga je de actuele prijs van je reis zien.
                      <br />
                      Onze excuses voor het ongemak.
                    </p>
                    <button
                      onClick={() => {
                        returnToStep(3);
                        setFlightsLoaded(true);
                      }}
                      type="button"
                      className="cta"
                      title="Zoek een andere vlucht"
                    >
                      Zoek een andere vlucht
                    </button>
                  </div>
                </div>
              )}
              {flightPriceChanged && !noPackageFound && (
                <div className="popup">
                  <div
                    className="popup__backdrop"
                    onClick={() => {
                      returnToStep(3);
                      setFlightsLoaded(true);
                    }}
                  ></div>
                  <div className="popup__frame">
                    <button
                      type="button"
                      className="popup__close-button"
                      title="Sluiten"
                      onClick={() => {
                        returnToStep(3);
                        setFlightsLoaded(true);
                      }}
                    ></button>
                    <div className="info-message">
                      <div className="flight-unavailable-icon">
                        <Icon name="flight-unavailable" />
                      </div>
                    </div>
                    <div className="heading-seperator">
                      <h1 className="popup__heading">Ai, de gekozen vlucht kunnen we niet langer aanbieden aan de getoonde prijs...</h1>
                    </div>
                    <p>
                      De prijs van de vluchten zal aangepast worden naar de actuele prijs.
                      <br />
                      In het boekingsoverzicht (rechterzijde) ga je de actuele prijs van je reis zien.
                      <br />
                      Onze excuses voor het ongemak.
                    </p>
                    <button
                      onClick={() => {
                        returnToStep(3);
                        setFlightsLoaded(true);
                      }}
                      type="button"
                      className="cta"
                      title="Zoek een andere vlucht"
                    >
                      Toon nieuwe vluchten
                    </button>
                  </div>
                </div>
              )}
              {noFlightsFound && !noPackageFound && (
                <div className="popup">
                  <div
                    className="popup__backdrop"
                    onClick={() => {
                      setNoFlightsFound(false);
                      setFlightsLoaded(true);
                    }}
                  ></div>
                  <div className="popup__frame">
                    <button
                      type="button"
                      className="popup__close-button"
                      title="Sluiten"
                      onClick={() => {
                        setNoFlightsFound(false);
                        setFlightsLoaded(true);
                      }}
                    ></button>
                    <div className="info-message">
                      <div className="flight-unavailable-icon">
                        <Icon name="flight-unavailable" />
                      </div>
                    </div>
                    <div className="heading-seperator">
                      <h1 className="popup__heading">Ai, geen vluchten gevonden...</h1>
                    </div>
                    <p>
                      Helaas, er zijn <strong>geen beschikbare vluchten gevonden</strong> voor deze bestemming. Je kan dit pakket ook verder boeken
                      zonder vlucht.
                    </p>
                    <button
                      onClick={() => {
                        setNoFlightsFound(false);
                        setFlightsLoaded(true);
                      }}
                      type="button"
                      className="cta"
                      title="Boek zonder vlucht"
                    >
                      Boek zonder vlucht
                    </button>
                  </div>
                </div>
              )}
              {hotelPriceChanged && !noPackageFound && !defaultTicketNotAvailable && (
                <div className="popup">
                  <div
                    className="popup__backdrop"
                    onClick={() => {
                      setSearchAltHotel(true);
                      setHotelPriceChanged(false);
                      setNoPackageFound(false);
                    }}
                  ></div>
                  <div className="popup__frame">
                    <button
                      type="button"
                      className="popup__close-button"
                      title="Sluiten"
                      onClick={() => {
                        setSearchAltHotel(true);
                        setHotelPriceChanged(false);
                        setNoPackageFound(false);
                      }}
                    ></button>
                    <div className="info-message">
                      <div className="hotel-unavailable-icon">
                        <Icon name="hotel-unavailable" />
                      </div>
                    </div>
                    <div className="heading-seperator">
                      <h1 className="popup__heading">
                        Helaas! De prijs van jouw voetbalreis is aangepast op basis van de huidige beschikbaarheid en prijzen.
                      </h1>
                    </div>
                    <p>
                      In het boekingsoverzicht ga je de actuele prijs van je reis zien.
                      <br />
                      Onze excuses voor het ongemak.
                    </p>
                    <button
                      onClick={() => {
                        setSearchAltHotel(true);
                        setHotelPriceChanged(false);
                        setNoPackageFound(false);
                      }}
                      type="button"
                      className="cta"
                      title="Toon de nieuwe hotels"
                    >
                      Toon de nieuwe hotels
                    </button>
                  </div>
                </div>
              )}
              {noPackageFound && !lastSuccesfulBookingPackage && !hotelPriceChanged && (
                <div className="popup">
                  <div className="popup__backdrop" onClick={() => navigate("/groepsreizen")}></div>
                  <div className="popup__frame">
                    <button type="button" className="popup__close-button" title="Sluiten" onClick={() => navigate("/groepsreizen")}></button>
                    <div className="info-message">
                      <div className="match-passed-icon">
                        <Icon name="match-passed" />
                      </div>
                    </div>
                    <div className="heading-seperator">
                      <h1 className="popup__heading">Ai, deze wedstrijd is niet meer beschikbaar...</h1>
                    </div>
                    <p>
                      Helaas, deze wedstrijd is <strong>volboekt of reeds voorbij</strong>. <br />
                      We helpen je graag met kijken naar welke reizen er wél mogelijk zijn.
                    </p>
                    <Link path="/groepsreizen" className="cta" title="Neem contact op">
                      Neem contact op
                    </Link>
                  </div>
                </div>
              )}
              {/* //TODO: when /priceDetails fails => restoring lastSuccesfulBookingPackage is not sufficiant */}
              {noPackageFound && lastSuccesfulBookingPackage && !hotelPriceChanged && (
                <div className="popup">
                  <div className="popup__backdrop" onClick={() => restoreLastSuccessfulPackage()}></div>
                  <div className="popup__frame">
                    <button type="button" className="popup__close-button" title="Sluiten" onClick={() => restoreLastSuccessfulPackage()}></button>
                    <div className="info-message">
                      <div className="configure-error-icon">
                        <Icon name="configure-error" />
                      </div>
                      <div className="heading-seperator">
                        <h1 className="popup__heading">Ai, er ging wat mis bij het samenstellen...</h1>
                      </div>
                      <p>
                        Daar ging wat mis. <strong>Probeer je even opnieuw</strong>?<br />
                        Als het blijft misgaan, <Link path="/contact">laat het ons zeker weten</Link>.
                      </p>
                      <button type="button" className="cta" title="Probeer opnieuw" onClick={() => restoreLastSuccessfulPackage()}>
                        Probeer opnieuw
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {!validBookingPackage && (
                <div className="popup">
                  <div className="popup__backdrop" onClick={() => window.location.reload()}></div>
                  <div className="popup__frame">
                    <button type="button" className="popup__close-button" title="Sluiten" onClick={() => window.location.reload()}></button>
                    <div className="info-message">
                      <div className="configure-error-icon">
                        <Icon name="configure-error" />
                      </div>
                      <div className="heading-seperator">
                        <h1 className="popup__heading">Ai, er ging wat mis bij het samenstellen...</h1>
                      </div>
                      <p>
                        Daar ging wat mis. <strong>Probeer je even opnieuw</strong>?<br />
                        We brengen je terug naar de eerste stap. Als het blijft misgaan, <Link path="/contact">laat het ons zeker weten</Link>.
                      </p>
                      <button type="button" className="cta" title="Probeer opnieuw" onClick={() => window.location.reload()}>
                        Probeer opnieuw
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
      </div>

      {/* <div className="popup popup--discount">
      <div className="popup__backdrop" />
      <div className="popup__frame">
        <button type="button" className="popup__close-button" />
        <div className="heading-seperator">
          <h2 className="popup__heading">Kortingscode toevoegen</h2>
        </div>
        <p>
          Pas hieronder jouw kortingscode toe.
          <br />
          Per bestelling kan je tot maximaal 5 kortingscodes toevoegen.
        </p>
        <div className="popup__region">
          <form className="form">
            <div className="form__row">
              <div className="form__group">
                <label className="form__label">
                  <strong>Jouw 12-cijferige code:</strong>
                </label>
                <div className="discount-input">
                  <div className="discount-input__inputs">
                    <input
                      className="discount-input__input"
                      type="text"
                      alt="Kortingscode region 1"
                      placeholder="AA00"
                      minlength="2"
                      maxlength="4"
                      required
                    />
                    <input
                      className="discount-input__input discount-input__input--3"
                      type="number"
                      alt="Kortingscode region 2"
                      placeholder="000"
                      minlength="3"
                      maxlength="3"
                      required
                    />
                    <input
                      className="discount-input__input discount-input__input--2"
                      type="number"
                      alt="Kortingscode region 3"
                      placeholder="00"
                      minlength="2"
                      maxlength="2"
                      required
                    />
                    <input
                      className="discount-input__input discount-input__input--2"
                      type="number"
                      alt="Kortingscode region 4"
                      placeholder="00"
                      minlength="2"
                      maxlength="2"
                      required
                    />
                  </div>                  
                  <button type="submit" className="discount-input__submit-button">
                    Toepassen
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="popup__frame">
        <button type="button" className="popup__close-button" />
        <div className="heading-seperator">
          <h2 className="popup__heading">Kortingscode toevoegen</h2>
        </div>
        <p>
          Pas hieronder jouw kortingscode toe.
          <br />
          Per bestelling kan je tot maximaal 5 kortingscodes toevoegen.
        </p>

        <div className="popup__region">
          <h3 className="popup__region-heading">2 toegevoegde kortingscodes:</h3>

          <div className="added-discounts-list">
            <div className="added-discounts-list__item">
              <div className="added-discounts-list__status-icon">
                <Icon name="check-circle-blue"/>
              </div>

              <div className="added-discounts-list__code">
                <div className="added-discounts-list__code-column">
                  SU53
                </div>
                <div className="added-discounts-list__code-column">
                  784
                </div>
                <div className="added-discounts-list__code-column">
                  36
                </div>
                <div className="added-discounts-list__code-column">
                  95
                </div>
              </div>

              <div className="added-discounts-list__summary">
                <div className="added-discounts-list__amount">
                    -10%
                </div>

                <button className="added-discounts-list__remove-button" title="Verwijder kortingscode">
                </button>
              </div>
            </div>

            <div className="added-discounts-list__item">
              <div className="added-discounts-list__status-icon">
                <Icon name="check-circle-blue"/>
              </div>

              <div className="added-discounts-list__code">
                <div className="added-discounts-list__code-column">
                  AL74
                </div>
                <div className="added-discounts-list__code-column">
                  561
                </div>
                <div className="added-discounts-list__code-column">
                  55
                </div>
                <div className="added-discounts-list__code-column">
                  23
                </div>
              </div>

              <div className="added-discounts-list__summary">
                <div className="added-discounts-list__amount">
                    -5%
                </div>

                <button className="added-discounts-list__remove-button" title="Verwijder kortingscode">
                </button>
            </div>
          </div>
        </div>

      </div>

      <div className="popup__region">
          <h3 className="popup__region-heading">Extra kortingscode toevoegen:</h3>
          <form className="form">
            <div className="form__row">
              <div className="form__group">
                <label className="form__label">
                  <strong>Jouw 12-cijferige code:</strong>
                </label>
                <div className="discount-input">
                  <div className="discount-input__inputs">
                    <input
                      className="discount-input__input"
                      type="text"
                      alt="Kortingscode region 1"
                      placeholder="AA00"
                      minlength="2"
                      maxlength="4"
                      required
                    />
                    <input
                      className="discount-input__input discount-input__input--3"
                      type="number"
                      alt="Kortingscode region 2"
                      placeholder="000"
                      minlength="3"
                      maxlength="3"
                      required
                    />
                    <input
                      className="discount-input__input discount-input__input--2"
                      type="number"
                      alt="Kortingscode region 3"
                      placeholder="00"
                      minlength="2"
                      maxlength="2"
                      required
                    />
                    <input
                      className="discount-input__input discount-input__input--2"
                      type="number"
                      alt="Kortingscode region 4"
                      placeholder="00"
                      minlength="2"
                      maxlength="2"
                      required
                    />
                  </div>                  
                  <button type="submit" className="discount-input__submit-button">
                    Toepassen
                  </button>
                </div>
              </div>
            </div>
            <div className="form__errors">
              <h2 className="form__errors-heading">Ongeldige kortingscode</h2>
              <p>
                De code die je hebt ingegeven lijkt niet (meer) geldig te zijn. <br/>
                Foutje? <a href="/contact" title="Neem contact op">Laat het ons weten</a>.
              </p>
            </div>
          </form>
        </div>
      
      </div>
      </div> */}
    </>
  );
};

export default Booking;
