import React, { useContext, useEffect, useState } from "react";
import GlobalContext, { GlobalContextType } from "../../../contexts/global-provider";
import translate from "../../../utils/translate";
import translations from "../translations.json";
import BookingContext, { BookingContextType } from "../../../contexts/booking-provider";
import Icon from "../../icon";
import { BookingPackageAddress, BookingPackagePax } from "@qite/tide-client/build/types";
import { cloneDeep, find, findIndex, first } from "lodash";
import { isValid, parse } from "date-fns";
import {
  BIRTHDATE_INVALID,
  EMAIL_INVALID,
  getBirthDateError,
  getEmailError,
  getPhoneNumberError,
  isValidBirthDate,
  isValidEmail,
  isValidPhoneNumber,
  PHONE_NUMBER_INVALID,
} from "../../../utils/validation-utils";
import TooltipModal from "../../tooltips/tooltip-modal";
import TextMask from "react-text-mask";
import { format } from "date-fns/esm";
import { getAge } from "../../../utils/date-utils";
import { TideItemForWebsite } from "../../../types";

interface BookingStep5Props {
  setFormIsValid: (isValid: boolean) => void;
  isLoading: boolean;
  website?: TideItemForWebsite;
}

interface Country {
  tideId: number;
  name: string;
  value: string;
}

const BookingStep5: React.FC<BookingStep5Props> = ({ setFormIsValid, isLoading, website }) => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
  }, []);
  const [hovered, setHovered] = useState("");
  const handleHover = (code: string) => {
    setHovered(code);
  };
  const { language } = useContext<GlobalContextType>(GlobalContext);
  const {
    bookingPackage,
    setBookingPackage,
    bookingAddress,
    setBookingAddress,
    stayAtHome,
    setStayAtHome,
    mainBookerData,
    setMainBookerData,
    nationalityCountries,
    mainBookerIsTraveller,
    setMainBookerIsTraveller,
  } = useContext<BookingContextType>(BookingContext);
  const [errors, setErrors] = useState({} as any);
  const [stayAtHomeActive, setStayAtHomeActive] = useState(false);

  // const staticQuery = useStaticQuery(graphql`
  // query CountriesQuery {
  //   allTideCountry {
  //     nodes {
  //       name
  //     }
  //   }
  // }
  // `) as any;

  // const tideCountries = staticQuery.allTideCountry.nodes as TideCountry[];
  const tideCountries = [
    { name: "Nederland", value: "Netherlands" },
    { name: "België", value: "Belgium" },
    { name: "Duitsland", value: "Germany" },
    { name: "Oostenrijk", value: "Austria" },
    { name: "Luxemburg", value: "Luxembourg" },
    { name: "Zwitserland", value: "Switzerland" },
  ] as Country[];

  const travellers = bookingPackage?.options.find((o) => o.isSelected)?.requestRooms.flatMap((rr) => rr.pax);

  const [touched, setTouched] = useState({
    travellers: travellers?.map((t) =>
      Object.keys(t).reduce((prev: any, cur) => {
        return prev;
      }, {})
    ),
    address: {} as any,
    stayAtHome: stayAtHome,
    mainBookerData: {} as any,
  });

  const addressFields = ["email", "phone"];

  useEffect(() => {
    if (!isLoading) {
      setTouched({
        ...touched,
        travellers: travellers?.map((t) =>
          Object.keys(t).reduce((prev: any, cur) => {
            return prev;
          }, {})
        ),
      });
    }
  }, [isLoading]);

  const handleBlur = (
    event: any,
    traveller?: BookingPackagePax,
    stayAtHome?: BookingPackagePax,
    address?: BookingPackageAddress,
    mainBookerData?: BookingPackagePax
  ) => {
    if (address) {
      setTouched({
        ...touched,
        address: { ...touched.address, [event.target.name]: true },
      });
    } else if (stayAtHome) {
      setTouched({
        ...touched,
        stayAtHome: { ...touched.stayAtHome, [event.target.name]: true },
      });
    } else if (traveller) {
      let trav = cloneDeep(touched.travellers);
      if (trav) {
        trav[
          findIndex(travellers, function (t) {
            return t.id == traveller?.id;
          })
        ][event.target.name] = true;
        setTouched({ ...touched, travellers: trav });
      }
    } else if (mainBookerData || (traveller && mainBookerIsTraveller == "1")) {
      setTouched({
        ...touched,
        mainBookerData: { ...touched.mainBookerData, [event.target.name]: true },
      });
    }

    validate();
  };

  const CheckIfFormIsValid = (errors: any) => {
    let isValid = true;
    if (errors) {
      if (errors.travellers) {
        errors.travellers.forEach((traveller: any) => {
          if (Object.keys(traveller).length) {
            isValid = false;
          }
        });
      }
      if (errors.bookingAddress && Object.keys(errors.bookingAddress).length) {
        isValid = false;
      }
      if (errors.mainBookerData && Object.keys(errors.mainBookerData).length) {
        isValid = false;
      }
    }
    setFormIsValid(isValid);
  };

  const validate = () => {
    let errors = {} as any;

    // travellers
    travellers?.map((trav, i) => {
      // if (trav.initials?.length === 0) {
      //   const travellerErrors = errors.travellers ?? Array(travellers.length).fill({});
      //   travellerErrors[i] = {
      //     ...travellerErrors[i],
      //     initials: translate(translations, language, "ERROR_INITIALS_REQUIRED"),
      //   };
      //   errors = { ...errors, travellers: travellerErrors };
      // }
      if (trav.firstName?.length === 0) {
        const travellerErrors = errors.travellers ?? Array(travellers.length).fill({});
        travellerErrors[i] = {
          ...travellerErrors[i],
          firstName: translate(translations, language, "ERROR_FIRSTNAME_REQUIRED"),
        };
        errors = { ...errors, travellers: travellerErrors };
      }
      if (trav.lastName?.length === 0) {
        const travellerErrors = errors.travellers ?? Array(travellers.length).fill({});
        travellerErrors[i] = {
          ...travellerErrors[i],
          lastName: translate(translations, language, "ERROR_LASTNAME_REQUIRED"),
        };
        errors = { ...errors, travellers: travellerErrors };
      }
      if (!trav.countryOfBirthId || trav.countryOfBirthId <= 0) {
        const travellerErrors = errors.travellers ?? Array(travellers.length).fill({});
        travellerErrors[i] = {
          ...travellerErrors[i],
          countryOfBirthId: translate(translations, language, "ERROR_COUNTRYOFBIRTHID_REQUIRED"),
        };
        errors = { ...errors, travellers: travellerErrors };
      }

      if (!isValidBirthDate(trav.dateOfBirth)) {
        let errorMessage = "";
        const error = getBirthDateError(trav.dateOfBirth);
        switch (error) {
          case BIRTHDATE_INVALID:
            errorMessage = translate(translations, language, "ERROR_BIRTHDATE_INVALID");
            break;
          default:
            errorMessage = translate(translations, language, "ERROR_BIRTHDATE_REQUIRED");
            break;
        }

        const travellerErrors = errors.travellers ?? Array(travellers.length).fill({});
        travellerErrors[i] = {
          ...travellerErrors[i],
          dateOfBirth: errorMessage,
        };
        errors = { ...errors, travellers: travellerErrors };
      }

      if (trav.isMainBooker) {
        if (!isValidPhoneNumber(trav.phone)) {
          let errorMessage = "";
          const error = getPhoneNumberError(trav.phone);
          switch (error) {
            case PHONE_NUMBER_INVALID:
              errorMessage = translate(translations, language, "ERROR_PHONE_INVALID");
              break;
            default:
              errorMessage = translate(translations, language, "ERROR_PHONE_REQUIRED");
              break;
          }
          errors = {
            ...errors,
            mainBookerData: {
              ...errors.mainBookerData,
              phone: errorMessage,
            },
          };
        }

        if (!isValidEmail(trav.email)) {
          let errorMessage = "";
          const error = getEmailError(trav.email);
          switch (error) {
            case EMAIL_INVALID:
              errorMessage = translate(translations, language, "ERROR_EMAIL_INVALID");
              break;
            default:
              errorMessage = translate(translations, language, "ERROR_EMAIL_REQUIRED");
              break;
          }
          errors = {
            ...errors,
            mainBookerData: {
              ...errors.mainBookerData,
              email: errorMessage,
            },
          };
        }
      }
    });

    // MainBooker
    if (mainBookerData) {
      // if (!mainBookerData.initials || mainBookerData.initials?.length === 0) {
      //   errors = {
      //     ...errors,
      //     mainBookerData: {
      //       ...errors.mainBookerData,
      //       initials: translate(translations, language, "ERROR_INITIALS_REQUIRED"),
      //     },
      //   };
      // }
      if (!mainBookerData.firstName || mainBookerData.firstName?.length === 0) {
        errors = {
          ...errors,
          mainBookerData: {
            ...errors.mainBookerData,
            firstName: translate(translations, language, "ERROR_FIRSTNAME_REQUIRED"),
          },
        };
      }
      if (!mainBookerData.lastName || mainBookerData.lastName?.length === 0) {
        errors = {
          ...errors,
          mainBookerData: {
            ...errors.mainBookerData,
            lastName: translate(translations, language, "ERROR_LASTNAME_REQUIRED"),
          },
        };
      }
      if (!mainBookerData.countryOfBirthId || mainBookerData.countryOfBirthId <= 0) {
        errors = {
          ...errors,
          mainBookerData: {
            ...errors.mainBookerData,
            countryOfBirthId: translate(translations, language, "ERROR_COUNTRYOFBIRTHID_REQUIRED"),
          },
        };
      }
      if (!isValidBirthDate(mainBookerData.dateOfBirth)) {
        let errorMessage = "";
        const error = getBirthDateError(mainBookerData.dateOfBirth);
        switch (error) {
          case BIRTHDATE_INVALID:
            errorMessage = translate(translations, language, "ERROR_BIRTHDATE_INVALID");
            break;
          default:
            errorMessage = translate(translations, language, "ERROR_BIRTHDATE_REQUIRED");
            break;
        }
        errors = {
          ...errors,
          mainBookerData: {
            ...errors.mainBookerData,
            dateOfBirth: errorMessage,
          },
        };
      }
      if (!isValidPhoneNumber(mainBookerData.phone)) {
        let errorMessage = "";
        const error = getPhoneNumberError(mainBookerData.phone);
        switch (error) {
          case PHONE_NUMBER_INVALID:
            errorMessage = translate(translations, language, "ERROR_PHONE_INVALID");
            break;
          default:
            errorMessage = translate(translations, language, "ERROR_PHONE_REQUIRED");
            break;
        }

        errors = {
          ...errors,
          mainBookerData: {
            ...errors.mainBookerData,
            phone: errorMessage,
          },
        };
      }

      if (!isValidEmail(mainBookerData.email)) {
        let errorMessage = "";
        const error = getEmailError(mainBookerData.email);
        switch (error) {
          case EMAIL_INVALID:
            errorMessage = translate(translations, language, "ERROR_EMAIL_INVALID");
            break;
          default:
            errorMessage = translate(translations, language, "ERROR_EMAIL_REQUIRED");
            break;
        }

        errors = {
          ...errors,
          mainBookerData: {
            ...errors.mainBookerData,
            email: errorMessage,
          },
        };
      }
    }

    // Address
    if (!bookingAddress?.street || bookingAddress?.street?.length === 0) {
      errors = {
        ...errors,
        bookingAddress: {
          ...errors.bookingAddress,
          street: translate(translations, language, "ERROR_STREET_REQUIRED"),
        },
      };
    }
    if (!bookingAddress?.number || bookingAddress?.number?.length === 0) {
      errors = {
        ...errors,
        bookingAddress: {
          ...errors.bookingAddress,
          number: translate(translations, language, "ERROR_HOUSE_NUMBER_REQUIRED"),
        },
      };
    }
    if (!bookingAddress?.postalCode || bookingAddress?.postalCode?.length === 0) {
      errors = {
        ...errors,
        bookingAddress: {
          ...errors.bookingAddress,
          postalCode: translate(translations, language, "ERROR_POSTALCODE_REQUIRED"),
        },
      };
    }
    if (!bookingAddress?.location || bookingAddress?.location?.length === 0) {
      errors = {
        ...errors,
        bookingAddress: {
          ...errors.bookingAddress,
          location: translate(translations, language, "ERROR_LOCATION_REQUIRED"),
        },
      };
    }
    if (!bookingAddress?.country || bookingAddress?.country?.length === 0) {
      errors = {
        ...errors,
        bookingAddress: {
          ...errors.bookingAddress,
          country: translate(translations, language, "ERROR_COUNTRY_REQUIRED"),
        },
      };
    }
    setErrors(errors);
    CheckIfFormIsValid(errors);
  };

  const setTravellerData = (packageTraveller: BookingPackagePax, traveller: BookingPackagePax) => {
    packageTraveller.dateOfBirth = traveller.dateOfBirth;
    packageTraveller.age = traveller.dateOfBirth ? getAge(new Date(traveller.dateOfBirth)) : traveller.age;
    packageTraveller.initials = traveller.initials;
    packageTraveller.firstName = traveller.firstName;
    packageTraveller.lastName = traveller.lastName;
    packageTraveller.gender = traveller.gender;
    packageTraveller.initials = traveller.initials;
    packageTraveller.email = traveller.email;
    packageTraveller.phone = traveller.phone;
    packageTraveller.mobilePhone = traveller.mobilePhone;
    packageTraveller.countryOfBirthId = traveller.countryOfBirthId;
    return packageTraveller;
  };

  const changeTraveller = (traveller: BookingPackagePax, event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    if (event.target.name == "dateOfBirth") {
      let packageTraveller = travellers?.find((t) => t.id == traveller.id);
      if (packageTraveller) {
        var date = parse(event.target.value, "dd/MM/yyyy", new Date());
        if (isValid(date)) {
          packageTraveller.dateOfBirth = format(date, "yyyy/MM/dd");
          packageTraveller.age = getAge(new Date(packageTraveller.dateOfBirth));
          if (bookingPackage) {
            setBookingPackage({ ...bookingPackage });
          }
        } else {
          packageTraveller.dateOfBirth = event.target.value;
        }
      }
    } else {
      traveller = { ...traveller, [event.target.name]: event.target.value };

      let packageTraveller = travellers?.find((t) => t.id == traveller.id);
      if (packageTraveller) {
        packageTraveller = setTravellerData(packageTraveller, traveller);
      }
      if (bookingPackage) {
        setBookingPackage({ ...bookingPackage });
      }
    }

    if (mainBookerData && mainBookerIsTraveller == "1" && index == 0) {
      changeMainBookerData(mainBookerData, event);
      if (
        !traveller.isMainBooker &&
        traveller.firstName.trim().toLowerCase() == mainBookerData.firstName.trim().toLowerCase() &&
        traveller.lastName.trim().toLowerCase() == mainBookerData.lastName.trim().toLowerCase() &&
        traveller.dateOfBirth == mainBookerData.dateOfBirth
      ) {
        let packageTraveller = travellers?.find((t) => t.id == traveller.id);
        if (packageTraveller) {
          packageTraveller.isMainBooker = true;
        }
        if (bookingPackage) {
          setBookingPackage({ ...bookingPackage });
        }
      }
    }
  };

  const changeTravellerNationality = (traveller: BookingPackagePax, event: React.ChangeEvent<HTMLSelectElement>, index: number) => {
    const selectedValue = find(event.target.options, "selected")?.value;
    if (selectedValue && Number.isInteger(parseInt(selectedValue, 10))) {
      traveller = { ...traveller, countryOfBirthId: parseInt(selectedValue, 10) };

      let packageTraveller = travellers?.find((t) => t.id == traveller.id);
      if (packageTraveller) {
        packageTraveller = setTravellerData(packageTraveller, traveller);
      }
      if (bookingPackage) {
        setBookingPackage({ ...bookingPackage });
      }
    }

    if (mainBookerIsTraveller == "1" && index == 0) {
      changeMainBookerNationality(event);
    }
  };

  const changeGender = (traveller: BookingPackagePax, event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    let packageTraveller = travellers?.find((t) => t.id == traveller.id);
    if (packageTraveller) {
      packageTraveller.gender = Number(event.target.value);

      if (mainBookerIsTraveller == "1" && index == 0) {
        changeGenderMainBookerData(event);
      }
    }
    if (bookingPackage) {
      setBookingPackage({ ...bookingPackage });
    }
  };

  const changeGenderStayAtHome = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (stayAtHome) {
      setStayAtHome({ ...stayAtHome, gender: Number(event.target.value) });
    }
  };

  const changeGenderMainBookerData = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (mainBookerData) {
      mainBookerData.gender = Number(event.target.value);
      setMainBookerData({ ...mainBookerData });
    }
  };

  const changeMainBookerData = (traveller: BookingPackagePax, event: React.ChangeEvent<HTMLInputElement>) => {
    // deze velden ook op address wegschrijven
    if (addressFields.includes(event.target.name)) {
      if (bookingAddress) {
        setBookingAddress({ ...bookingAddress, [event.target.name]: event.target.value });
      }
    }

    if (event.target.name == "dateOfBirth") {
      if (mainBookerData) {
        var date = parse(event.target.value, "dd/MM/yyyy", new Date());
        if (isValid(date)) {
          mainBookerData.dateOfBirth = format(date, "yyyy/MM/dd");
          setMainBookerData({ ...mainBookerData });
        } else {
          mainBookerData.dateOfBirth = event.target.value;
        }
      }
    } else {
      traveller = { ...traveller, [event.target.name]: event.target.value };

      if (mainBookerData) {
        setMainBookerData({ ...setTravellerData(mainBookerData, traveller) });

        // Set email en phone to mainBooker, because atm we need a traveller to be the mainbooker in Tide.
        let mainBooker = travellers?.find((t) => t.isMainBooker);
        if (mainBooker) {
          mainBooker.email = mainBookerData.email;
          mainBooker.phone = mainBookerData.phone;
        }
        if (bookingPackage) {
          setBookingPackage({ ...bookingPackage });
        }
      }
    }
  };

  const changeStayAtHomeTraveller = (traveller: BookingPackagePax, event: React.ChangeEvent<HTMLInputElement>) => {
    if (stayAtHome) {
      setStayAtHome({ ...stayAtHome, [event.target.name]: event.target.value });
    }
  };

  const changeAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (bookingAddress) {
      setBookingAddress({ ...bookingAddress, [event.target.name]: event.target.value });
    }
  };

  const changeCountry = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = find(event.target.options, "selected")?.value;

    if (bookingAddress && selectedValue) {
      setBookingAddress({ ...bookingAddress, country: selectedValue });
    }
  };

  const changeMainBookerNationality = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = find(event.target.options, "selected")?.value;

    if (mainBookerData && selectedValue && Number.isInteger(parseInt(selectedValue, 10))) {
      setMainBookerData({ ...mainBookerData, countryOfBirthId: parseInt(selectedValue, 10) });
    }
  };

  useEffect(() => {
    validate();
  }, [bookingPackage, bookingAddress, stayAtHome]);

  const formatDate = (dateOfBirth?: string) => {
    if (dateOfBirth) {
      var date = parse(dateOfBirth, "yyyy/MM/dd", new Date());

      if (isValid(date)) {
        return format(date, "dd/MM/yyyy");
      } else {
        return dateOfBirth;
      }
    }
  };

  const mainBookerHasErrors = () => {
    if (errors.mainBookerData) {
      if (errors.mainBookerData.dateOfBirth && touched.mainBookerData.dateOfBirth) {
        return true;
      }
      if (errors.mainBookerData.email && touched.mainBookerData.email) {
        return true;
      }
      if (errors.mainBookerData.initials && touched.mainBookerData.initials) {
        return true;
      }
      if (errors.mainBookerData.firstName && touched.mainBookerData.firstName) {
        return true;
      }
      if (errors.mainBookerData.lastName && touched.mainBookerData.lastName) {
        return true;
      }
      if (errors.mainBookerData.phone && touched.mainBookerData.phone) {
        return true;
      }
      if (errors.mainBookerData.countryOfBirthId && touched.mainBookerData.countryOfBirthId) {
        return true;
      }
    }

    if (errors.bookingAddress) {
      if (errors.bookingAddress.location && touched.address.location) {
        return true;
      }
      if (errors.bookingAddress.number && touched.address.number) {
        return true;
      }
      if (errors.bookingAddress.postalCode && touched.address.postalCode) {
        return true;
      }
      if (errors.bookingAddress.street && touched.address.street) {
        return true;
      }
    }

    return false;
  };

  const travellerHasErrors = (index: number) => {
    if (touched.travellers) {
      if (errors.travellers[index].dateOfBirth && touched.travellers[index].dateOfBirth) {
        return true;
      }
      if (errors.travellers[index].initials && touched.travellers[index].initials) {
        return true;
      }
      if (errors.travellers[index].firstName && touched.travellers[index].firstName) {
        return true;
      }
      if (errors.travellers[index].lastName && touched.travellers[index].lastName) {
        return true;
      }
      if (errors.travellers[index].countryOfBirthId && touched.travellers[index].countryOfBirthId) {
        return true;
      }
    }

    return false;
  };

  const handleMainBookerIsTraveller = (value: boolean) => {
    if (value) {
      setMainBookerIsTraveller("1");
      var mainBooker = { ...first(travellers) };
      if (mainBooker && mainBookerData) {
        setMainBookerData({ ...setTravellerData(mainBookerData, mainBooker as BookingPackagePax) });
      }
    } else {
      setMainBookerIsTraveller("2");
      if (mainBookerData) {
        setMainBookerData({
          ...setTravellerData(mainBookerData, {
            id: -1,
            dateOfBirth: "",
            age: 30,
            firstName: "",
            lastName: "",
            gender: 0,
            isMainBooker: true,
            initials: "",
            email: "",
            phone: "",
            mobilePhone: "",
            countryOfBirthId: 0,
          } as BookingPackagePax),
        });
      }
    }
  };

  return (
    <div className="booking__form-panel">
      <div className="booking__region">
        <div className="booking__card booking__card--transparent">
          <div className="booking__intro">
            <div className="heading-seperator">
              <h1 className="booking__region-heading">{translate(translations, language, "PERSONAL_INFORMATION")}</h1>
            </div>
          </div>
          <div className="booking__input">
            <strong className="booking__input--text">Gaat de hoofdboeker mee opreis?</strong>
            <div className="booking__input--wrapper">
              <label className="radiobutton">
                <input
                  type="radio"
                  className="radiobutton__input"
                  value="1"
                  name="mainbookerIsTraveller"
                  checked={mainBookerIsTraveller == "1"}
                  onChange={() => handleMainBookerIsTraveller(true)}
                />
                <span className="radiobutton__text">Ja</span>
              </label>
              <label className="radiobutton">
                <input
                  type="radio"
                  className="radiobutton__input"
                  value="2"
                  name="mainbookerIsTraveller"
                  checked={mainBookerIsTraveller == "2"}
                  onChange={() => handleMainBookerIsTraveller(false)}
                />
                <span className="radiobutton__text">Nee</span>
              </label>
            </div>
          </div>
          {mainBookerData && mainBookerIsTraveller == "2" && (
            <form className="form" id="form-personal-data-mainbooker">
              <div className="personal-data-card">
                <div className="form__region">
                  <div className="form__row">
                    <div className="form__group">
                      <div className="personal-data-card__header">
                        <Icon name="user" />
                        <h2 className="personal-data-card__heading">{translate(translations, language, "DATA_MAINBOOKER")}</h2>
                      </div>
                      <div className="personal-data-card__body">
                        <div className="form__row">
                          <div
                            className={`form__group form__group--100 form__group--md-33 ${
                              touched.address && touched.address.bookingType && errors.bookingAddress && errors.bookingAddress.bookingType
                                ? "form__group--error"
                                : ""
                            }`}
                          >
                            <label className="form__label">{translate(translations, language, "BOOKING_TYPE")}</label>
                            <div className="radiobutton-list radiobutton-list--inline">
                              <label className="radiobutton">
                                <input
                                  type="radio"
                                  className="radiobutton__input"
                                  value="1"
                                  name="customerType"
                                  checked={bookingAddress?.customerType?.toString() === "1"}
                                  onChange={(event) => changeAddress(event)}
                                />
                                <span className="radiobutton__text">{translate(translations, language, "PRIVATE")}</span>
                              </label>
                              <label className="radiobutton">
                                <input
                                  type="radio"
                                  className="radiobutton__input"
                                  value="2"
                                  name="customerType"
                                  checked={bookingAddress?.customerType?.toString() === "2"}
                                  onChange={(event) => changeAddress(event)}
                                />
                                <span className="radiobutton__text">{translate(translations, language, "BUSINESS")}</span>
                              </label>
                            </div>
                          </div>
                          {bookingAddress?.customerType?.toString() === "2" && (
                            <div className="form__group form__group--100 form__group--md-33" id="formGroupCompany">
                              <label className="form__label">{translate(translations, language, "COMPANY_NAME")}:</label>
                              <input
                                type="text"
                                className="form__input"
                                id="name"
                                name="name"
                                value={bookingAddress?.name}
                                placeholder={translate(translations, language, "COMPANY_NAME")}
                                onChange={(event) => changeAddress(event)}
                              />
                            </div>
                          )}
                        </div>
                        <div className="form__row">
                          <div
                            className={`form__group ${
                              touched.mainBookerData && touched.mainBookerData.gender && errors.mainBookerData && errors.mainBookerData.gender
                                ? "form__group--error"
                                : ""
                            }`}
                          >
                            <label className="form__label">{translate(translations, language, "SALUTATION")} *</label>
                            <div className="radiobutton-list radiobutton-list--inline">
                              <label className="radiobutton">
                                <input
                                  type="radio"
                                  className="radiobutton__input"
                                  value="0"
                                  name="gender-mainbooker"
                                  checked={mainBookerData.gender?.toString() === "0"}
                                  onChange={(event) => changeGenderMainBookerData(event)}
                                  onFocus={(event) => handleBlur(event, undefined, undefined, undefined, mainBookerData)}
                                />
                                <span className="radiobutton__text">{translate(translations, language, "SALUTATION_MR")}</span>
                              </label>
                              <label className="radiobutton">
                                <input
                                  type="radio"
                                  className="radiobutton__input"
                                  value="1"
                                  name="gender-mainbooker"
                                  checked={mainBookerData.gender?.toString() === "1"}
                                  onChange={(event) => changeGenderMainBookerData(event)}
                                  onFocus={(event) => handleBlur(event, undefined, undefined, undefined, mainBookerData)}
                                />
                                <span className="radiobutton__text">{translate(translations, language, "SALUTATION_MRS")}</span>
                              </label>
                              <label className="radiobutton">
                                <input
                                  type="radio"
                                  className="radiobutton__input"
                                  value="2"
                                  name="gender-mainbooker"
                                  checked={mainBookerData.gender?.toString() === "2"}
                                  onChange={(event) => changeGenderMainBookerData(event)}
                                  onFocus={(event) => handleBlur(event, undefined, undefined, undefined, mainBookerData)}
                                />
                                <span className="radiobutton__text">{translate(translations, language, "SALUTATION_DIFFERENT")}</span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="form__row">
                          <div
                            className={`form__group form__group--100 form__group--md-33 ${
                              touched.mainBookerData && touched.mainBookerData.initials && errors.mainBookerData && errors.mainBookerData.initials
                                ? "form__group--error"
                                : ""
                            }`}
                          >
                            {website?.content?.bookingFlow?.initialsInfo && (
                              <>
                                <TooltipModal
                                  handleHover={handleHover}
                                  hovered={hovered}
                                  suffix={"_mainBooker"}
                                  title={translate(translations, language, "INITIALS")}
                                  text={website.content.bookingFlow.initialsInfo}
                                  Offset={[5, 10]}
                                  required={false}
                                  leftAlign={true}
                                ></TooltipModal>
                                <label className="form__label form__label--has-tooltip"></label>
                              </>
                            )}
                            {!website?.content?.bookingFlow?.initialsInfo && (
                              <label className="form__label">{translate(translations, language, "INITIALS")}</label>
                            )}
                            <input
                              type="text"
                              className="form__input"
                              name="initials"
                              value={mainBookerData.initials}
                              onChange={(event) => changeMainBookerData(mainBookerData, event)}
                              onFocus={(event) => handleBlur(event, undefined, undefined, undefined, mainBookerData)}
                              placeholder={translate(translations, language, "INITIALS")}
                            />
                          </div>

                          <div
                            className={`form__group form__group--100 form__group--md-33 ${
                              touched.mainBookerData && touched.mainBookerData.firstName && errors.mainBookerData && errors.mainBookerData.firstName
                                ? "form__group--error"
                                : ""
                            }`}
                          >
                            <TooltipModal
                              handleHover={handleHover}
                              hovered={hovered}
                              suffix={"_mainBooker"}
                              title={translate(translations, language, "FIRSTNAME")}
                              text="Let u erop dat de eerste volledige voornaam zoals vermeld in het paspoort/ID invult en niet de roepnaam. VB: iemand heet Hans Janssen, echter in zijn paspoort staat zijn eerste volledige voornaam als Hermanus, U vult dus Hermanus in."
                              Offset={[5, 10]}
                              required={true}
                            ></TooltipModal>
                            <label className="form__label form__label--has-tooltip"></label>
                            <input
                              type="text"
                              className="form__input"
                              name="firstName"
                              value={mainBookerData.firstName}
                              onChange={(event) => changeMainBookerData(mainBookerData, event)}
                              onFocus={(event) => handleBlur(event, undefined, undefined, undefined, mainBookerData)}
                              placeholder={translate(translations, language, "FIRSTNAME")}
                              required
                            />
                          </div>

                          <div
                            className={`form__group form__group--100 form__group--md-33 ${
                              touched.mainBookerData && touched.mainBookerData.lastName && errors.mainBookerData && errors.mainBookerData.lastName
                                ? "form__group--error"
                                : ""
                            }`}
                          >
                            <TooltipModal
                              handleHover={handleHover}
                              hovered={hovered}
                              suffix={"_mainBooker"}
                              title={translate(translations, language, "LASTNAME")}
                              text="Bij vrouwen dient hier de meisjesnaam ingevuld te worden."
                              Offset={[5, 10]}
                              required={true}
                            ></TooltipModal>
                            <label className="form__label form__label--has-tooltip"></label>
                            <input
                              type="text"
                              className="form__input"
                              name="lastName"
                              value={mainBookerData.lastName}
                              onChange={(event) => changeMainBookerData(mainBookerData, event)}
                              onFocus={(event) => handleBlur(event, undefined, undefined, undefined, mainBookerData)}
                              placeholder={translate(translations, language, "LASTNAME")}
                              required
                            />
                          </div>
                        </div>
                        <div className="form__row">
                          <div
                            className={`form__group form__group--50 form__group--md-25 ${
                              touched.mainBookerData &&
                              touched.mainBookerData.dateOfBirth &&
                              errors.mainBookerData &&
                              errors.mainBookerData.dateOfBirth
                                ? "form__group--error"
                                : ""
                            }`}
                          >
                            <label className="form__label">{translate(translations, language, "DATE_OF_BIRTH")} *</label>
                            <TextMask
                              name="dateOfBirth"
                              className="form__input"
                              value={formatDate(mainBookerData.dateOfBirth)}
                              placeholder="DD/MM/YYYY"
                              mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                              onChange={(event) => changeMainBookerData(mainBookerData, event)}
                              onFocus={(event) => handleBlur(event, undefined, undefined, undefined, mainBookerData)}
                            />
                          </div>
                          <div
                            className={`form__group form__group--50 form__group--md-25 ${
                              touched.mainBookerData &&
                              touched.mainBookerData.countryOfBirthId &&
                              errors.mainBookerData &&
                              errors.mainBookerData.countryOfBirthId
                                ? "form__group--error"
                                : ""
                            }`}
                          >
                            <label className="form__label">{translate(translations, language, "NATIONALITY")} *</label>
                            <div className="select-themed">
                              <div className="select-themed__select">
                                <select
                                  name="countryOfBirthId"
                                  className="form__input"
                                  value={mainBookerData.countryOfBirthId ?? undefined}
                                  onChange={(event) => changeMainBookerNationality(event)}
                                  onFocus={(event) => handleBlur(event, undefined, undefined, undefined, mainBookerData)}
                                >
                                  <option value={0} key={1}></option>
                                  {nationalityCountries.length &&
                                    nationalityCountries.map((nationalityCountry, i) => (
                                      <option value={nationalityCountry.id} key={i + 1}>
                                        {nationalityCountry.name}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div
                            className={`form__group form__group--100 form__group--md-25 ${
                              touched.mainBookerData && touched.mainBookerData.email && errors.mainBookerData && errors.mainBookerData.email
                                ? "form__group--error"
                                : ""
                            }`}
                          >
                            <label className="form__label">{translate(translations, language, "EMAIL")} *</label>
                            <input
                              type="email"
                              className="form__input"
                              name="email"
                              value={mainBookerData.email}
                              onChange={(event) => changeMainBookerData(mainBookerData, event)}
                              onFocus={(event) => handleBlur(event, undefined, undefined, undefined, mainBookerData)}
                              placeholder={translate(translations, language, "EMAIL")}
                              required
                            />
                          </div>
                          <div
                            className={`form__group form__group--100 form__group--md-25 ${
                              touched.mainBookerData && touched.mainBookerData.phone && errors.mainBookerData && errors.mainBookerData.phone
                                ? "form__group--error"
                                : ""
                            }`}
                          >
                            <label className="form__label">{translate(translations, language, "PHONE")} *</label>
                            <input
                              type="telephone"
                              className="form__input"
                              name="phone"
                              value={mainBookerData.phone}
                              onChange={(event) => changeMainBookerData(mainBookerData, event)}
                              onFocus={(event) => handleBlur(event, undefined, undefined, undefined, mainBookerData)}
                              placeholder={translate(translations, language, "PHONE")}
                              required
                            />
                          </div>
                        </div>
                        <div className="form__row">
                          <div
                            className={`form__group form__group--50 form__group--lg-80 ${
                              touched.address && touched.address.street && errors.bookingAddress && errors.bookingAddress.street
                                ? "form__group--error"
                                : ""
                            }`}
                          >
                            <label className="form__label">{translate(translations, language, "STREET")} *</label>
                            <input
                              type="text"
                              className="form__input"
                              name="street"
                              defaultValue={bookingAddress?.street}
                              onChange={(event) => changeAddress(event)}
                              onFocus={(event) => handleBlur(event, undefined, undefined, bookingAddress)}
                              placeholder={translate(translations, language, "STREET")}
                              required
                            />
                          </div>
                          <div
                            className={`form__group form__group--25 form__group--lg-25 ${
                              touched.address && touched.address.number && errors.bookingAddress && errors.bookingAddress.number
                                ? "form__group--error"
                                : ""
                            }`}
                          >
                            <label className="form__label">{translate(translations, language, "NUMBER")} *</label>
                            <input
                              type="number"
                              className="form__input"
                              name="number"
                              defaultValue={bookingAddress?.number}
                              onChange={(event) => changeAddress(event)}
                              onFocus={(event) => handleBlur(event, undefined, undefined, bookingAddress)}
                              placeholder={translate(translations, language, "NUMBER")}
                              required
                            />
                          </div>
                          <div className="form__group form__group--25 form__group--lg-25">
                            <label className="form__label">{translate(translations, language, "ADDITION")}:</label>
                            <input
                              type="text"
                              className="form__input"
                              name="box"
                              defaultValue={bookingAddress?.box}
                              onChange={(event) => changeAddress(event)}
                              onFocus={(event) => handleBlur(event, undefined, undefined, bookingAddress)}
                              placeholder={translate(translations, language, "ADDITION")}
                              required
                            />
                          </div>
                        </div>
                        <div className="form__row">
                          <div
                            className={`form__group form__group--30 form__group--lg-20 ${
                              touched.address && touched.address.postalCode && errors.bookingAddress && errors.bookingAddress.postalCode
                                ? "form__group--error"
                                : ""
                            }`}
                          >
                            <label className="form__label">{translate(translations, language, "POSTALCODE")} *</label>
                            <input
                              type="text"
                              className="form__input"
                              name="postalCode"
                              defaultValue={bookingAddress?.postalCode}
                              onChange={(event) => changeAddress(event)}
                              onFocus={(event) => handleBlur(event, undefined, undefined, bookingAddress)}
                              placeholder={translate(translations, language, "POSTALCODE")}
                              required
                            />
                          </div>
                          <div
                            className={`form__group form__group--30 form__group--lg-20 ${
                              touched.address && touched.address.location && errors.bookingAddress && errors.bookingAddress.location
                                ? "form__group--error"
                                : ""
                            }`}
                          >
                            <label className="form__label">{translate(translations, language, "CITY")} *</label>
                            <input
                              type="text"
                              className="form__input"
                              name="location"
                              defaultValue={bookingAddress?.location}
                              onChange={(event) => changeAddress(event)}
                              onFocus={(event) => handleBlur(event, undefined, undefined, bookingAddress)}
                              placeholder={translate(translations, language, "CITY")}
                              required
                            />
                          </div>
                          <div
                            className={`form__group form__group--40 form__group--lg-60 ${
                              touched.address && touched.address.country && errors.bookingAddress && errors.bookingAddress.country
                                ? "form__group--error"
                                : ""
                            }`}
                          >
                            <label className="form__label">{translate(translations, language, "COUNTRY")} *</label>
                            <div className="select-themed">
                              <div className="select-themed__select">
                                <select
                                  value={bookingAddress?.country}
                                  onChange={(event) => changeCountry(event)}
                                  onFocus={(event) => handleBlur(event, undefined, undefined, bookingAddress)}
                                >
                                  {tideCountries.length &&
                                    tideCountries.map((country, i) => (
                                      <option value={country.value ?? country.tideId} key={i}>
                                        {country.name}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}

          {travellers &&
            travellers?.length > 0 &&
            travellers.map((traveller, i) => (
              <form key={i} className="form" id={`form-personal-data-traveller-${i}`}>
                <div className="personal-data-card">
                  <div className="form__region">
                    <div className="form__row">
                      <div className="form__group">
                        <div className="personal-data-card__header">
                          <Icon name="user" />
                          <h2 className="personal-data-card__heading">
                            {translate(translations, language, "TRAVELLER")} {i + 1}
                          </h2>
                          {traveller.age && traveller.age >= 12 ? (
                            <span className="personal-data-card__traveler-type">
                              {translate(translations, language, "ADULT")}
                              {i == 0 && mainBookerIsTraveller == "1" && <>, {translate(translations, language, "MAINBOOKER")}</>}
                            </span>
                          ) : (
                            <span className="personal-data-card__traveler-type">{translate(translations, language, "CHILD")}</span>
                          )}
                        </div>
                        <div className="personal-data-card__body">
                          {mainBookerData && mainBookerIsTraveller == "1" && i == 0 && (
                            <div className="form__row">
                              <div
                                className={`form__group form__group--100 form__group--md-33 ${
                                  touched.address && touched.address.bookingType && errors.bookingAddress && errors.bookingAddress.bookingType
                                    ? "form__group--error"
                                    : ""
                                }`}
                              >
                                <label className="form__label">{translate(translations, language, "BOOKING_TYPE")}</label>
                                <div className="radiobutton-list radiobutton-list--inline">
                                  <label className="radiobutton">
                                    <input
                                      type="radio"
                                      className="radiobutton__input"
                                      value="1"
                                      name="customerType"
                                      checked={bookingAddress?.customerType?.toString() === "1"}
                                      onChange={(event) => changeAddress(event)}
                                    />
                                    <span className="radiobutton__text">{translate(translations, language, "PRIVATE")}</span>
                                  </label>
                                  <label className="radiobutton">
                                    <input
                                      type="radio"
                                      className="radiobutton__input"
                                      value="2"
                                      name="customerType"
                                      checked={bookingAddress?.customerType?.toString() === "2"}
                                      onChange={(event) => changeAddress(event)}
                                    />
                                    <span className="radiobutton__text">{translate(translations, language, "BUSINESS")}</span>
                                  </label>
                                </div>
                              </div>
                              {bookingAddress?.customerType?.toString() === "2" && (
                                <div className="form__group form__group--100 form__group--md-33" id="formGroupCompany">
                                  <label className="form__label">{translate(translations, language, "COMPANY_NAME")}:</label>
                                  <input
                                    type="text"
                                    className="form__input"
                                    id="name"
                                    name="name"
                                    value={bookingAddress?.name}
                                    placeholder={translate(translations, language, "COMPANY_NAME")}
                                    onChange={(event) => changeAddress(event)}
                                  />
                                </div>
                              )}
                            </div>
                          )}
                          <div className="form__row">
                            <div
                              className={`form__group ${
                                touched.travellers &&
                                touched.travellers[i] &&
                                touched.travellers[i].gender &&
                                errors.travellers &&
                                errors.travellers[i].gender
                                  ? "form__group--error"
                                  : ""
                              }`}
                            >
                              <label className="form__label">{translate(translations, language, "SALUTATION")} *</label>
                              <div className="radiobutton-list radiobutton-list--inline">
                                <label className="radiobutton">
                                  <input
                                    type="radio"
                                    className="radiobutton__input"
                                    value="0"
                                    name={`gender-traveller` + i}
                                    checked={traveller.gender?.toString() === "0"}
                                    onChange={(event) => changeGender(traveller, event, i)}
                                    onFocus={(event) => handleBlur(event, traveller, undefined, undefined)}
                                  />
                                  <span className="radiobutton__text">{translate(translations, language, "SALUTATION_MR")}</span>
                                </label>
                                <label className="radiobutton">
                                  <input
                                    type="radio"
                                    className="radiobutton__input"
                                    value="1"
                                    name={`gender-traveller` + i}
                                    checked={traveller.gender?.toString() === "1"}
                                    onChange={(event) => changeGender(traveller, event, i)}
                                    onFocus={(event) => handleBlur(event, traveller, undefined, undefined)}
                                  />
                                  <span className="radiobutton__text">{translate(translations, language, "SALUTATION_MRS")}</span>
                                </label>
                                <label className="radiobutton">
                                  <input
                                    type="radio"
                                    className="radiobutton__input"
                                    value="2"
                                    name={`gender-traveller` + i}
                                    checked={traveller.gender?.toString() === "2"}
                                    onChange={(event) => changeGender(traveller, event, i)}
                                    onFocus={(event) => handleBlur(event, traveller, undefined, undefined)}
                                  />
                                  <span className="radiobutton__text">{translate(translations, language, "SALUTATION_DIFFERENT")}</span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="form__row">
                            <div
                              className={`form__group form__group--100 form__group--md-33 ${
                                touched.travellers &&
                                touched.travellers[i] &&
                                touched.travellers[i].initials &&
                                errors.travellers &&
                                errors.travellers[i].initials
                                  ? "form__group--error"
                                  : ""
                              }`}
                            >
                              {website?.content?.bookingFlow?.initialsInfo && (
                                <>
                                  <TooltipModal
                                    handleHover={handleHover}
                                    hovered={hovered}
                                    suffix={`traveller_${i}`}
                                    title={translate(translations, language, "INITIALS")}
                                    text={website.content.bookingFlow.initialsInfo}
                                    Offset={[5, 10]}
                                    required={false}
                                    leftAlign={true}
                                  ></TooltipModal>
                                  <label className="form__label form__label--has-tooltip"></label>
                                </>
                              )}
                              {!website?.content?.bookingFlow?.initialsInfo && (
                                <label className="form__label">{translate(translations, language, "INITIALS")}</label>
                              )}
                              <input
                                type="text"
                                className="form__input"
                                name="initials"
                                value={traveller.initials}
                                onChange={(event) => changeTraveller(traveller, event, i)}
                                onFocus={(event) => handleBlur(event, traveller, undefined, undefined)}
                                placeholder={translate(translations, language, "INITIALS")}
                              />
                            </div>
                            <div
                              className={`form__group form__group--100 form__group--md-33 ${
                                touched.travellers &&
                                touched.travellers[i] &&
                                touched.travellers[i].firstName &&
                                errors.travellers &&
                                errors.travellers[i].firstName
                                  ? "form__group--error"
                                  : ""
                              }`}
                            >
                              <TooltipModal
                                handleHover={handleHover}
                                hovered={hovered}
                                suffix={`traveller_${i}`}
                                title={translate(translations, language, "FIRSTNAME")}
                                text="Let u erop dat de eerste volledige voornaam zoals vermeld in het paspoort/ID invult en niet de roepnaam. VB: iemand heet Hans Janssen, echter in zijn paspoort staat zijn eerste volledige voornaam als Hermanus, U vult dus Hermanus in."
                                Offset={[5, 10]}
                                required={true}
                              ></TooltipModal>
                              <label className="form__label form__label--has-tooltip"></label>
                              <input
                                type="text"
                                className="form__input"
                                name="firstName"
                                value={traveller.firstName}
                                onChange={(event) => changeTraveller(traveller, event, i)}
                                onFocus={(event) => handleBlur(event, traveller, undefined, undefined)}
                                placeholder={translate(translations, language, "FIRSTNAME")}
                                required
                              />
                            </div>
                            <div
                              className={`form__group form__group--100 form__group--md-33 ${
                                touched.travellers &&
                                touched.travellers[i] &&
                                touched.travellers[i].lastName &&
                                errors.travellers &&
                                errors.travellers[i].lastName
                                  ? "form__group--error"
                                  : ""
                              }`}
                            >
                              <TooltipModal
                                handleHover={handleHover}
                                hovered={hovered}
                                suffix={`traveller_${i}`}
                                title={translate(translations, language, "LASTNAME")}
                                text="Bij vrouwen dient hier de meisjesnaam ingevuld te worden."
                                Offset={[5, 10]}
                                required={true}
                              ></TooltipModal>
                              <label className="form__label form__label--has-tooltip"></label>
                              <input
                                type="text"
                                className="form__input"
                                name="lastName"
                                value={traveller.lastName}
                                onChange={(event) => changeTraveller(traveller, event, i)}
                                onFocus={(event) => handleBlur(event, traveller, undefined, undefined)}
                                placeholder={translate(translations, language, "LASTNAME")}
                                required
                              />
                            </div>
                          </div>

                          {mainBookerData && mainBookerIsTraveller == "1" && i == 0 ? (
                            <>
                              <div className="form__row">
                                <div
                                  className={`form__group form__group--50 form__group--md-25 ${
                                    touched.travellers &&
                                    touched.travellers[i] &&
                                    touched.travellers[i].dateOfBirth &&
                                    errors.travellers &&
                                    errors.travellers[i].dateOfBirth
                                      ? "form__group--error"
                                      : ""
                                  }`}
                                >
                                  <label className="form__label">{translate(translations, language, "DATE_OF_BIRTH")} *</label>
                                  <TextMask
                                    name="dateOfBirth"
                                    className="form__input"
                                    value={formatDate(traveller.dateOfBirth)}
                                    placeholder="DD/MM/YYYY"
                                    mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                                    onChange={(event) => changeTraveller(traveller, event, i)}
                                    onFocus={(event) => handleBlur(event, traveller, undefined, undefined)}
                                  />
                                </div>
                                <div
                                  className={`form__group form__group--50 form__group--md-25 ${
                                    touched.travellers &&
                                    touched.travellers[i] &&
                                    touched.travellers[i].countryOfBirthId &&
                                    errors.travellers &&
                                    errors.travellers[i].countryOfBirthId
                                      ? "form__group--error"
                                      : ""
                                  }`}
                                >
                                  <label className="form__label">{translate(translations, language, "NATIONALITY")} *</label>
                                  <div className="select-themed">
                                    <div className="select-themed__select">
                                      <select
                                        name="countryOfBirthId"
                                        className="form__input"
                                        value={traveller.countryOfBirthId ?? undefined}
                                        onChange={(event) => changeTravellerNationality(traveller, event, i)}
                                        onFocus={(event) => handleBlur(event, traveller, undefined, undefined)}
                                      >
                                        <option value={0} key={1}></option>
                                        {nationalityCountries.length &&
                                          nationalityCountries.map((nationalityCountry, i) => (
                                            <option value={nationalityCountry.id} key={i + 1}>
                                              {nationalityCountry.name}
                                            </option>
                                          ))}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`form__group form__group--100 form__group--md-25 ${
                                    touched.mainBookerData && touched.mainBookerData.email && errors.mainBookerData && errors.mainBookerData.email
                                      ? "form__group--error"
                                      : ""
                                  }`}
                                >
                                  <label className="form__label">{translate(translations, language, "EMAIL")} *</label>
                                  <input
                                    type="email"
                                    className="form__input"
                                    name="email"
                                    value={mainBookerData.email}
                                    onChange={(event) => changeMainBookerData(mainBookerData, event)}
                                    onFocus={(event) => handleBlur(event, undefined, undefined, undefined, mainBookerData)}
                                    placeholder={translate(translations, language, "EMAIL")}
                                    required
                                  />
                                </div>
                                <div
                                  className={`form__group form__group--100 form__group--md-25 ${
                                    touched.mainBookerData && touched.mainBookerData.phone && errors.mainBookerData && errors.mainBookerData.phone
                                      ? "form__group--error"
                                      : ""
                                  }`}
                                >
                                  <label className="form__label">{translate(translations, language, "PHONE")} *</label>
                                  <input
                                    type="telephone"
                                    className="form__input"
                                    name="phone"
                                    value={mainBookerData.phone}
                                    onChange={(event) => changeMainBookerData(mainBookerData, event)}
                                    onFocus={(event) => handleBlur(event, undefined, undefined, undefined, mainBookerData)}
                                    placeholder={translate(translations, language, "PHONE")}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="form__row">
                                <div
                                  className={`form__group form__group--50 form__group--lg-80 ${
                                    touched.address && touched.address.street && errors.bookingAddress && errors.bookingAddress.street
                                      ? "form__group--error"
                                      : ""
                                  }`}
                                >
                                  <label className="form__label">{translate(translations, language, "STREET")} *</label>
                                  <input
                                    type="text"
                                    className="form__input"
                                    name="street"
                                    defaultValue={bookingAddress?.street}
                                    onChange={(event) => changeAddress(event)}
                                    onFocus={(event) => handleBlur(event, undefined, undefined, bookingAddress)}
                                    placeholder={translate(translations, language, "STREET")}
                                    required
                                  />
                                </div>
                                <div
                                  className={`form__group form__group--25 form__group--lg-25 ${
                                    touched.address && touched.address.number && errors.bookingAddress && errors.bookingAddress.number
                                      ? "form__group--error"
                                      : ""
                                  }`}
                                >
                                  <label className="form__label">{translate(translations, language, "NUMBER")} *</label>
                                  <input
                                    type="number"
                                    className="form__input"
                                    name="number"
                                    defaultValue={bookingAddress?.number}
                                    onChange={(event) => changeAddress(event)}
                                    onFocus={(event) => handleBlur(event, undefined, undefined, bookingAddress)}
                                    placeholder={translate(translations, language, "NUMBER")}
                                    required
                                  />
                                </div>
                                <div className="form__group form__group--25 form__group--lg-25">
                                  <label className="form__label">{translate(translations, language, "ADDITION")}:</label>
                                  <input
                                    type="text"
                                    className="form__input"
                                    name="box"
                                    defaultValue={bookingAddress?.box}
                                    onChange={(event) => changeAddress(event)}
                                    onFocus={(event) => handleBlur(event, undefined, undefined, bookingAddress)}
                                    placeholder={translate(translations, language, "ADDITION")}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="form__row">
                                <div
                                  className={`form__group form__group--30 form__group--lg-20 ${
                                    touched.address && touched.address.postalCode && errors.bookingAddress && errors.bookingAddress.postalCode
                                      ? "form__group--error"
                                      : ""
                                  }`}
                                >
                                  <label className="form__label">{translate(translations, language, "POSTALCODE")} *</label>
                                  <input
                                    type="text"
                                    className="form__input"
                                    name="postalCode"
                                    defaultValue={bookingAddress?.postalCode}
                                    onChange={(event) => changeAddress(event)}
                                    onFocus={(event) => handleBlur(event, undefined, undefined, bookingAddress)}
                                    placeholder={translate(translations, language, "POSTALCODE")}
                                    required
                                  />
                                </div>
                                <div
                                  className={`form__group form__group--30 form__group--lg-20 ${
                                    touched.address && touched.address.location && errors.bookingAddress && errors.bookingAddress.location
                                      ? "form__group--error"
                                      : ""
                                  }`}
                                >
                                  <label className="form__label">{translate(translations, language, "CITY")} *</label>
                                  <input
                                    type="text"
                                    className="form__input"
                                    name="location"
                                    defaultValue={bookingAddress?.location}
                                    onChange={(event) => changeAddress(event)}
                                    onFocus={(event) => handleBlur(event, undefined, undefined, bookingAddress)}
                                    placeholder={translate(translations, language, "CITY")}
                                    required
                                  />
                                </div>
                                <div
                                  className={`form__group form__group--40 form__group--lg-60 ${
                                    touched.address && touched.address.country && errors.bookingAddress && errors.bookingAddress.country
                                      ? "form__group--error"
                                      : ""
                                  }`}
                                >
                                  <label className="form__label">{translate(translations, language, "COUNTRY")} *</label>
                                  <div className="select-themed">
                                    <div className="select-themed__select">
                                      <select
                                        value={bookingAddress?.country}
                                        onChange={(event) => changeCountry(event)}
                                        onFocus={(event) => handleBlur(event, undefined, undefined, bookingAddress)}
                                      >
                                        {tideCountries.length &&
                                          tideCountries.map((country, i) => (
                                            <option value={country.value ?? country.tideId} key={i}>
                                              {country.name}
                                            </option>
                                          ))}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className="form__row">
                              <div
                                className={`form__group form__group--50 form__group--md-33 ${
                                  touched.travellers &&
                                  touched.travellers[i] &&
                                  touched.travellers[i].dateOfBirth &&
                                  errors.travellers &&
                                  errors.travellers[i].dateOfBirth
                                    ? "form__group--error"
                                    : ""
                                }`}
                              >
                                <label className="form__label">{translate(translations, language, "DATE_OF_BIRTH")} *</label>
                                <TextMask
                                  name="dateOfBirth"
                                  className="form__input"
                                  value={formatDate(traveller.dateOfBirth)}
                                  placeholder="DD/MM/YYYY"
                                  mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                                  onChange={(event) => changeTraveller(traveller, event, i)}
                                  onFocus={(event) => handleBlur(event, traveller, undefined, undefined)}
                                />
                              </div>
                              <div
                                className={`form__group form__group--50 form__group--md-66 ${
                                  touched.travellers &&
                                  touched.travellers[i] &&
                                  touched.travellers[i].countryOfBirthId &&
                                  errors.travellers &&
                                  errors.travellers[i].countryOfBirthId
                                    ? "form__group--error"
                                    : ""
                                }`}
                              >
                                <label className="form__label">{translate(translations, language, "NATIONALITY")} *</label>
                                <div className="select-themed">
                                  <div className="select-themed__select">
                                    <select
                                      name="countryOfBirthId"
                                      className="form__input"
                                      value={traveller.countryOfBirthId ?? undefined}
                                      onChange={(event) => changeTravellerNationality(traveller, event, i)}
                                      onFocus={(event) => handleBlur(event, traveller, undefined, undefined)}
                                    >
                                      <option value={0} key={1}></option>
                                      {nationalityCountries.length &&
                                        nationalityCountries.map((nationalityCountry, i) => (
                                          <option value={nationalityCountry.id} key={i + 1}>
                                            {nationalityCountry.name}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            ))}

          {stayAtHome && (
            <form className="form" id="form-personal-data-stayathome">
              <div className="personal-data-card personal-data-card--accordion">
                <div className="form__region">
                  <div className="form__row">
                    <div className="form__group">
                      <div className="accordion-wrapper">
                        <div className={`accordion__section ${stayAtHomeActive ? "accordion__section--active" : ""}`}>
                          <button className="accordion" type="button" onClick={() => setStayAtHomeActive(!stayAtHomeActive)}>
                            <div className="accordion__title">
                              <div className="personal-data-card__header">
                                <Icon name="user" />
                                <h2 className="personal-data-card__heading">{translate(translations, language, "STAY_AT_HOME")}</h2>
                              </div>
                            </div>
                          </button>
                          <div className="accordion__content">
                            <div className="accordion__text">
                              <div className="personal-data-card__body">
                                <div className="form__row">
                                  <div className="form__group">
                                    <label className="form__label">{translate(translations, language, "SALUTATION")}</label>
                                    <div className="radiobutton-list radiobutton-list--inline">
                                      <label className="radiobutton">
                                        <input
                                          type="radio"
                                          className="radiobutton__input"
                                          value="0"
                                          name="gender-stayathome"
                                          checked={stayAtHome.gender?.toString() === "0"}
                                          onChange={(event) => changeGenderStayAtHome(event)}
                                          onFocus={(event) => handleBlur(event, undefined, stayAtHome, undefined)}
                                        />
                                        <span className="radiobutton__text">{translate(translations, language, "SALUTATION_MR")}</span>
                                      </label>
                                      <label className="radiobutton">
                                        <input
                                          type="radio"
                                          className="radiobutton__input"
                                          value="1"
                                          name="gender-stayathome"
                                          checked={stayAtHome.gender?.toString() === "1"}
                                          onChange={(event) => changeGenderStayAtHome(event)}
                                          onFocus={(event) => handleBlur(event, undefined, stayAtHome, undefined)}
                                        />
                                        <span className="radiobutton__text">{translate(translations, language, "SALUTATION_MRS")}</span>
                                      </label>
                                      <label className="radiobutton">
                                        <input
                                          type="radio"
                                          className="radiobutton__input"
                                          value="2"
                                          name="gender-stayathome"
                                          checked={stayAtHome.gender?.toString() === "2"}
                                          onChange={(event) => changeGenderStayAtHome(event)}
                                          onFocus={(event) => handleBlur(event, undefined, stayAtHome, undefined)}
                                        />
                                        <span className="radiobutton__text">{translate(translations, language, "SALUTATION_DIFFERENT")}</span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="form__row">
                                  <div className="form__group form__group--100 form__group--md-25">
                                    {website?.content?.bookingFlow?.initialsInfo && (
                                      <>
                                        <TooltipModal
                                          handleHover={handleHover}
                                          hovered={hovered}
                                          suffix={"stayAtHome"}
                                          title={translate(translations, language, "INITIALS")}
                                          text={website.content.bookingFlow.initialsInfo}
                                          Offset={[5, 10]}
                                          required={false}
                                          leftAlign={true}
                                        ></TooltipModal>
                                        <label className="form__label form__label--has-tooltip"></label>
                                      </>
                                    )}
                                    {!website?.content?.bookingFlow?.initialsInfo && (
                                      <label className="form__label">{translate(translations, language, "INITIALS")}</label>
                                    )}
                                    <input
                                      type="text"
                                      className="form__input"
                                      name="initials"
                                      value={stayAtHome.initials}
                                      onChange={(event) => changeStayAtHomeTraveller(stayAtHome, event)}
                                      onFocus={(event) => handleBlur(event, undefined, stayAtHome, undefined)}
                                      placeholder={translate(translations, language, "INITIALS")}
                                    />
                                  </div>
                                  <div className="form__group form__group--100 form__group--md-25">
                                    <TooltipModal
                                      handleHover={handleHover}
                                      hovered={hovered}
                                      suffix={"stayAtHome"}
                                      title={translate(translations, language, "FIRSTNAME")}
                                      text="Let u erop dat de eerste volledige voornaam zoals vermeld in het paspoort/ID invult en niet de roepnaam. VB: iemand heet Hans Janssen, echter in zijn paspoort staat zijn eerste volledige voornaam als Hermanus, U vult dus Hermanus in."
                                      Offset={[5, 10]}
                                      required={true}
                                    ></TooltipModal>
                                    <label className="form__label form__label--has-tooltip"></label>
                                    <input
                                      type="text"
                                      className="form__input"
                                      name="firstName"
                                      value={stayAtHome.firstName}
                                      onChange={(event) => changeStayAtHomeTraveller(stayAtHome, event)}
                                      onFocus={(event) => handleBlur(event, undefined, stayAtHome, undefined)}
                                      placeholder={translate(translations, language, "FIRSTNAME")}
                                    />
                                  </div>
                                  <div className="form__group form__group--100 form__group--md-25">
                                    <TooltipModal
                                      handleHover={handleHover}
                                      hovered={hovered}
                                      suffix={"stayAtHome"}
                                      title={translate(translations, language, "LASTNAME")}
                                      text="Bij vrouwen dient hier de meisjesnaam ingevuld te worden."
                                      Offset={[5, 10]}
                                      required={true}
                                    ></TooltipModal>
                                    <label className="form__label form__label--has-tooltip"></label>
                                    <input
                                      type="text"
                                      className="form__input"
                                      name="lastName"
                                      value={stayAtHome.lastName}
                                      onChange={(event) => changeStayAtHomeTraveller(stayAtHome, event)}
                                      onFocus={(event) => handleBlur(event, undefined, stayAtHome, undefined)}
                                      placeholder={translate(translations, language, "LASTNAME")}
                                    />
                                  </div>
                                  <div className="form__group form__group--100 form__group--md-25">
                                    <label className="form__label">{translate(translations, language, "PHONE")}</label>
                                    <input
                                      type="telephone"
                                      className="form__input"
                                      name="phone"
                                      value={stayAtHome.phone}
                                      onChange={(event) => changeStayAtHomeTraveller(stayAtHome, event)}
                                      onFocus={(event) => handleBlur(event, undefined, stayAtHome, undefined)}
                                      placeholder={translate(translations, language, "PHONE")}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>

        {errors && Object.keys(errors).length > 0 && (
          <div className="booking__card booking__card--transparent booking__card--transparent-errors">
            <form className="form" id="form-personal-data-errors">
              <div className="form__errors">
                <h2 className="form__errors-heading">{translate(translations, language, "CHECK_FIELDS")}</h2>
                <p>Bovenstaande velden zijn verplicht in te vullen vooraleer u verder kan gaan met uw boeking.</p>
                {mainBookerHasErrors() && (
                  <div className="form__errors-group">
                    <strong>{translate(translations, language, "MAINBOOKER")}:</strong>
                    <ul>
                      {errors.mainBookerData && (
                        <>
                          {errors.mainBookerData.initials && touched.mainBookerData.initials && (
                            <li>
                              <a className="form__errors-anchor" title={translate(translations, language, errors.mainBookerData.initials)}>
                                {translate(translations, language, errors.mainBookerData.initials)}:
                              </a>
                            </li>
                          )}
                          {errors.mainBookerData.firstName && touched.mainBookerData.firstName && (
                            <li>
                              <a className="form__errors-anchor" title={translate(translations, language, errors.mainBookerData.firstName)}>
                                {translate(translations, language, errors.mainBookerData.firstName)}:
                              </a>
                            </li>
                          )}
                          {errors.mainBookerData.lastName && touched.mainBookerData.lastName && (
                            <li>
                              <a className="form__errors-anchor" title={translate(translations, language, errors.mainBookerData.lastName)}>
                                {translate(translations, language, errors.mainBookerData.lastName)}:
                              </a>
                            </li>
                          )}
                          {errors.mainBookerData.dateOfBirth && touched.mainBookerData.dateOfBirth && (
                            <li>
                              <a className="form__errors-anchor" title={translate(translations, language, errors.mainBookerData.dateOfBirth)}>
                                {translate(translations, language, errors.mainBookerData.dateOfBirth)}:
                              </a>
                            </li>
                          )}
                          {errors.mainBookerData.gender && touched.mainBookerData.gender && (
                            <li>
                              <a className="form__errors-anchor" title={translate(translations, language, errors.mainBookerData.gender)}>
                                {translate(translations, language, errors.mainBookerData.gender)}:
                              </a>
                            </li>
                          )}
                          {errors.mainBookerData.email && touched.mainBookerData.email && (
                            <li>
                              <a className="form__errors-anchor" title={translate(translations, language, errors.mainBookerData.email)}>
                                {translate(translations, language, errors.mainBookerData.email)}:
                              </a>
                            </li>
                          )}
                          {errors.mainBookerData.phone && touched.mainBookerData.phone && (
                            <li>
                              <a className="form__errors-anchor" title={translate(translations, language, errors.mainBookerData.phone)}>
                                {translate(translations, language, errors.mainBookerData.phone)}:
                              </a>
                            </li>
                          )}
                          {errors.mainBookerData.mobilePhone && touched.mainBookerData.mobilePhone && (
                            <li>
                              <a className="form__errors-anchor" title={translate(translations, language, errors.mainBookerData.mobilePhone)}>
                                {translate(translations, language, errors.mainBookerData.mobilePhone)}:
                              </a>
                            </li>
                          )}
                          {errors.mainBookerData.countryOfBirthId && touched.mainBookerData.countryOfBirthId && (
                            <li>
                              <a className="form__errors-anchor" title={translate(translations, language, errors.mainBookerData.countryOfBirthId)}>
                                {translate(translations, language, errors.mainBookerData.countryOfBirthId)}:
                              </a>
                            </li>
                          )}
                        </>
                      )}
                      {errors.bookingAddress && (
                        <>
                          {errors.bookingAddress.street && touched.address.street && (
                            <li>
                              <a className="form__errors-anchor" title={translate(translations, language, errors.bookingAddress.street)}>
                                {translate(translations, language, errors.bookingAddress.street)}:
                              </a>
                            </li>
                          )}
                          {errors.bookingAddress.number && touched.address.number && (
                            <li>
                              <a className="form__errors-anchor" title={translate(translations, language, errors.bookingAddress.number)}>
                                {translate(translations, language, errors.bookingAddress.number)}:
                              </a>
                            </li>
                          )}
                          {errors.bookingAddress.postalCode && touched.address.postalCode && (
                            <li>
                              <a className="form__errors-anchor" title={translate(translations, language, errors.bookingAddress.postalCode)}>
                                {translate(translations, language, errors.bookingAddress.postalCode)}:
                              </a>
                            </li>
                          )}
                          {errors.bookingAddress.location && touched.address.location && (
                            <li>
                              <a className="form__errors-anchor" title={translate(translations, language, errors.bookingAddress.location)}>
                                {translate(translations, language, errors.bookingAddress.location)}:
                              </a>
                            </li>
                          )}
                          {errors.bookingAddress.country && touched.address.country && (
                            <li>
                              <a className="form__errors-anchor" title={translate(translations, language, errors.bookingAddress.country)}>
                                {translate(translations, language, errors.bookingAddress.country)}:
                              </a>
                            </li>
                          )}
                        </>
                      )}
                    </ul>
                    <br />
                  </div>
                )}
                {errors.travellers &&
                  errors.travellers.map(
                    (traveller: any, i: any) =>
                      travellerHasErrors(i) && (
                        <div key={i} className="form__errors-group">
                          <strong>{translate(translations, language, "TRAVELLER") + " " + (i + 1)}:</strong>
                          <ul>
                            {traveller.initials && touched.travellers && touched.travellers[i].initials && (
                              <li>
                                <a className="form__errors-anchor" title={translate(translations, language, traveller.initials)}>
                                  {translate(translations, language, traveller.initials)}:
                                </a>
                              </li>
                            )}
                            {traveller.firstName && touched.travellers && touched.travellers[i].firstName && (
                              <li>
                                <a className="form__errors-anchor" title={translate(translations, language, traveller.firstName)}>
                                  {translate(translations, language, traveller.firstName)}:
                                </a>
                              </li>
                            )}
                            {traveller.lastName && touched.travellers && touched.travellers[i].lastName && (
                              <li>
                                <a className="form__errors-anchor" title={translate(translations, language, traveller.lastName)}>
                                  {translate(translations, language, traveller.lastName)}:
                                </a>
                              </li>
                            )}
                            {traveller.dateOfBirth && touched.travellers && touched.travellers[i].dateOfBirth && (
                              <li>
                                <a className="form__errors-anchor" title={translate(translations, language, traveller.dateOfBirth)}>
                                  {translate(translations, language, traveller.dateOfBirth)}:
                                </a>
                              </li>
                            )}
                            {traveller.countryOfBirthId && touched.travellers && touched.travellers[i].countryOfBirthId && (
                              <li>
                                <a className="form__errors-anchor" title={translate(translations, language, traveller.countryOfBirthId)}>
                                  {translate(translations, language, traveller.countryOfBirthId)}:
                                </a>
                              </li>
                            )}
                            {traveller.gender && touched.travellers && touched.travellers[i].gender && (
                              <li>
                                <a className="form__errors-anchor" title={translate(translations, language, traveller.gender)}>
                                  {translate(translations, language, traveller.gender)}:
                                </a>
                              </li>
                            )}
                            {traveller.email && touched.travellers && touched.travellers[i].email && (
                              <li>
                                <a className="form__errors-anchor" title={translate(translations, language, traveller.email)}>
                                  {translate(translations, language, traveller.email)}:
                                </a>
                              </li>
                            )}
                            {traveller.phone && touched.travellers && touched.travellers[i].phone && (
                              <li>
                                <a className="form__errors-anchor" title={translate(translations, language, traveller.phone)}>
                                  {translate(translations, language, traveller.phone)}:
                                </a>
                              </li>
                            )}
                            {traveller.mobilePhone && touched.travellers && touched.travellers[i].mobilePhone && (
                              <li>
                                <a className="form__errors-anchor" title={translate(translations, language, traveller.mobilePhone)}>
                                  {translate(translations, language, traveller.mobilePhone)}:
                                </a>
                              </li>
                            )}
                          </ul>
                          <br />
                        </div>
                      )
                  )}
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default BookingStep5;
