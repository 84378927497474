import React, { useContext } from "react";
import GlobalContext, { GlobalContextType } from "../../contexts/global-provider";
import translate from "../../utils/translate";
import translations from "./translations.json";
import BookingContext, { BookingContextType } from "../../contexts/booking-provider";
import { Image, Maybe, TideItemForCategory, TideItemForEvenementLocatie, TideItemForExcursion } from "../../types";
import { getChildItemsWithTypename, getSpecificChildItemWithName, parse } from "../../utils/component";
import { first } from "lodash";
import Carousel from "nuka-carousel";
import TicketsUsps from "../tickets-usps";
import { addWidthParameter } from "../../utils/image-utils";

interface TicketsExtrasProps {
  venue?: TideItemForEvenementLocatie;
  excursions: TideItemForExcursion[];
}

const TicketsExtras: React.FC<TicketsExtrasProps> = ({ venue }) => {
  const { language } = useContext<GlobalContextType>(GlobalContext);
  const { bookingPackage } = useContext<BookingContextType>(BookingContext);

  const selectedTicket = first(bookingPackage?.options.find((o) => o.isSelected)?.rooms)?.options.find((o) => o.isSelected);
  const categories = getChildItemsWithTypename("TideItemForCategory", venue) as TideItemForCategory[];

  let category = selectedTicket && categories?.find((x) => x.content?.general?.id == selectedTicket.externalAccommodationCode);
  if (!category && selectedTicket && (!selectedTicket.externalAccommodationCode || selectedTicket.externalAccommodationCode == "")) {
    category = getSpecificChildItemWithName(selectedTicket?.accommodationName, venue) as TideItemForCategory;
  }

  const getBottomControls = (images: Maybe<Maybe<Image>[]>, currentSlide: any, goToSlide: any) => {
    return (
      <div className="carousel__indicators">
        {images &&
          images.map((image, i) => (
            <button
              key={i}
              type="button"
              className={`carousel__indicator ${currentSlide === i ? "carousel__indicator--active" : ""}`}
              onClick={() => goToSlide(i)}
            ></button>
          ))}
      </div>
    );
  };

  return (
    <div className="tickets-extras">
      <TicketsUsps venue={venue?.content?.general?.title ?? ""}></TicketsUsps>
      <div className="tickets-extras__seating">
        {category?.content?.general?.gallery && category?.content?.general?.gallery?.length > 0 ? (
          <>
            <h4 className="tickets-extras__heading">{translate(translations, language, "VIEW")}</h4>
            <div className="tickets-extras__sight">
              {category?.content?.general?.gallery?.length > 1 ? (
                <Carousel
                  slidesToShow={1}
                  renderBottomCenterControls={({ currentSlide, goToSlide }) =>
                    getBottomControls(category?.content?.general?.gallery!, currentSlide, goToSlide)
                  }
                  defaultControlsConfig={{
                    nextButtonClassName: "carousel__control carousel__control--next",
                    nextButtonText: " ",
                    prevButtonClassName: "carousel__control carousel__control--prev",
                    prevButtonText: " ",
                  }}
                >
                  {category?.content?.general?.gallery.map((image, i) => (
                    <img key={i} src={addWidthParameter(parse(image?.url), 480)} alt={parse(image?.altText)} />
                  ))}
                </Carousel>
              ) : (
                <img
                  src={addWidthParameter(parse(first(category?.content?.general?.gallery)?.url), 480)}
                  className="tickets-extras__image"
                  alt={parse(first(category?.content?.general?.gallery)?.altText)}
                />
              )}
            </div>
          </>
        ) : (
          <>
            {venue?.content?.general?.title && (venue?.content?.general?.gallery || venue?.content?.general?.image) && (
              <>
                <h4 className="tickets-extras__heading">
                  {translate(translations, language, "STADIUM")}&nbsp;{venue.content.general.title}
                </h4>
                <div className="tickets-extras__sight">
                  {venue.content?.general?.gallery && venue.content?.general?.gallery.length > 1 ? (
                    <Carousel
                      slidesToShow={1}
                      renderBottomCenterControls={({ currentSlide, goToSlide }) =>
                        getBottomControls(venue?.content?.general?.gallery!, currentSlide, goToSlide)
                      }
                      defaultControlsConfig={{
                        nextButtonClassName: "carousel__control carousel__control--next",
                        nextButtonText: " ",
                        prevButtonClassName: "carousel__control carousel__control--prev",
                        prevButtonText: " ",
                      }}
                    >
                      {venue.content?.general?.gallery.map((image, i) => (
                        <img key={i} src={addWidthParameter(parse(image?.url), 180)} alt={parse(image?.altText)} />
                      ))}
                    </Carousel>
                  ) : (
                    <img
                      className="tickets-extras__image"
                      src={addWidthParameter(
                        venue?.content?.general?.gallery && first(venue.content.general.gallery)?.url
                          ? parse(first(venue?.content?.general?.gallery)?.url)
                          : parse(venue.content.general.image?.url),
                        480
                      )}
                      alt={
                        venue?.content?.general?.gallery && first(venue.content.general.gallery)?.altText
                          ? parse(first(venue?.content?.general?.gallery)?.altText)
                          : parse(venue.content.general.image?.altText)
                      }
                    />
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default TicketsExtras;
