import React, { useState } from "react";
import { usePopperTooltip } from "react-popper-tooltip";
import Icon from "../icon";


interface TicketsUspsProps {
  venue?: string;
}

const TicketsUsps: React.FC<TicketsUspsProps> = ({ venue }) => {
  const [controlledVisible, setControlledVisible] = useState(false);
  const [hovered, setHovered] = useState("");

  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    trigger: null,
    placement: "bottom",
    interactive: true,
    visible: controlledVisible,
    onVisibleChange: setControlledVisible,
  });

  const handleTooltipTrigger = () => {
    setHovered("usp");
    setControlledVisible(true);
  };

  return (
    <div className="tickets-usps">
      <div className="tickets-usps__item">
        <Icon name="seats-together" />
        {process.env.WEBSITE === "Voetbalreizen" ?
          <>Bij elkaar in het stadion</>
          :
          <>Zit bij elkaar in {venue ?? "het stadion"}</>
        }
      </div>
      {process.env.WEBSITE === "Voetbalreizen" && (
        <div className="tickets-usps__item" ref={setTriggerRef} onMouseOver={() => handleTooltipTrigger()}>
          <Icon name="football-guarantee" />
          Voetbalgarant op reis
          <div className={`tooltip-modal tooltip-modal--downwards ${controlledVisible ? "tooltip-modal--active" : ""}`}>
            <div className="tooltip-modal__trigger">
              <Icon name="tooltip-white" />
            </div>
            {visible && hovered == "usp" && (
              <div className="tooltip-modal__frame" ref={setTooltipRef} {...getTooltipProps({ className: "tooltip-modal__frame" })}>
                <div {...getArrowProps({ className: "tooltip-arrow" })} />
                <div className="tooltip-modal__header">
                  <h3 className="tooltip-modal__heading">Voetbalgarant op reis</h3>
                  <div className="tooltip-modal__actions">
                    <button
                      type="button"
                      onClick={() => {
                        setHovered("");
                        setControlledVisible(false);
                      }}
                      className="tooltip-modal__close-button"
                    ></button>
                  </div>
                </div>
                <div className="tooltip-modal__body">
                  <p>
                    <strong>Voetbalgarant regeling van Voetbalreizen.com</strong>
                  </p>
                  <p>
                    Het komt regelmatig voor dat buitenlandse wedstrijden verplaatst worden. Dat kan een vroegere of latere aftrap op dezelfde dag zijn,
                    maar ook verplaatsing naar een andere dag (voorbeeld van zaterdag naar zondag). Vaak hangt dit samen met een live tv-uitzending
                    en/of verplichtingen van de betrokken club(s) in nationale- of Europese bekertoernooien. Met name in de Engelse Premier League komt
                    dit met regelmaat voor.
                  </p>
                  <p>
                    Bij een verplaatsing bestaat de mogelijkheid dat de wedstrijd niet meer binnen uw geboekte reisschema valt. Voetbalreizen.com biedt
                    je in dat geval de volgende opties:
                  </p>
                  <ul>
                    <li>
                      Jouw reis wordt aangepast. De wijziging van de vlucht komt voor rekening van Voetbalreizen.com. Een eventuele extra overnachting
                      (oftewel verlenging van je verblijf) zal voor € 69,- per persoon (extra overnachting conform kamer- en board type) in rekening
                      worden gebracht (Voetbalreizen.com past alleen heen- of terugvlucht aan. Oftewel het is of de heen vlucht, of de terugvlucht).
                    </li>
                    <li>
                      Wij bieden je kosteloos een vervangende wedstrijd aan die past in het oorspronkelijke reisschema (uiteraard in overeenstemming).
                    </li>
                    <li>
                      Wij bieden je de mogelijkheid om een vervangende wedstrijd uit te zoeken. In dat geval wordt 50% van de omboekkosten van je vlucht
                      in rekening gebracht.
                    </li>
                  </ul>
                  <a href="#guarantees" title="Bekijk onze Voetbalgarant op reis-pagina" className="tooltip-modal__read-more-button">
                    Bekijk onze Voetbalgarant op reis-pagina
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TicketsUsps;
