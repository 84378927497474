import { getCountries } from "@qite/tide-client";
import { CountryItem } from "@qite/tide-client/build/types";
import { getTideClientConfig } from "../utils/member-and-tide-utils";

const HOST = process.env.GATSBY_TIDE_HOST;
const API_KEY = process.env.GATSBY_TIDE_API_KEY;

export const searchCountries = async (signal?: AbortSignal): Promise<CountryItem[]> => {
  if (!HOST || !API_KEY) {
    return Promise.reject();
  }

  const tideClientConfig = getTideClientConfig();

  const countries = await getCountries(tideClientConfig, signal);
  return countries.items;
};