import React from "react";
import StadiumSelector from "../../stadium-selector/";
import TicketsExtras from "../../tickets-extras";
import { TideItemForEvenementLocatie, TideItemForExcursion } from "../../../types";

interface BookingStep2Props {
  venue?: TideItemForEvenementLocatie;
  excursions: TideItemForExcursion[];
}

const BookingStep2: React.FC<BookingStep2Props> = ({ venue, excursions }) => {
  return (
    <div className="booking__form-panel">
      <div className="booking__region">
        <div className="booking__card booking__card--transparent">
          <div className="booking__tickets">
            <div className="booking__tickets-configure">
              <StadiumSelector venue={venue} />
              <TicketsExtras venue={venue} excursions={excursions} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingStep2;
