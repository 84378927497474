import { get } from "lodash";
import regimes from '../../static/data/regime-types.json';
import rooms from '../../static/data/room-types.json';

export function mapRegime(key: string) {
    const regime = get(regimes, key, key) as string;

    return regime != "" ? regime : key;
}

export function mapRoom(key: string) {
    const room = get(rooms, key, key) as string;

    return room != "" ? room : key;
}
