import React, { useContext } from "react";
import Icon from "../../icon";
import BookingContext, { BookingContextType } from "../../../contexts/booking-provider";
import { Hotel } from "../../../site-types";

interface MarkerProps {
  hotel: Hotel;
  lat: number;
  lng: number;
  handleMouseOver: (hotelCode: string) => void;
}

const Marker: React.FC<MarkerProps> = ({ hotel, handleMouseOver }) => {
  const { flyInIsOpen, setFlyInIsOpen, setFlyInHotel, bookingPackage, requestRooms } = useContext<BookingContextType>(BookingContext);
  const bookingDetails = bookingPackage?.options.find((o) => o.isSelected);
  const selectedHotel = bookingDetails?.hotels.find((h) => h.isSelected);
  const numberOfPax = requestRooms.flatMap((r) => r.pax).length;

  const handleOnClick = (hotel: Hotel) => {
    setFlyInIsOpen(!flyInIsOpen);
    setFlyInHotel(hotel.name);
  };

  return (
    <div
      className={`map__icon${hotel.productCode == selectedHotel?.productCode ? " map__icon--selected" : ""}`}
      onMouseOver={() => handleMouseOver(hotel.productCode)}
      onClick={() => handleOnClick(hotel)}
    >
      <div className="map__icon-pin">
        <Icon name="hotel-fill" />€ {hotel.cheapestPrice - (selectedHotel ? selectedHotel.cheapestPrice : 0) >= 0 ? "+" : ""}
        {((hotel.cheapestPrice - (selectedHotel ? selectedHotel.cheapestPrice : 0)) / numberOfPax).toFixed(0)}
      </div>
    </div>
  );
};

export default Marker;
