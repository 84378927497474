import React, { useContext, useState } from "react";
import BookingContext, { BookingContextType } from "../../../contexts/booking-provider";
import GoogleMapReact, { fitBounds } from "google-map-react";
import { first, max, min } from "lodash";
import { TideItemForEvenementLocatie, TideItemForFacility } from "../../../types";
import AccommodationCard from "../accommodations-cards/card";
import Marker from "../../markers/marker";
import VenueMarker from "../../markers/venue-marker";

export const API_KEY = "AIzaSyD9FhsgpnYloyOo0jLPfHmMtlu2W8ZY114";

interface AccommodationsMapProps {
  facilities: TideItemForFacility[];
  venue?: TideItemForEvenementLocatie;
}

const AccommodationsMap: React.FC<AccommodationsMapProps> = ({ facilities, venue }) => {
  const { hotels } = useContext<BookingContextType>(BookingContext);

  const selectedHotel = hotels.find((h) => h.isSelected);
  const [hoveredHotelCode, setHoveredHotelCode] = useState(selectedHotel?.productCode);

  let center, zoom;
  const latitudes = hotels.map((h) => h.latitude);
  const longitudes = hotels.map((h) => h.longitude);
  if (venue?.content?.address?.location?.latitude && venue?.content?.address?.location?.longitude) {
    latitudes?.push(venue.content.address.location.latitude);
    longitudes?.push(venue.content.address.location.longitude);
  }
  if ((latitudes && latitudes.length === 1) || (longitudes && longitudes.length === 1)) {
    const singleHotel = first(hotels);
    center = {
      lat: singleHotel?.latitude ?? 0,
      lng: singleHotel?.longitude ?? 0,
    };
    zoom = 12;
  } else {
    const ne = {
      lat: max(latitudes) ?? 0,
      lng: max(longitudes) ?? 0,
    };
    const sw = {
      lat: min(latitudes) ?? 0,
      lng: min(longitudes) ?? 0,
    };

    ({ center, zoom } = fitBounds({ ne, sw }, { width: 300, height: 300 }));
  }

  const createMapOptions = () => {
    return {
      panControl: true,
      gestureHandling: "greedy",
      draggableCursor: "default",
      streetViewControl: true,
      mapTypeControl: true,
      fullscreenControl: false,
      clickableIcons: false,
    };
  };

  const handleMouseOver = (hotelCode: string) => {
    setHoveredHotelCode(hotelCode);
  };

  return (
    <div className="accommodations__map">
      {/* Important! Always set the container height explicitly */}
      <div className="map">
        <div className="map__googlemaps">
          <GoogleMapReact
            bootstrapURLKeys={{ key: API_KEY }}
            defaultCenter={center}
            defaultZoom={zoom}
            options={createMapOptions}
            yesIWantToUseGoogleMapApiInternals={true}
          >
            {hotels.map((h, i) => (
              <Marker key={i} lat={h.latitude} lng={h.longitude} hotel={h} handleMouseOver={handleMouseOver} />
            ))}
            {venue?.content?.address?.location?.latitude && venue?.content?.address?.location?.longitude && (
              <VenueMarker lat={venue?.content?.address?.location?.latitude} lng={venue?.content?.address?.location?.longitude} />
            )}
          </GoogleMapReact>
        </div>
        {hoveredHotelCode && hoveredHotelCode != "" && (
          <AccommodationCard hotel={hotels.find((x) => x.productCode == hoveredHotelCode)} facilities={facilities} venue={venue} />
        )}
      </div>
    </div>
  );
};

export default AccommodationsMap;
