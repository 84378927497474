import React, { useContext, useEffect, useRef } from "react";
import GlobalContext, { GlobalContextType } from "../../../contexts/global-provider";
import translate from "../../../utils/translate";
import translations from "../translations.json";
import BookingContext, { BookingContextType } from "../../../contexts/booking-provider";
import Icon from "../../icon";
import { first, uniqBy } from "lodash";
import Link from "../../link";
import { differenceInCalendarDays, format, parse } from "date-fns";
import { nlBE } from "date-fns/locale";
import { mapRegime, mapRoom } from "../../../utils/room-regime-mapper";

interface BookingStep6Props {
  policyChecks: PolicyChecks;
  handleSetPolicyCheckboxes: (selectedCheckbox: any) => void;
  policyChecksValid: boolean;
}

export interface PolicyChecks {
  identity: boolean;
  readAndAgree: boolean;
}

const BookingStep6: React.FC<BookingStep6Props> = ({ policyChecks, handleSetPolicyCheckboxes, policyChecksValid }) => {
  const bookingSummaryErrors = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (!policyChecksValid && bookingSummaryErrors.current) {
      bookingSummaryErrors.current.scrollIntoView();
    }
  }, [policyChecksValid]);

  const { language } = useContext<GlobalContextType>(GlobalContext);
  const { bookingPackage, bookingAddress, stayAtHome, mainBookerData, mainBookerIsTraveller } = useContext<BookingContextType>(BookingContext);

  const bookingDetails = bookingPackage?.options.find((o) => o.isSelected);
  const travellers = bookingDetails?.requestRooms.flatMap((rr) => rr.pax);

  const selectedTicket = first(bookingDetails?.rooms)?.options.find((o) => o.isSelected);

  const hotelRooms = bookingDetails?.optionUnits.flatMap((ou) => ou.groups.flatMap((g) => g.options.filter((o) => o.isSelected && o.isHotelPool)));
  const firstRoom = first(hotelRooms);

  const selectedOutwardFlight = bookingPackage?.outwardFlights?.find((f) => f.isSelected);
  const selectedReturnFlight = bookingPackage?.returnFlights?.find((f) => f.isSelected);

  const subProducts = uniqBy(
    bookingDetails?.groups.filter((g) => g.name !== "CACHE TRESHOLD").flatMap((g) => g.options),
    "line.productName"
  ).filter((sp) => sp.isSelected);

  const changeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleSetPolicyCheckboxes(event.target.name);
  };

  const getDifferenceInDays = (fromDate: string, toDate: string) => {
    var Difference_In_Time = new Date(toDate).getTime() - new Date(fromDate).getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    return Math.ceil(Difference_In_Days);
  };

  const parseTime = (time: string) => {
    if (time && (time.includes("AM") || time.includes("PM"))) {
      return format(parse(time, "hh:mm a", new Date()), "HH:mm");
    }

    return time;
  };

  return (
    <div className="booking__form-panel">
      <div className="booking__region">
        {travellers && (
          <div className="booking__card booking__card--transparent">
            <div className="booking__intro">
              <div className="heading-seperator">
                <h1 className="booking__region-heading">{translate(translations, language, "OVERVIEW")}</h1>
              </div>
              <div className="booking__intro-text">
                <p>{translate(translations, language, "CHECK_DATA_BEFORE_PAYMENT")}:</p>
              </div>
            </div>

            <div className="booking-summary">
              <div className="booking-summary__header">
                <h2 className="booking-summary__heading">{translate(translations, language, "TRAVEL_GROUP")}</h2>
                <span className="booking-summary__amount-travelers">
                  <span className="text-lowercase">
                    {travellers.filter((t) => t.age! > 12).length} {translate(translations, language, "ADULTS")}
                    {travellers.filter((t) => t.age! <= 12).length > 0 && (
                      <>
                        &nbsp;&nbsp;&nbsp;
                        {travellers.filter((t) => t.age! <= 12).length} {translate(translations, language, "CHILDREN")}
                      </>
                    )}
                  </span>
                </span>
              </div>
              <div className="booking-summary__body">
                <div className="booking-summary__region">
                  <div className="booking-summary__row">
                    {travellers &&
                      travellers.map((traveller, i) => (
                        <div key={i} className="booking-summary__column booking-summary__column--background">
                          <div className="booking-summary__column-header">
                            {traveller.isMainBooker && mainBookerIsTraveller == "1" && (
                              <div className="inclusive-label">
                                {translate(translations, language, "MAINBOOKER")}
                                <Icon name="check-circle" />
                              </div>
                            )}
                            <div className="booking-summary__type-header">
                              <div className="booking-summary__type-icon">
                                <Icon name="user"></Icon>
                                <div className="text-inline">
                                  <h5 className="booking-summary__body-heading">{translate(translations, language, "TRAVELLER") + " " + (i + 1)}</h5>
                                  &nbsp;
                                  <em>
                                    {traveller.age! > 12 ? translate(translations, language, "ADULT") : translate(translations, language, "CHILD")}
                                  </em>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="booking-summary__column-body">
                            <p>
                              <strong>
                                {traveller.firstName}&nbsp;{traveller.lastName}
                              </strong>
                              :&nbsp;
                              {traveller.dateOfBirth ? format(new Date(traveller.dateOfBirth), "dd/MM/yyyy") : ""}
                              {mainBookerIsTraveller == "1" && traveller.isMainBooker && bookingAddress && (
                                <>
                                  <br />
                                  {bookingAddress.street}&nbsp;{bookingAddress.number}
                                  {bookingAddress.box},&nbsp;{bookingAddress.location},&nbsp;{bookingAddress.country}
                                  {traveller.phone && (
                                    <>
                                      <br />
                                      {traveller.phone}
                                    </>
                                  )}
                                  {traveller.mobilePhone && (
                                    <>
                                      <br />
                                      {traveller.mobilePhone}
                                    </>
                                  )}
                                  {traveller.email && (
                                    <>
                                      <br />
                                      {traveller.email}
                                    </>
                                  )}
                                </>
                              )}
                              <br />
                            </p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>

            {mainBookerData && mainBookerIsTraveller == "2" && (
              <div className="booking-summary">
                <div className="booking-summary__header">
                  <h2 className="booking-summary__heading">{translate(translations, language, "MAINBOOKER")}</h2>
                </div>
                <div className="booking-summary__body">
                  <div className="booking-summary__region">
                    <div className="booking-summary__row">
                      <div className="booking-summary__column">
                        <div className="booking-summary__column-header">
                          <div className="booking-summary__type-header">
                            <div className="booking-summary__type-icon">
                              <Icon name="user"></Icon>
                              <div className="text-inline">
                                <h5 className="booking-summary__body-heading">{translate(translations, language, "MAINBOOKER")}</h5>
                                &nbsp;
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="booking-summary__column-body">
                          <p>
                            <strong>
                              {mainBookerData.firstName}&nbsp;{mainBookerData.lastName}
                            </strong>
                            :&nbsp;
                            {mainBookerData.dateOfBirth ? format(new Date(mainBookerData.dateOfBirth), "dd/MM/yyyy") : ""}
                            {mainBookerData.isMainBooker && bookingAddress && (
                              <>
                                <br />
                                {bookingAddress.street}&nbsp;{bookingAddress.number}
                                {bookingAddress.box},&nbsp;{bookingAddress.location},&nbsp;{bookingAddress.country}
                              </>
                            )}
                            {mainBookerData.phone && (
                              <>
                                <br />
                                {mainBookerData.phone}
                              </>
                            )}
                            {mainBookerData.mobilePhone && (
                              <>
                                <br />
                                {mainBookerData.mobilePhone}
                              </>
                            )}
                            {mainBookerData.email && (
                              <>
                                <br />
                                {mainBookerData.email}
                              </>
                            )}
                            <br />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {stayAtHome && stayAtHome.firstName && (
              <div className="booking-summary">
                <div className="booking-summary__header">
                  <h2 className="booking-summary__heading">{translate(translations, language, "STAY_AT_HOME")}</h2>
                </div>
                <div className="booking-summary__body">
                  <div className="booking-summary__region">
                    <div className="booking-summary__row">
                      <div className="booking-summary__column">
                        <div className="booking-summary__column-header">
                          {stayAtHome.isMainBooker && (
                            <div className="inclusive-label">
                              {translate(translations, language, "MAINBOOKER")}
                              <Icon name="check-circle" />
                            </div>
                          )}
                          <div className="booking-summary__type-header">
                            <div className="booking-summary__type-icon">
                              <Icon name="user"></Icon>
                              <div className="text-inline">
                                <h5 className="booking-summary__body-heading">{translate(translations, language, "STAY_AT_HOME")}</h5>
                                &nbsp;
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="booking-summary__column-body">
                          <p>
                            <strong>
                              {stayAtHome.firstName}&nbsp;{stayAtHome.lastName}
                            </strong>
                            :&nbsp;
                            {stayAtHome.dateOfBirth ? mainBookerData : ""}
                            {stayAtHome.isMainBooker && bookingAddress && (
                              <>
                                <br />
                                {bookingAddress.street}&nbsp;{bookingAddress.number}
                                {bookingAddress.box},&nbsp;{bookingAddress.location},&nbsp;{bookingAddress.country}
                              </>
                            )}
                            {stayAtHome.phone && (
                              <>
                                <br />
                                {stayAtHome.phone}
                              </>
                            )}
                            {stayAtHome.mobilePhone && (
                              <>
                                <br />
                                {stayAtHome.mobilePhone}
                              </>
                            )}
                            {stayAtHome.email && (
                              <>
                                <br />
                                {stayAtHome.email}
                              </>
                            )}
                            <br />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="booking-summary">
              <div className="booking-summary__header">
                <h2 className="booking-summary__heading">{translate(translations, language, "OVERVIEW")}</h2>
                {bookingDetails?.fromDate && bookingDetails?.toDate && (
                  <div className="booking-summary__amount-travelers">
                    <span>
                      {`${
                        differenceInCalendarDays(
                          new Date(bookingDetails.toDate.replace("Z", "")),
                          new Date(bookingDetails.fromDate.replace("Z", ""))
                        ) + 1
                      }d ${differenceInCalendarDays(
                        new Date(bookingDetails.toDate.replace("Z", "")),
                        new Date(bookingDetails.fromDate.replace("Z", ""))
                      )}n`}
                    </span>

                    <div className="arrow-list">
                      <ul>
                        <li>{format(new Date(bookingDetails.fromDate), "dd/MM/yyyy")}</li>
                        <li>{format(new Date(bookingDetails.toDate), "dd/MM/yyyy")}</li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
              <div className="booking-summary__body">
                <div className="booking-summary__region">
                  <div className="booking-summary__row">
                    <div className="booking-summary__column">
                      <div className="booking-summary__product-groups">
                        {bookingDetails && (
                          <div className="booking-summary__product-group">
                            <div className="booking-summary__column-header">
                              <div className="booking-summary__type-header">
                                <div className="booking-summary__type-icon">
                                  <Icon name="ticket"></Icon>
                                </div>
                                <h5 className="booking-summary__type-heading">
                                  {translate(translations, language, "TICKETS")} &amp; {translate(translations, language, "EXTRAS")}:
                                </h5>
                              </div>
                            </div>
                            <div className="booking-summary__column-body">
                              <strong>{bookingDetails?.allotmentName}</strong>
                              <p>
                                {format(new Date(selectedTicket?.from ?? bookingDetails.fromDate), "EEEEEE d MMM yyyy", { locale: nlBE })}
                                &nbsp;
                                {parseTime(bookingDetails.startTime)}: {selectedTicket?.accommodationName}
                                {/* 3e ring korte zijde */}
                              </p>
                              {subProducts && subProducts.length > 0 && (
                                <>
                                  <strong>{translate(translations, language, "EXTRAS")}:</strong>
                                  {subProducts.map((extra, i) => (
                                    <p key={i}>
                                      {new Date(extra.line.startDate).toLocaleString(language ? language : "nl-BE", {
                                        day: "numeric",
                                        month: "numeric",
                                        year: "numeric",
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        hour12: false,
                                      })}
                                      : {extra.line.productName}
                                    </p>
                                  ))}
                                </>
                              )}
                            </div>
                          </div>
                        )}

                        {firstRoom && (
                          <div className="booking-summary__product-group">
                            <div className="booking-summary__column-header">
                              <div className="booking-summary__type-header">
                                <div className="booking-summary__type-icon">
                                  <Icon name="bed-booking"></Icon>
                                </div>
                                <h5 className="booking-summary__type-heading">{translate(translations, language, "STAY")}:</h5>
                              </div>
                            </div>
                            <div className="booking-summary__column-body">
                              <strong>
                                {firstRoom.line.productName}, {format(new Date(firstRoom.line.startDate), "dd/MM/yyyy")}
                                &nbsp;&gt;&nbsp;
                                {format(new Date(firstRoom.line.endDate), "dd/MM/yyyy")}
                              </strong>
                              <div className="list-summary list-summary--markers">
                                <ul>
                                  {hotelRooms &&
                                    hotelRooms.map((room, i) => (
                                      <li key={i}>
                                        {mapRoom(room.line.accommodationName)}, {mapRegime(room.line.regimeName)}
                                      </li>
                                    ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        )}

                        {selectedOutwardFlight && selectedReturnFlight && (
                          <div className="booking-summary__product-group">
                            <div className="booking-summary__column-header">
                              <div className="booking-summary__type-header">
                                <div className="booking-summary__type-icon">
                                  <Icon name="markers-ab"></Icon>
                                </div>
                                <h5 className="booking-summary__type-heading">
                                  {translate(translations, language, "TRANSPORT")}: {translate(translations, language, "FLIGHT")}
                                </h5>
                              </div>
                            </div>
                            <div className="booking-summary__column-body">
                              <div className="booking-summary__transport-times">
                                <div className="booking-summary__transport-time">
                                  <strong>
                                    {translate(translations, language, "OUTWARD")}, &nbsp;
                                    {format(new Date(selectedOutwardFlight.startDateTime), "dd/MM/yyyy")}:
                                  </strong>
                                  {selectedOutwardFlight.flightMetaData.flightLines.map((flightLine, i) => (
                                    <ul key={i}>
                                      <li>
                                        {flightLine.departureAirportDescription}&nbsp;&gt;&nbsp;{flightLine.arrivalAirportDescription}
                                      </li>
                                      <li>{selectedOutwardFlight.code}</li>
                                      <li>
                                        {flightLine.departureTime}&nbsp;&gt;&nbsp;{flightLine.arrivalTime}
                                      </li>
                                    </ul>
                                  ))}
                                </div>
                                <div className="booking-summary__transport-time">
                                  <strong>
                                    {translate(translations, language, "RETURN")},&nbsp;
                                    {format(new Date(selectedReturnFlight.startDateTime), "dd/MM/yyyy")}:
                                  </strong>
                                  {selectedReturnFlight.flightMetaData.flightLines.map((flightLine, i) => (
                                    <ul key={i}>
                                      <li>
                                        {flightLine.departureAirportDescription}&nbsp;&gt;&nbsp;{flightLine.arrivalAirportDescription}
                                      </li>
                                      <li>{selectedReturnFlight.code}</li>
                                      <li>
                                        {flightLine.departureTime}&nbsp;&gt;&nbsp;{flightLine.arrivalTime}
                                      </li>
                                    </ul>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="booking-summary__column">
                      <div className="booking-summary__product-groups">
                        <div className="booking-summary__product-group">
                          <div className="booking-summary__column-header">
                            <div className="check-circle-list">
                              <h5 className="check-circle-list__heading">{translate(translations, language, "INCLUSIVE")}</h5>
                              <ul>
                                <li>{translate(translations, language, "INCLUSIVE1")}</li>
                                <li>{translate(translations, language, "INCLUSIVE2")}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="booking-summary__product-group">
                          <div className="booking-summary__column-header">
                            <div className="cross-circle-list">
                              <h5 className="cross-circle-list__heading">{translate(translations, language, "EXCLUSIVE")}</h5>
                              <ul>
                                <li>{translate(translations, language, "EXCLUSIVE1")}</li>
                                <li>{translate(translations, language, "EXCLUSIVE2")}</li>
                                <li>{translate(translations, language, "EXCLUSIVE3")}</li>
                                <li>{translate(translations, language, "EXCLUSIVE4")}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="booking-summary booking-summary--transparent">
            <div className="booking-summary__header">
              <h2 className="booking-summary__heading">{translate(translations, language, "CHECK_YOUR_DATA")}</h2>
            </div>
            <div className="booking-summary__body">
              <div className="booking-summary__region">
                <div className="booking-summary__row">
                  <div className="booking-summary__column booking-summary__column--transparent">
                    <p>
                      <div class="inline-icon">
                        <Icon name="giftbox"/>
                      </div>
                      Heb je een kortingscode? Die kan je <a href="#addDiscount" title="Kortingscode toevoegen">hier toevoegen</a>.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

            <div className="booking-summary booking-summary--transparent">
              <div className="booking-summary__header">
                <h2 className="booking-summary__heading">{translate(translations, language, "CHECK_YOUR_DATA")}</h2>
              </div>
              <div className="booking-summary__body">
                <div className="booking-summary__region">
                  <div className="booking-summary__row">
                    <div className="booking-summary__column booking-summary__column--transparent">
                      <p>
                        <strong>{translate(translations, language, "CONFIRM_BOOKING")}.</strong>
                        <br />
                        {translate(translations, language, "CONFIRM_BOOKING_DEFINITIVE")}.
                      </p>
                      <div className="booking__confirmation-sliders">
                        <label className={`slider ${!policyChecksValid && !policyChecks.identity ? "slider__error" : ""}`}>
                          <input
                            type="checkbox"
                            className="slider__input"
                            id="identity"
                            name="identity"
                            checked={policyChecks.identity}
                            onChange={(event) => changeCheckBox(event)}
                            required
                          />
                          <span className="slider__text">{translate(translations, language, "IDENTITY")}.</span>
                        </label>
                        <label className={`slider ${!policyChecksValid && !policyChecks.readAndAgree ? "slider__error" : ""}`}>
                          <input
                            type="checkbox"
                            className="slider__input"
                            id="read_and_agree"
                            name="read_and_agree"
                            checked={policyChecks.readAndAgree}
                            onChange={(event) => changeCheckBox(event)}
                            required
                          />
                          <span className="slider__text">
                            {translate(translations, language, "DECLARE")}
                            &nbsp;
                            <Link path="/voorwaarden" title={translate(translations, language, "TRAVEL_CONDITIONS")} targetBlank={true}>
                              {translate(translations, language, "TRAVEL_CONDITIONS")}
                            </Link>
                            &nbsp;
                            {translate(translations, language, "READ_AND_AGREE")}.
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  {!policyChecksValid && (
                    <div className="booking-summary__row" ref={bookingSummaryErrors}>
                      <div className="booking-summary__column booking-summary__column--transparent">
                        <div className="form__errors">{translate(translations, language, "ERROR_POLICY_CHECKS")}.</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BookingStep6;
