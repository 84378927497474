import React, { useContext } from "react";
import GlobalContext, { GlobalContextType } from "../../contexts/global-provider";
import translate from "../../utils/translate";
import translations from "./translations.json";
import Icon from "../icon";
interface StepIndicatorsProps {
  step: number;
}

const StepIndicators: React.FC<StepIndicatorsProps> = ({ step }) => {
  const { language } = useContext<GlobalContextType>(GlobalContext);

  return (
    <>
      <div className="step-indicators">
        <div
          className={`step-indicators__item ${step == 1 ? "step-indicators__item--active" : ""} ${
            step > 1 ? "step-indicators__item--completed" : ""
          }`}
        >
          <span className="step-indicators__icon">
            <Icon name="luggage" />
          </span>
          <span className="step-indicators__label">1. {translate(translations, language ? language : "nl-BE", "JOURNEY")}</span>
        </div>
        <div
          className={`step-indicators__item ${step == 2 ? "step-indicators__item--active" : ""} ${
            step > 2 ? "step-indicators__item--completed" : ""
          }`}
        >
          <span className="step-indicators__icon">
            <Icon name="ticket" />
          </span>
          <span className="step-indicators__label">2. {translate(translations, language ? language : "nl-BE", "TICKETS")}</span>
        </div>
        <div
          className={`step-indicators__item ${step == 3 ? "step-indicators__item--active" : ""} ${
            step > 3 ? "step-indicators__item--completed" : ""
          }`}
        >
          <span className="step-indicators__icon">
            <Icon name="markers-ab" />
          </span>
          <span className="step-indicators__label">3. {translate(translations, language ? language : "nl-BE", "TRANSPORT")}</span>
        </div>
        <div
          className={`step-indicators__item ${step == 4 ? "step-indicators__item--active" : ""} ${
            step > 4 ? "step-indicators__item--completed" : ""
          }`}
        >
          <span className="step-indicators__icon">
            <Icon name="bed-booking" />
          </span>
          <span className="step-indicators__label">4. {translate(translations, language ? language : "nl-BE", "STAY")}</span>
        </div>
        <div
          className={`step-indicators__item ${step == 5 ? "step-indicators__item--active" : ""} ${
            step > 5 ? "step-indicators__item--completed" : ""
          }`}
        >
          <span className="step-indicators__icon">
            <Icon name="user" />
          </span>
          <span className="step-indicators__label">5. {translate(translations, language ? language : "nl-BE", "INFORMATION")}</span>
        </div>
        <div
          className={`step-indicators__item ${step == 6 ? "step-indicators__item--active" : ""} ${
            step > 6 ? "step-indicators__item--completed" : ""
          }`}
        >
          <span className="step-indicators__icon">
            <Icon name="euro-coins" />
          </span>
          <span className="step-indicators__label">6. {translate(translations, language ? language : "nl-BE", "OVERVIEW")}</span>
        </div>
        <div
          className={`step-indicators__item ${step == 7 ? "step-indicators__item--active" : ""} ${
            step > 7 ? "step-indicators__item--completed" : ""
          }`}
        >
          <span className="step-indicators__icon">
            <Icon name="goal" />
          </span>
          <span className="step-indicators__label">7. {translate(translations, language ? language : "nl-BE", "CONFIRMATION")}</span>
        </div>
      </div>
    </>
  );
};

export default StepIndicators;
