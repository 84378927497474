import React from "react";
import Icon from "../../icon";

interface VenueMarkerProps {
  lat: number;
  lng: number;
}

const VenueMarker: React.FC<VenueMarkerProps> = ({}) => {
  return (
    <div className="map__icon">
      <div className="map__icon-pin">
        <Icon name="stadium" />
      </div>
    </div>
  );
};

export default VenueMarker;
