import React, { useContext } from "react";
import GlobalContext, { GlobalContextType } from "../../../contexts/global-provider";
import translate from "../../../utils/translate";
import translations from "../translations.json";
import Flights from "../../flights";
import BookingContext, { BookingContextType } from "../../../contexts/booking-provider";
import { ServiceType } from "@qite/tide-client";
import { isEmpty } from "lodash";
import Icon from "../../icon";

interface BookingStep3Props {}

const BookingStep3: React.FC<BookingStep3Props> = () => {
  const { language } = useContext<GlobalContextType>(GlobalContext);
  const { bookingSearch, flightsLoaded, cachedFlightPool, shouldCheckTickets, initialServiceTypes } = useContext<BookingContextType>(BookingContext);

  return (
    <div className="booking__form-panel">
      <div className="booking__region">
        <div className="booking__card booking__card--transparent">
          {!shouldCheckTickets && (
            <>
              {flightsLoaded && !isEmpty(cachedFlightPool?.outwardFlights) && (
                <div className="booking__intro">
                  <div className="heading-seperator">
                    <h1 className="booking__region-heading">{translate(translations, language, "SHOW_FLIGHTS_FROM")}:</h1>
                  </div>
                </div>
              )}
              {initialServiceTypes?.includes(ServiceType.flight) ? (
                flightsLoaded && isEmpty(cachedFlightPool?.outwardFlights) ? (
                  <div className="booking__intro">
                    <div className="heading-seperator">
                      <h4 className="booking__region-heading">
                        Helaas, er zijn <strong>geen beschikbare vluchten gevonden</strong> voor deze bestemming. Je kan dit pakket ook verder boeken
                        zonder vlucht.
                      </h4>
                    </div>
                  </div>
                ) : (
                  <Flights />
                )
              ) : (
                <div className="booking__intro">
                  <div className="heading-seperator">
                    <h1 className="booking__region-heading">Eigen vervoer</h1>
                  </div>
                </div>
              )}
            </>
          )}
          {shouldCheckTickets && (
            <div className="preloader-spinner preloader-spinner--vertical-placement">
              <div className="preloader__icon">
                <Icon name="spinner" />
              </div>
              <div className="preloader__label">
                We controleren nog even de beschikbaarheid van de tickets
                <div className="preloader__dots">
                  <div className="preloader__dot-1">.</div>
                  <div className="preloader__dot-2">.</div>
                  <div className="preloader__dot-3">.</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BookingStep3;
