import { first, isEmpty, last, sumBy, uniqBy } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { TideItemForToernooi, TideItemForWedstrijd } from "../../../types";
import { parse } from "../../../utils/component";
import Icon from "../../icon";
import { differenceInCalendarDays, format, parse as parseDate } from "date-fns";
import translate from "../../../utils/translate";
import translations from "../translations.json";
import GlobalContext, { GlobalContextType } from "../../../contexts/global-provider";
import BookingContext, { BookingContextType } from "../../../contexts/booking-provider";
import { ServiceType } from "@qite/tide-client";
import { Image } from "../../../types";
import { mapRegime, mapRoom } from "../../../utils/room-regime-mapper";
import Link from "../../link";
import { getDateOnlyString } from "../../../utils/date-utils";
import { addWidthParameter } from "../../../utils/image-utils";
import { hotelReduction } from "../../../utils/package-utils";
import { Flight } from "../../../site-types";

interface SummaryPanelProps {
  event?: TideItemForWedstrijd;
  tournament?: TideItemForToernooi;
  activeStep: number;
  homeTeamImage?: Image;
  awayTeamImage?: Image;
}

const SummaryPanel: React.FC<SummaryPanelProps> = ({ event, tournament, activeStep, homeTeamImage, awayTeamImage }) => {
  const { language } = useContext<GlobalContextType>(GlobalContext);
  const {
    bookingSearch,
    bookingPackage,
    bookingPriceDetail,
    priceRecalculating,
    noPackageFound,
    requestRooms,
    hotelsLoaded,
    selectedTicket,
    cheapestTicket,
    selectedOutwardFlight,
    selectedReturnFlight,
    cheapestFlight,
    showCachedPrices,
    roomDistributionErrors,
    packageView,
    initialServiceTypes,
    hotels,
  } = useContext<BookingContextType>(BookingContext);
  const [summaryOpen, setSummaryOpen] = useState<boolean>(false);
  const [headerClassName, setHeaderClassName] = useState<string>("summary-panel__header");
  const [flightPriceDifference, setFlightPriceDifference] = useState<number>(0);
  const [showTravelGroupDetails, setShowTravelGroupDetails] = useState(false);
  const [showTicketDetails, setShowTicketDetails] = useState(false);
  const [showTransportDetails, setShowTransportDetails] = useState(false);
  const [showHotelDetails, setShowHotelDetails] = useState(false);

  const numberOfPax = requestRooms.flatMap((r) => r.pax).length;
  const numberOfAdults = requestRooms.flatMap((r) => r.pax.filter((p) => p.age! > 12)).length;
  const numberOfChildren = requestRooms.flatMap((r) => r.pax.filter((p) => p.age! <= 12)).length;
  const bookingDetails = bookingPackage?.options.find((o) => o.isSelected);

  const selectedHotel = hotels ? hotels.find((h) => h.isSelected) : undefined;

  const cheapestHotel =
    hotels && !isEmpty(hotels)
      ? hotels.reduce(function (prev, curr) {
          return prev.cheapestPrice < curr.cheapestPrice ? prev : curr;
        })
      : undefined;

  const showSpinner = priceRecalculating != "" || (activeStep >= 4 && (!bookingPriceDetail || showCachedPrices));

  const hotelRooms = selectedHotel?.rooms.filter((r) => r.isSelected);

  const extraCosts = bookingDetails?.groups.flatMap((g) => g.options.filter((o) => o.line.productName == "File cost"));

  const subProducts = uniqBy(
    bookingDetails?.groups.filter((g) => g.name !== "CACHE TRESHOLD").flatMap((g) => g.options),
    "line.productName"
  ).filter((sp) => sp.isSelected);

  const getPriceDetail = (productCode: string, accommodationCode: string) => {
    return bookingPriceDetail?.details.find((pd) => pd.productCode == productCode && pd.accommodationCode == accommodationCode);
  };

  const showFlightsPrice = (outwardFlight: Flight, returnFlight: Flight) => {
    const outwardPriceDetail = getPriceDetail(outwardFlight.code, outwardFlight.accommodationCode);
    const returnPriceDetail = getPriceDetail(returnFlight.code, returnFlight.accommodationCode);
    if (outwardPriceDetail?.showPrice && returnPriceDetail?.showPrice) {
      return true;
    }
    return false;
  };

  const getFlightsPrice = (outwardFlight: Flight, returnFlight: Flight) => {
    const outwardPriceDetails = bookingPriceDetail?.details.filter(
      (pd) => pd.productCode == outwardFlight.code && pd.accommodationCode == outwardFlight.accommodationCode
    );
    const returnPriceDetails = bookingPriceDetail?.details.filter(
      (pd) => pd.productCode == returnFlight.code && pd.accommodationCode == returnFlight.accommodationCode
    );

    return (sumBy(outwardPriceDetails, "total") + sumBy(returnPriceDetails, "total")).toFixed(2);
  };

  const getPrice = (productCode: string) => {
    var lines = bookingPriceDetail?.details.filter((pd) => pd.productCode == productCode);
    var price = 0;
    if (lines) {
      lines.forEach((line) => {
        price += line.total;
      });
    }
    return price;
  };

  const getHotelPrice = (hotelCode: string) => {
    var lines = bookingPriceDetail?.details.filter((pd) => pd.productCode == hotelCode || pd.productCode == hotelReduction);
    var price = 0;
    if (lines) {
      lines.forEach((line) => {
        price += line.total;
      });
    }
    return price;
  };

  const getCachedPrice = () => {
    if (!selectedTicket) return undefined;
    if (activeStep >= 4) return "...";
    let difference = 0;
    if (selectedTicket && cheapestTicket) {
      difference = selectedTicket.price - cheapestTicket.price;
    }
    let flightPriceDifference = 0;
    if (selectedOutwardFlight && selectedReturnFlight && cheapestFlight) {
      flightPriceDifference =
        selectedOutwardFlight.price + selectedReturnFlight.price - (cheapestFlight.outwardFlight.price + cheapestFlight.returnFlight.price);
    }
    return (bookingSearch && bookingSearch.price ? bookingSearch.price + difference + flightPriceDifference : 0).toFixed(2);
  };

  const getCachedDeposit = () => {
    let difference = 0;
    if (selectedTicket && cheapestTicket) {
      difference = (selectedTicket.price - cheapestTicket.price) / 2;
    }
    let flightPriceDifference = 0;
    if (selectedOutwardFlight && selectedReturnFlight && cheapestFlight) {
      flightPriceDifference =
        (selectedOutwardFlight.price + selectedReturnFlight.price - (cheapestFlight.outwardFlight.price + cheapestFlight.returnFlight.price)) / 2;
    }
    return (bookingSearch && bookingSearch.deposit ? bookingSearch.deposit + difference + flightPriceDifference : 0).toFixed(2);
  };

  useEffect(() => {
    if (homeTeamImage && awayTeamImage) {
      setHeaderClassName("summary-panel__header summary-panel__header--clubs");
    }
    if ((!homeTeamImage || !awayTeamImage) && !tournament?.content?.general?.logo?.url && !event?.content?.general?.thumbnail?.url) {
      setHeaderClassName("summary-panel__header summary-panel__header--no-image");
    }
  }, [homeTeamImage, awayTeamImage, tournament, event]);

  const parseTime = (time: string) => {
    if (time.includes("AM") || time.includes("PM")) {
      return format(parseDate(time, "hh:mm a", new Date()), "HH:mm");
    }

    return time;
  };

  useEffect(() => {
    setFlightPriceDifference(calcFLightPriceDifference());
  }, [selectedOutwardFlight, selectedReturnFlight, cheapestFlight]);

  const calcFLightPriceDifference = () => {
    if (!selectedOutwardFlight || !selectedReturnFlight || !cheapestFlight?.outwardFlight || !cheapestFlight?.returnFlight) return 0;
    return selectedOutwardFlight.price + selectedReturnFlight.price - (cheapestFlight.outwardFlight.price + cheapestFlight.returnFlight.price);
  };

  // When switching step open panel of that step.
  useEffect(() => {
    setShowTravelGroupDetails(activeStep === 1);
    setShowTicketDetails(activeStep === 2);
    setShowTransportDetails(activeStep === 3);
    setShowHotelDetails(activeStep === 4);
  }, [activeStep]);

  const disclaimer = () => {
    return translate(translations, language ? language : "nl-BE", "DEPOSIT_DISCLAIMER", {
      amount:
        !showCachedPrices && bookingPriceDetail && bookingPriceDetail.deposit
          ? (sumBy(bookingPriceDetail.details, "total") - bookingPriceDetail.deposit).toFixed(2)
          : getCachedDeposit(),
    });
  };

  return (
    <>
      <div className={`booking__summary-panel ${summaryOpen ? "booking__summary-panel--active" : ""}`}>
        {(bookingDetails || bookingSearch) && (
          <div className={`summary-panel summary-panel--booking`}>
            <div className={headerClassName}>
              {(event?.content?.general?.thumbnail?.url || (homeTeamImage && awayTeamImage)) && (
                <div className="summary-panel__header-image">
                  {homeTeamImage && awayTeamImage ? (
                    <>
                      <div className="badge">
                        <img src={addWidthParameter(parse(homeTeamImage.url), 45)} className="badge__image" alt={parse(homeTeamImage.altText)} />
                      </div>
                      <div className="badge">
                        <img src={addWidthParameter(parse(awayTeamImage.url), 45)} className="badge__image" alt={parse(awayTeamImage.altText)} />
                      </div>
                    </>
                  ) : (
                    <img
                      src={addWidthParameter(
                        parse(tournament ? tournament?.content?.general?.logo?.url : event?.content?.general?.thumbnail?.url),
                        500
                      )}
                      alt={parse(tournament ? tournament?.content?.general?.logo?.altText : event?.content?.general?.thumbnail?.altText)}
                      title={parse(tournament ? tournament?.content?.general?.logo?.title : event?.content?.general?.thumbnail?.title)}
                      className="summary-panel__image"
                    />
                  )}
                </div>
              )}
              <div className="summary-panel__header-text">
                <h4 className="summary-panel__heading">{bookingSearch?.allotment.name}</h4>

                <div className={`state state--mobile-only ${activeStep >= 3 && priceRecalculating != "" ? "state--loading" : ""}`}>
                  <div className="preloader state__front">
                    <div className="preloader-spinner">
                      <div className="preloader__icon">
                        <Icon name="spinner-white" />
                      </div>
                      <div className="preloader__label">
                        Wordt verwerkt
                        <div className="preloader__dots">
                          <div className="preloader__dot-1">.</div>
                          <div className="preloader__dot-2">.</div>
                          <div className="preloader__dot-3">.</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="summary-panel__configurable-data state__back">
                    <div className="arrow-list">
                      <ul>
                        <li>
                          {selectedOutwardFlight?.startDateTime
                            ? getDateOnlyString(selectedOutwardFlight.startDateTime)
                            : bookingDetails
                            ? getDateOnlyString(bookingDetails.fromDate)
                            : bookingSearch
                            ? getDateOnlyString(bookingSearch.fromDate)
                            : ""}
                        </li>
                        <li>
                          {selectedReturnFlight?.startDateTime
                            ? getDateOnlyString(selectedReturnFlight.startDateTime)
                            : bookingDetails
                            ? getDateOnlyString(bookingDetails.toDate)
                            : bookingSearch
                            ? getDateOnlyString(bookingSearch.toDate)
                            : ""}
                        </li>
                      </ul>
                    </div>
                    <div className="summary-panel__to-pay">
                      <div className="summary-panel__total">
                        <h6 className="summary-panel__heading">{translate(translations, language ? language : "nl-BE", "TOTAL")}</h6>
                        <span className={`pricing ${showSpinner ? "state state--loading" : ""}`}>
                          <span className={priceRecalculating !== "" ? "state__back" : ""}>
                            &euro;
                            {!showCachedPrices && bookingPriceDetail?.details
                              ? sumBy(bookingPriceDetail?.details, "total").toFixed(2)
                              : getCachedPrice()}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button type="button" onClick={() => setSummaryOpen(!summaryOpen)} className="summary-panel__toggle-button"></button>
            </div>

            <div className="summary-panel__body">
              {/* ===========================
            START example: upsell + spinner 
            =========================== */}
              {/* <div className="summary-panel__region">
                <div className="summary-panel__region-header">
                  <Icon name="euro-coins" />
                  <h6 className="summary-panel__region-heading">Example: upsell pricing</h6>
                  <span className="text-primary">+ € 210,00</span>
                </div>
                <div className="summary-panel__region-body ">
                  <div className="summary-panel__description-column ">
                    <div className="list-summary">
                      <ul>
                        <li>
                          <strong>Wedstrijddatum:</strong>zo 18 sep. 2022&nbsp;-&nbsp;17:30
                        </li>
                        <li>
                          <span className="list-summary__property">
                            <strong>Ticket:</strong> 2x Managers Bar
                          </span>
                          <span className="list-summary__pricing">
                            <span className="text-primary">+ € 35,00</span>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div className="summary-panel__region">
                <div className="summary-panel__region-header">
                  <Icon name="euro-coins" />
                  <h6 className="summary-panel__region-heading">Example: calculating spinner</h6>
                  <div className="preloader-spinner">
                    <div className="preloader__icon">
                      <Icon name="spinner" />
                    </div>
                    <div className="preloader__label">
                      Wordt berekend
                      <div className="preloader__dots">
                        <div className="preloader__dot-1">.</div>
                        <div className="preloader__dot-2">.</div>
                        <div className="preloader__dot-3">.</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="summary-panel__region-body ">
                  <div className="summary-panel__description-column ">
                    <div className="list-summary">
                      <ul>
                        <li>
                          <strong>Wedstrijddatum:</strong>zo 18 sep. 2022&nbsp;-&nbsp;17:30
                        </li>
                        <li>
                          <span className="list-summary__property">
                            <strong>Ticket:</strong> 2x Managers Bar
                          </span>
                          <span className="list-summary__pricing">
                            <div className="preloader-spinner">
                              <div className="preloader__icon">
                                <Icon name="spinner" />
                              </div>
                              <div className="preloader__label">
                                Wordt berekend
                                <div className="preloader__dots">
                                  <div className="preloader__dot-1">.</div>
                                  <div className="preloader__dot-2">.</div>
                                  <div className="preloader__dot-3">.</div>
                                </div>
                              </div>
                            </div>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* ===========================
            END example: upsell + spinner 
            =========================== */}

              {noPackageFound ? (
                <div className={`summary-panel__region`}>
                  <div className="summary-panel__no-package">
                    <p>
                      Geen pakket gevonden.
                      <br />
                      Neem contact met ons op voor <small>&nbsp;</small>
                      <Link path="/groepsreizen" className="" title="Klik hier voor het contactformulier">
                        een reis op maat
                      </Link>
                      .
                    </p>
                  </div>
                </div>
              ) : (
                <>
                  <div
                    className={`summary-panel__region collapse ${showTravelGroupDetails ? "collapse--show" : ""}`}
                    onClick={() => setShowTravelGroupDetails(!showTravelGroupDetails)}
                  >
                    <div className="summary-panel__region-header">
                      <Icon name="user"></Icon>
                      <h6 className="summary-panel__region-heading">{translate(translations, language ? language : "nl-BE", "TRAVELLINGGROUP")}</h6>
                    </div>
                    <div className="summary-panel__region-body ">
                      <div className="summary-panel__description-column">
                        <p>
                          {`${numberOfAdults} ${translate(translations, language ? language : "nl-BE", "ADULTS")}`},{" "}
                          {`${numberOfChildren} ${translate(translations, language ? language : "nl-BE", "CHILDREN")}`}
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* Ticket cached data */}
                  {/* {bookingSearch && !selectedTicket && !cheapestTicket && (
                    <div className="summary-panel__region">
                      <div className="summary-panel__region-header">
                        <Icon name="ticket"></Icon>
                        <h6 className="summary-panel__region-heading">{translate(translations, language ? language : "nl-BE", "TICKETS")} cache</h6>
                      </div>
                      <div className="summary-panel__region-body">
                        <div className="summary-panel__description-column">
                          <div className="list-summary">
                            <ul>
                              <li>
                                <strong>{translate(translations, language ? language : "nl-BE", "MATCHDATE")}:</strong>
                                {bookingSearch.fromDate
                                  ? new Date(bookingSearch.fromDate).toLocaleString(language ? language : "nl-BE", {
                                      weekday: "short",
                                      year: "numeric",
                                      month: "short",
                                      day: "numeric",
                                    })
                                  : ""}
                                &nbsp;-&nbsp;
                                {parseTime(bookingSearch.allotment.startTime ?? "")}
                              </li>
                              <li>
                                <span className="list-summary__property">
                                  <strong>Ticket:</strong> {numberOfPax}x {bookingSearch.accommodationName}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  )} */}
                  {/* Ticket realtime data */}
                  {selectedTicket && cheapestTicket && (
                    <div
                      className={`summary-panel__region collapse ${showTicketDetails ? "collapse--show" : ""}`}
                      onClick={() => setShowTicketDetails(!showTicketDetails)}
                    >
                      <div className="summary-panel__region-header">
                        <Icon name="ticket"></Icon>
                        <h6 className="summary-panel__region-heading">{translate(translations, language ? language : "nl-BE", "TICKETS")}</h6>
                        {priceRecalculating != "tickets" && (selectedTicket?.price as number) - cheapestTicket.price > 0 && (
                          <span className="text-primary">+ € {((selectedTicket?.price as number) - cheapestTicket.price).toFixed(2)}</span>
                        )}
                      </div>
                      <div className={`summary-panel__region-body ${priceRecalculating == "tickets" ? "state state--loading" : ""}`}>
                        <div className={`summary-panel__description-column ${priceRecalculating == "tickets" ? "state__back" : ""}`}>
                          <div className="list-summary">
                            <ul>
                              <li>
                                <strong>
                                  {translate(
                                    translations,
                                    language ? language : "nl-BE",
                                    process.env.WEBSITE === "Sportreizen" ? "EVENTDATE" : "MATCHDATE"
                                  )}
                                  :
                                </strong>
                                {selectedTicket.from
                                  ? new Date(selectedTicket.from).toLocaleString(language ? language : "nl-BE", {
                                      weekday: "short",
                                      year: "numeric",
                                      month: "short",
                                      day: "numeric",
                                    })
                                  : ""}
                                &nbsp;-&nbsp;
                                {selectedTicket.from != selectedTicket.to
                                  ? new Date(selectedTicket.to).toLocaleString(language ? language : "nl-BE", {
                                      weekday: "short",
                                      year: "numeric",
                                      month: "short",
                                      day: "numeric",
                                    })
                                  : parseTime(bookingDetails?.startTime ?? bookingSearch?.allotment.startTime ?? "")}
                              </li>
                              {activeStep >= 2 ? (
                                <>
                                  <li>
                                    <span className="list-summary__property">
                                      <strong>Ticket:</strong> {numberOfPax}x {selectedTicket.accommodationName}
                                    </span>
                                    {getPriceDetail(selectedTicket.productCode, selectedTicket.accommodationCode)?.showPrice && (
                                      <span className="list-summary__pricing">
                                        <span>&euro; {getPrice(selectedTicket.productCode).toFixed(2)}</span>
                                      </span>
                                    )}
                                  </li>
                                  {subProducts.length > 0 &&
                                    subProducts.map((sp, i) => (
                                      <li key={i}>
                                        <span className="list-summary__property">
                                          <strong>Extra:</strong>
                                          {sp.line.startDate
                                            ? new Date(sp.line.startDate).toLocaleString(language ? language : "nl-BE", {
                                                day: "numeric",
                                                month: "numeric",
                                                year: "numeric",
                                                hour: "2-digit",
                                                minute: "2-digit",
                                                hour12: false,
                                              })
                                            : ""}
                                          : {sp.line.productName}
                                        </span>
                                        {getPriceDetail(sp.line.productCode, sp.line.accommodationCode)?.showPrice && (
                                          <span className="list-summary__pricing">
                                            <span className="text-primary">
                                              + € {getPriceDetail(sp.line.productCode, sp.line.accommodationCode)?.total.toFixed(2)}
                                            </span>
                                          </span>
                                        )}
                                      </li>
                                    ))}
                                </>
                              ) : (
                                <li>{translate(translations, language ? language : "nl-BE", "TICKETS_INCLUSIVE")}</li>
                              )}
                            </ul>
                          </div>
                        </div>
                        {priceRecalculating == "tickets" && (
                          <div className="preloader state__front">
                            <div className="preloader-spinner">
                              <div className="preloader__icon">
                                <Icon name="spinner" />
                              </div>
                              <div className="preloader__label">
                                Wordt verwerkt
                                <div className="preloader__dots">
                                  <div className="preloader__dot-1">.</div>
                                  <div className="preloader__dot-2">.</div>
                                  <div className="preloader__dot-3">.</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {/* Flights cached data */}
                  {activeStep < 3 && initialServiceTypes?.includes(ServiceType.flight) && bookingSearch && (
                    <div
                      className={`summary-panel__region collapse ${showTransportDetails ? "collapse--show" : ""}`}
                      onClick={() => setShowTransportDetails(!showTransportDetails)}
                    >
                      <div className="summary-panel__region-header">
                        <Icon name="markers-ab"></Icon>
                        <h6 className="summary-panel__region-heading">
                          {translate(translations, language ? language : "nl-BE", "TRANSPORT")}:{" "}
                          {translate(translations, language ? language : "nl-BE", "FLIGHT")}
                        </h6>
                        {flightPriceDifference != 0 && (
                          <span className={flightPriceDifference > 0 ? "pricing--increase" : "pricing--decrease"}>
                            {flightPriceDifference > 0 ? <>+</> : <>-</>}
                            &nbsp;€&nbsp;
                            {(flightPriceDifference < 0 ? flightPriceDifference * -1 : flightPriceDifference).toFixed(2)}
                          </span>
                        )}
                      </div>
                      <div className={`summary-panel__region-body`}>
                        <div className={`summary-panel__description-column`}>
                          <p>
                            <strong>
                              {translate(translations, language ? language : "nl-BE", "DEPARTURE")}{" "}
                              {getDateOnlyString(bookingSearch.outwardFlightStartDate!)}
                            </strong>
                          </p>
                        </div>
                        <div className={`summary-panel__description-column ${priceRecalculating == "flights" ? "state__back" : ""}`}>
                          <p>
                            <strong>
                              {translate(translations, language ? language : "nl-BE", "ARRIVAL")}{" "}
                              {getDateOnlyString(bookingSearch.returnFlightEndDate!)}
                            </strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Flights realtime data */}
                  {(activeStep >= 3 || (activeStep < 3 && packageView)) && selectedOutwardFlight && selectedReturnFlight && cheapestFlight && (
                    <div
                      className={`summary-panel__region collapse ${showTransportDetails ? "collapse--show" : ""}`}
                      onClick={() => setShowTransportDetails(!showTransportDetails)}
                    >
                      <div className="summary-panel__region-header">
                        <Icon name="markers-ab"></Icon>
                        <h6 className="summary-panel__region-heading">
                          {translate(translations, language ? language : "nl-BE", "TRANSPORT")}:{" "}
                          {translate(translations, language ? language : "nl-BE", "FLIGHT")}
                        </h6>
                        {priceRecalculating != "flights" &&
                          // !showCachedPrices &&
                          (showFlightsPrice(selectedOutwardFlight, selectedReturnFlight) ? (
                            <span className={priceRecalculating == "flights" || priceRecalculating == "all" ? "state state--loading" : ""}>
                              &euro; {getFlightsPrice(selectedOutwardFlight, selectedReturnFlight)}
                            </span>
                          ) : (
                            flightPriceDifference != 0 && (
                              <span className={flightPriceDifference > 0 ? "pricing--increase" : "pricing--decrease"}>
                                {flightPriceDifference > 0 ? <>+</> : <>-</>}
                                &nbsp;€&nbsp;
                                {(flightPriceDifference < 0 ? flightPriceDifference * -1 : flightPriceDifference).toFixed(2)}
                              </span>
                            )
                          ))}
                      </div>
                      <div
                        className={`summary-panel__region-body ${priceRecalculating == "flights" && !showCachedPrices ? "state state--loading" : ""}`}
                      >
                        <div
                          className={`summary-panel__description-column ${priceRecalculating == "flights" && !showCachedPrices ? "state__back" : ""}`}
                        >
                          <div>
                            <strong>
                              {translate(translations, language ? language : "nl-BE", "DEPARTURE")}{" "}
                              {getDateOnlyString(selectedOutwardFlight.startDateTime)}
                            </strong>
                            {activeStep >= 3 && (
                              <ul>
                                <li>{first(selectedOutwardFlight?.flightMetaData?.flightLines)?.departureAirportDescription}</li>
                                <li>{selectedOutwardFlight.code}</li>
                                <li>
                                  {first(selectedOutwardFlight?.flightMetaData?.flightLines)?.departureTime} &gt;{" "}
                                  {last(selectedOutwardFlight?.flightMetaData?.flightLines)?.arrivalTime}
                                </li>
                              </ul>
                            )}
                          </div>
                        </div>
                        <div className={`summary-panel__description-column ${priceRecalculating == "flights" ? "state__back" : ""}`}>
                          <div>
                            <strong>
                              {translate(translations, language ? language : "nl-BE", "ARRIVAL")}{" "}
                              {getDateOnlyString(selectedReturnFlight.endDateTime)}
                            </strong>
                            {activeStep >= 3 && (
                              <ul>
                                <li>{first(selectedReturnFlight?.flightMetaData?.flightLines)?.departureAirportDescription}</li>
                                <li>{selectedReturnFlight.code}</li>
                                <li>
                                  {first(selectedReturnFlight?.flightMetaData?.flightLines)?.departureTime} &gt;{" "}
                                  {last(selectedReturnFlight?.flightMetaData?.flightLines)?.arrivalTime}
                                </li>
                              </ul>
                            )}
                          </div>
                        </div>
                        {priceRecalculating == "flights" && !showCachedPrices && (
                          <div className="preloader state__front">
                            <div className="preloader-spinner">
                              <div className="preloader__icon">
                                <Icon name="spinner" />
                              </div>
                              <div className="preloader__label">
                                Wordt verwerkt
                                <div className="preloader__dots">
                                  <div className="preloader__dot-1">.</div>
                                  <div className="preloader__dot-2">.</div>
                                  <div className="preloader__dot-3">.</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {/* Hotel cached data */}
                  {initialServiceTypes?.includes(ServiceType.hotel) && (
                    <>
                      {((bookingSearch && activeStep < 4 && !packageView) ||
                        (bookingSearch && activeStep >= 4 && (!selectedHotel || !cheapestHotel))) && (
                        <div
                          className={`summary-panel__region collapse ${showHotelDetails ? "collapse--show" : ""}`}
                          onClick={() => setShowHotelDetails(!showHotelDetails)}
                        >
                          <div className="summary-panel__region-header">
                            <Icon name="bed-booking"></Icon>
                            <h6 className="summary-panel__region-heading">
                              {`${translate(translations, language, "STAY")}:
                            ${
                              differenceInCalendarDays(
                                new Date(bookingSearch.toDate.replace("Z", "")),
                                new Date(bookingSearch.fromDate.replace("Z", ""))
                              ) + 1
                            } ${translate(translations, language, "DAYS")}, 
                            ${differenceInCalendarDays(
                              new Date(bookingSearch.toDate.replace("Z", "")),
                              new Date(bookingSearch.fromDate.replace("Z", ""))
                            )} ${translate(translations, language, "NIGHTS")}`}
                            </h6>
                          </div>
                          <div className={`summary-panel__region-body`}>
                            <div className={`summary-panel__description-column`}>
                              <p>
                                <strong>
                                  {`${differenceInCalendarDays(
                                    new Date(bookingSearch.toDate.replace("Z", "")),
                                    new Date(bookingSearch.fromDate.replace("Z", ""))
                                  )} ${translate(translations, language, "NIGHTS_IN_HOTEL")}`}
                                </strong>
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* Hotel realtime data */}
                      {(activeStep >= 4 || (activeStep < 4 && packageView)) && selectedHotel && cheapestHotel && (
                        <div
                          className={`summary-panel__region collapse ${showHotelDetails ? "collapse--show" : ""}`}
                          onClick={() => setShowHotelDetails(!showHotelDetails)}
                        >
                          <div className="summary-panel__region-header">
                            <Icon name="bed-booking"></Icon>
                            {selectedHotel && (
                              <>
                                <h6 className="summary-panel__region-heading">
                                  {`${translate(translations, language, "STAY")}:
                            ${
                              differenceInCalendarDays(
                                new Date(selectedHotel.endDate.replace("Z", "")),
                                new Date(selectedHotel.startDate.replace("Z", ""))
                              ) + 1
                            } ${translate(translations, language, "DAYS")}, 
                            ${differenceInCalendarDays(
                              new Date(selectedHotel.endDate.replace("Z", "")),
                              new Date(selectedHotel.startDate.replace("Z", ""))
                            )} ${translate(translations, language, "NIGHTS")}`}
                                </h6>
                                {priceRecalculating != "hotel" &&
                                  priceRecalculating != "all" &&
                                  hotelsLoaded &&
                                  getHotelPrice(selectedHotel.productCode) - cheapestHotel?.cheapestPrice > 0 && (
                                    <span className="text-primary">
                                      + € {(getHotelPrice(selectedHotel.productCode) - cheapestHotel?.cheapestPrice).toFixed(2)}
                                    </span>
                                  )}
                              </>
                            )}
                          </div>
                          <div
                            className={`summary-panel__region-body ${
                              priceRecalculating == "hotel" || priceRecalculating == "all" ? "state state--loading" : ""
                            }`}
                          >
                            <div
                              className={`summary-panel__description-column ${
                                priceRecalculating == "hotel" || priceRecalculating == "all" ? "state__back" : ""
                              }`}
                            >
                              <p>
                                {selectedHotel && (
                                  <strong>
                                    {activeStep < 4 && !packageView
                                      ? `${selectedHotel.stars} ${translate(translations, language ? language : "nl-BE", "STARS")}`
                                      : selectedHotel?.name}
                                    ,&nbsp;
                                    {format(new Date(selectedHotel.startDate), "dd/MM/yyyy")} &gt;{" "}
                                    {format(new Date(selectedHotel.endDate), "dd/MM/yyyy")}
                                  </strong>
                                )}
                              </p>

                              {hotelRooms && activeStep >= 4 && (
                                <div className="list-summary list-summary--markers">
                                  <ul>
                                    {hotelRooms.map((hr, i) => (
                                      <li key={i}>
                                        <span className="list-summary__property">
                                          1x {mapRoom(hr.accommodationName)}, {mapRegime(hr.regimeName)}
                                        </span>
                                        {getPriceDetail(selectedHotel.productCode, hr.accommodationCode)?.showPrice && (
                                          <span className="list-summary__pricing">&euro; {getPrice(selectedHotel.productCode).toFixed(2)}</span>
                                        )}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}
                            </div>
                            {(priceRecalculating == "hotel" || priceRecalculating == "all") && (
                              <div className="preloader state__front">
                                <div className="preloader-spinner">
                                  <div className="preloader__icon">
                                    <Icon name="spinner" />
                                  </div>
                                  <div className="preloader__label">
                                    Wordt verwerkt
                                    <div className="preloader__dots">
                                      <div className="preloader__dot-1">.</div>
                                      <div className="preloader__dot-2">.</div>
                                      <div className="preloader__dot-3">.</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  {extraCosts && extraCosts?.length > 0 && (
                    <div className="summary-panel__region summary-panel__region--no-icon">
                      <div className="summary-panel__region-header">
                        <h6 className="summary-panel__region-heading">{translate(translations, language ? language : "nl-BE", "EXTRA_COSTS")}</h6>
                      </div>
                      {extraCosts.map((e, i) => (
                        <div key={i} className="summary-panel__region-header">
                          <p>
                            <strong>{e.line.productName}</strong>
                          </p>
                          <p>&euro; {e.line.price}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>

            <div className="summary-panel__footer">
              {!(roomDistributionErrors.length > 0 && first(roomDistributionErrors)?.key != "singleRooms") && (
                <div className="summary-panel__to-pay">
                  <div className="summary-panel__total">
                    <h6 className="summary-panel__heading">{translate(translations, language ? language : "nl-BE", "TOTAL")}</h6>
                    {showSpinner ? (
                      <div className="preloader-spinner">
                        <div className="preloader__icon">
                          <Icon name="spinner" />
                        </div>
                        <div className="preloader__label">
                          Wordt berekend
                          <div className="preloader__dots">
                            <div className="preloader__dot-1">.</div>
                            <div className="preloader__dot-2">.</div>
                            <div className="preloader__dot-3">.</div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <span className="pricing">
                        <span>
                          &euro;{" "}
                          {!showCachedPrices && bookingPriceDetail?.details
                            ? sumBy(bookingPriceDetail?.details, "total").toFixed(2)
                            : getCachedPrice()}
                        </span>
                      </span>
                    )}
                  </div>
                  {((bookingSearch?.deposit !== bookingSearch?.price && !bookingPriceDetail) ||
                    (bookingPriceDetail && bookingPriceDetail?.deposit?.toFixed(2) !== sumBy(bookingPriceDetail?.details, "total").toFixed(2))) && (
                    <>
                      <div className="summary-panel__deposit">
                        <h6 className="summary-panel__heading">{translate(translations, language ? language : "nl-BE", "DEPOSIT")}</h6>
                        {showSpinner ? (
                          <div className="preloader-spinner">
                            <div className="preloader__icon">
                              <Icon name="spinner" />
                            </div>
                            <div className="preloader__label">
                              Wordt berekend
                              <div className="preloader__dots">
                                <div className="preloader__dot-1">.</div>
                                <div className="preloader__dot-2">.</div>
                                <div className="preloader__dot-3">.</div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <span className="pricing">
                            <span>
                              &euro; {!showCachedPrices && bookingPriceDetail?.deposit ? bookingPriceDetail?.deposit?.toFixed(2) : getCachedDeposit()}
                            </span>
                          </span>
                        )}
                      </div>

                      {!showSpinner ? (
                        <div className="summary-panel__disclaimer">
                          <span className={`pricing ${activeStep > 3 && priceRecalculating !== "" ? "state state--loading" : ""}`}>
                            <span
                              className={activeStep > 3 && priceRecalculating !== "" ? "state__back" : ""}
                              dangerouslySetInnerHTML={{ __html: disclaimer() }}
                            ></span>
                          </span>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>

            {/* <div className="discount-disclosure">    
              <h6 className="discount-disclosure__heading">
              <div className="inline-icon">
                <Icon name="giftbox"/>
              </div>
              Heb je een kortingscode?</h6>   
              <p>Kortingscodes kunnen toegevoegd worden bij het betalingsproces.</p>
            </div> */}

            <div className="payment-logos">
              <div className="payment-logos__visa">
                <Icon name="visa" />
              </div>
              <div className="payment-logos__mastercard">
                <Icon name="mastercard" />
              </div>
              <div className="payment-logos__bancontact">
                <Icon name="bancontact" />
              </div>
              <div className="payment-logos__ideal">
                <Icon name="ideal" />
              </div>
              <div className="payment-logos__paypal">
                <Icon name="paypal" />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SummaryPanel;
