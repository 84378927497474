// import { de, enGB, nlBE } from "date-fns/locale";

// export const languageDescription = (language: string) => {
//   switch (language) {
//     case "nl-BE":
//       return "NL";
//     case "en-GB":
//       return "EN";
//     case "de-DE":
//       return "DE";
//   }
// }

// export const localeMapper = (language: string) => {
//   switch (language) {
//     case "en-GB":
//       return enGB;
//     case "de-DE":
//       return de;
//     default:
//       return nlBE;
//   }
// }
import { add, isWithinInterval, parse } from 'date-fns';

import { isEmpty, isNil } from 'lodash';

const countries = ['BE', 'NL', 'LU', 'FR'];
const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

//Errors

export const EMPTY = 'EMPTY';
export const BIRTHDATE_INVALID = 'BIRTHDATE_INVALID';
export const PHONE_NUMBER_INVALID = 'PHONE_NUMBER_INVALID';
export const EMAIL_INVALID = 'EMAIL_INVALID';

// Validation

export function isValidBirthDate(date: any) {
  if (isNil(date)) {
    return false
  }
  
  const earliestDate = add(new Date(), { years: -100 });
  const latestDate = add(new Date(), { years: 1 });
  return isWithinInterval(new Date(date), {
    start: earliestDate,
    end: latestDate,
  });
}

export function getBirthDateError(date: any) {
  // Empty
  if (isEmpty(date)) {
    return EMPTY;
  }

  const parsedDate = parse(date, 'yyyy-MM-dd', new Date());

  // Invalid
  const earliestDate = add(new Date(), { years: -100 });
  const latestDate = add(new Date(), { years: 1 });

  if (
    !isWithinInterval(new Date(parsedDate), {
      start: earliestDate,
      end: latestDate,
    })
  ) {
    return BIRTHDATE_INVALID;
  }
}

export function isValidPhoneNumber(phoneNumber: any) {
  if (isEmpty(phoneNumber)) {
    return false;
  }

  return true;
}

export function getPhoneNumberError(phoneNumber: any) {
  // Empty
  if (isEmpty(phoneNumber)) {
    return EMPTY;
  }

  // Invalid
  const validation = countries.map(country => {
    // const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber, country);

    // return Boolean(parsedPhoneNumber && parsedPhoneNumber.isValid());
  });

  if (!Boolean(validation.some(x => x))) {
    return PHONE_NUMBER_INVALID;
  }
}

export function isValidEmail(email: any) {
  if (isEmpty(email)) {
    return false;
  }

  return regexEmail.test(email);
}

export function getEmailError(email: any) {
  // Empty
  if (isEmpty(email)) {
    return EMPTY;
  }

  if (!regexEmail.test(email)) {
    return EMAIL_INVALID;
  }
}
