import React, { useState } from "react";
import { usePopperTooltip } from "react-popper-tooltip";
import Icon from "../../icon";

interface TooltipModalProps {
  text?: string;
  title: string;
  handleHover: (Code: string) => void;
  hovered: string;
  suffix: string;
  hideTitle?: boolean;
  Offset?: [number, number];
  required?: boolean;
  placement?: any;
  leftAlign?: boolean;
  extraClass?: string;
}

const TooltipModal: React.FC<TooltipModalProps> = ({
  title,
  text,
  handleHover,
  hovered,
  suffix,
  hideTitle,
  Offset,
  required,
  placement,
  leftAlign,
  extraClass,
}) => {
  const [controlledVisible, setControlledVisible] = useState(false);

  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip(
    {
      trigger: null,
      interactive: true,
      placement: placement ?? "top",
      offset: Offset,
      visible: controlledVisible,
      onVisibleChange: setControlledVisible,
    },
    {
      placement: placement ?? "top",
    }
  );

  const handleTooltipTrigger = () => {
    handleHover(title + suffix);
    setControlledVisible(true);
  };

  const tooltipFrameClassName = "tooltip-modal__frame" + (leftAlign ? " tooltip-modal__frame--left" : "");

  return (
    <>
      <div className="tooltip-modal__label" ref={setTriggerRef} onMouseOver={() => handleTooltipTrigger()}>
        {!hideTitle && (
          <span className="tooltip-modal__label-text">
            {title} {required ? "*" : ""}
          </span>
        )}
        <div className={`tooltip-modal ${extraClass} ${hovered == title + suffix ? " tooltip-modal--active" : ""}`}>
          <div className="tooltip-modal__trigger">
            <Icon name="tooltip-white" />
          </div>
          {visible && hovered == title + suffix && (
            <div className="tooltip-modal__frame" ref={setTooltipRef} {...getTooltipProps({ className: tooltipFrameClassName })}>
              <div {...getArrowProps({ className: "tooltip-arrow" })} />
              <div className="tooltip-modal__header">
                <h3 className="tooltip-modal__heading">{title}</h3>
                <div className="tooltip-modal__actions">
                  <button
                    type="button"
                    onClick={() => {
                      handleHover("");
                      setControlledVisible(false);
                    }}
                    className="tooltip-modal__close-button"
                  ></button>
                </div>
              </div>
              <div className="tooltip-modal__body" dangerouslySetInnerHTML={{ __html: text ?? "" }}>
                {/* <p>
                                    <strong>{text}</strong>
                                </p> */}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TooltipModal;
