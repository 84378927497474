import React, { useContext } from "react";
import Icon from "../icon";
import BookingContext, { BookingContextType } from "../../contexts/booking-provider";
import { parse } from "../../utils/component";
import { isEmpty } from "lodash";
import { Hotel } from "../../site-types";

interface ScoreHeadingProps {
  hotel: Hotel;
  moreInfoTitle?: string;
  onHandleSetFlyInHotel?: (hotel: any) => void;
}

const ScoreHeading: React.FC<ScoreHeadingProps> = ({ hotel, moreInfoTitle, onHandleSetFlyInHotel }) => {
  const { flyInIsOpen, setFlyInIsOpen } = useContext<BookingContextType>(BookingContext);

  const createArrayFromDecimal = (numString: string) => {
    const num = parseFloat(numString);
    const wholePart = Math.floor(num);
    const fractionalPart = num - wholePart;

    const array = [...Array(wholePart)].map((_, i) => i + 1);

    if (fractionalPart > 0) {
      array.push(fractionalPart);
    }

    return array;
  };

  return (
    <div className="score-heading">
      {hotel.stars && (
        <div className="rating">
          <div className="list-inline">
            <ul>
              {createArrayFromDecimal(hotel.stars).map((star, i) => (
                <li key={i}>{star === 0.5 ? <Icon name="star-half" /> : <Icon name="star-full" />}</li>
              ))}
            </ul>
          </div>
        </div>
      )}
      <h4 className="score-heading__heading">
        {hotel.name}
        {onHandleSetFlyInHotel && (
          <button
            type="button"
            className="accommodations__more-info"
            onClick={() => {
              setFlyInIsOpen(!flyInIsOpen);
              onHandleSetFlyInHotel(hotel);
            }}
          >
            {moreInfoTitle}
          </button>
        )}
      </h4>
      {/* <div className="score-heading__score">
                <span className="score-heading__score-number">{Number(rating) * 20}</span>
            </div> */}
      <div className="score-tripadvisor">
        <div className="score-tripadvisor__number">{Number(parse(hotel.rating)) * 2}</div>
        <div className="score-tripadvisor__emblem">
          <Icon name="tripadvisor" />
        </div>
      </div>
    </div>
  );
};

export default ScoreHeading;
