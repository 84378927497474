import React, { useContext, useEffect, useState } from "react";
import GlobalContext, { GlobalContextType } from "../../contexts/global-provider";
import translate from "../../utils/translate";
import translations from "./translations.json";
import BookingContext, { BookingContextType } from "../../contexts/booking-provider";
import { parse } from "../../utils/component";
import ScoreHeading from "../score-heading/";
import { cloneDeep, find, first, orderBy, split, sumBy } from "lodash";
import Carousel from "nuka-carousel";
import { BookingOptionUnit, BookingPackageLine, PerUnitPackageOption } from "@qite/tide-client/build/types";
import { ServiceType } from "@qite/tide-client";
import { mapRegime, mapRoom } from "../../utils/room-regime-mapper";
import { getPlaceHolderImage } from "../../utils/image-utils";
import { BookingPackageOption } from "@qite/tide-client/build/types/offer/booking-v2/shared/booking-package-option";
import { Hotel } from "../../site-types";
import { fetchRegimes } from "../../utils/hotel-utils";

interface FlyinProps {}

const Flyin: React.FC<FlyinProps> = () => {
  const { language } = useContext<GlobalContextType>(GlobalContext);
  const { bookingPackage, selectHotel, flyInIsOpen, setFlyInIsOpen, flyInHotel, flyInFacilities, requestRooms, hotels, packageView } =
    useContext<BookingContextType>(BookingContext);
  const [localBookingPackageOption, setLocalBookingPackageOption] = useState<BookingPackageOption>();

  const selectedHotel = hotels ? hotels.find((h) => h.name == flyInHotel) : undefined;
  const hotelInPackage = hotels ? hotels.find((h) => h.isSelected) : undefined;
  const roomIndexes = selectedHotel?.rooms
    .map((r) => r.roomIndex)
    .reduce((unique: number[], ri: number) => {
      if (!unique.includes(ri)) {
        unique.push(ri);
      }
      return unique;
    }, []);
  const numberOfPax = requestRooms.flatMap((r) => r.pax).length;
  const [hotel, setHotel] = useState<Hotel>();
  const [regime, setRegime] = useState<string>("");
  const [possibleRegimes, setPossibleRegimes] = useState<string[]>([]);

  const setBookingPackageUnitOptions = (hotel: Hotel, preLocalBookingPackageOption: BookingPackageOption) => {
    if (hotel) {
      preLocalBookingPackageOption.optionUnits.forEach((x) => (x.groups = x.groups.filter((y) => !y.options.some((z) => z.isHotelPool))));
      for (let index = 1; index < preLocalBookingPackageOption.optionUnits.length + 1; index++) {
        let unit = preLocalBookingPackageOption.optionUnits.find((x) => x.index == index);
        if (!unit) {
          unit = {
            index: index,
            groups: [],
            airlineGroups: [],
            airportGroups: [],
          } as BookingOptionUnit;
          preLocalBookingPackageOption.optionUnits.push(unit);
        }
        var group = unit.groups.find((x) => x.name == "");
        if (!group) {
          group = {
            name: "",
            title: "",
            options: [],
          };
          unit.groups.push(group);
        }
        var alternatives: PerUnitPackageOption[] = [];
        hotel.rooms
          .filter((ro) => ro.roomIndex == index)
          .map((room) => {
            const unitOption: PerUnitPackageOption = {
              alternatives: [],
              groups: [],
              isDefault: false,
              isHotelPool: true,
              isSelected: room.isSelected,
              line: {
                accommodationCode: room.accommodationCode,
                accommodationName: room.accommodationName,
                endDate: hotel.endDate,
                entryLineGuid: room.entryLineGuid,
                price: room.price,
                productCode: hotel.productCode,
                productName: hotel.name,
                regimeCode: room.regimeCode,
                regimeName: room.regimeName,
                startDate: hotel.startDate,
                serviceType: ServiceType.hotel,
              } as BookingPackageLine,
              pax: [],
              requirementType: 1,
            } as PerUnitPackageOption;
            alternatives.push(unitOption);
          });

        var selected = alternatives.find((a) => a.isSelected);
        if (selected) {
          selected.alternatives = alternatives.filter((x) => x !== selected).map((a) => a.line);
          group.options.push(selected);
        }
      }

      setLocalBookingPackageOption(preLocalBookingPackageOption);
    }
  };

  useEffect(() => {
    const bookingDetails = bookingPackage?.options.find((o) => o.isSelected);
    if (bookingDetails && selectedHotel) {
      var deep = cloneDeep(bookingDetails);
      var hotel = cloneDeep(selectedHotel);
      setHotel(hotel);
      setRegime(hotel.rooms.find((r) => r.isSelected)?.regimeName ?? "");
      setPossibleRegimes(fetchRegimes(selectedHotel));
      if (!packageView) {
        setBookingPackageUnitOptions(hotel, deep);
      }
    }
  }, [selectedHotel]);

  const parseImages = (imageString: string) => {
    const images = split(imageString, "%image%");
    return images;
  };

  const getBottomControls = (images: any[], currentSlide: any, goToSlide: any) => {
    return (
      <div className="carousel__indicators">
        {images &&
          images.map((image, i) => (
            <button
              key={i}
              type="button"
              className={`carousel__indicator ${currentSlide === i ? "carousel__indicator--active" : ""}`}
              onClick={() => goToSlide(i)}
            ></button>
          ))}
      </div>
    );
  };

  const getSelectedValue = (hotel: Hotel, index: number) => {
    return hotel.rooms.find((r) => r.isSelected && r.roomIndex === index + 1)?.accommodationName;
  };

  const getSelectedRegimeValue = (hotel: Hotel) => {
    return hotel.rooms.find((r) => r.isSelected)?.regimeName;
  };

  const changeSelectedRoom = (event: React.ChangeEvent<HTMLSelectElement>, index: number) => {
    const selectedValue = find(event.target.options, "selected")?.value;
    if (selectedValue && hotel) {
      hotel.rooms.filter((r) => r.roomIndex == index + 1).map((o) => (o.isSelected = false));
      let selectedAcco = hotel.rooms.find((r) => r.accommodationName === selectedValue && r.roomIndex == index + 1 && r.regimeName === regime);

      // wanneer regime niet beschikbaar is voor kamer dan goedkoopste alternatief selecteren
      if (!selectedAcco) {
        selectedAcco = first(
          orderBy(
            hotel.rooms.filter((r) => r.accommodationName === selectedValue && r.roomIndex == index + 1),
            "price"
          )
        );
        if (selectedAcco) {
          setRegime(selectedAcco.regimeName);
        }
      }
      if (selectedAcco) {
        selectedAcco.isSelected = true;
        setHotel({ ...hotel });
        if (!packageView && localBookingPackageOption) {
          setBookingPackageUnitOptions(hotel, localBookingPackageOption);
        }
      }
    }
  };

  const changeSelectedRegime = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedRegimeName = find(event.target.options, "selected")?.value;

    if (selectedRegimeName && hotel) {
      setRegime(selectedRegimeName);
      roomIndexes?.map((index) => {
        const selectedAccoName = hotel.rooms.find((r) => r.roomIndex === index && r.isSelected)?.accommodationName;
        hotel.rooms.filter((r) => r.roomIndex == index).map((o) => (o.isSelected = false));
        const selectedAcco = hotel.rooms.find(
          (r) => r.accommodationName === selectedAccoName && r.roomIndex == index && r.regimeName === selectedRegimeName
        );
        if (selectedAcco) {
          selectedAcco.isSelected = true;
          setHotel({ ...hotel });
          if (!packageView && localBookingPackageOption) {
            setBookingPackageUnitOptions(hotel, localBookingPackageOption);
          }
        }
      });
    }
  };

  const getPriceDifference = (index: number, selectedAccoName?: string, currentAccoName?: string) => {
    const oldAcco = hotel?.rooms.find((r) => r.accommodationName === currentAccoName && r.roomIndex === index + 1 && r.regimeName === regime);
    let newAcco = hotel?.rooms.find((r) => r.accommodationName === selectedAccoName && r.roomIndex === index + 1 && r.regimeName === regime);
    // Opvangen dat soms hetzelfde regime niet beschikbaar is
    if (!newAcco) {
      newAcco = first(
        orderBy(
          hotel?.rooms.filter((r) => r.accommodationName === currentAccoName && r.roomIndex === index + 1),
          "price"
        )
      );
    }

    if (oldAcco && newAcco) {
      const priceDifference = oldAcco.price - newAcco.price;
      if (priceDifference < 0) {
        return `+ € ${((priceDifference * -1) / numberOfPax).toFixed(2)} p.p.`;
      }
      if (priceDifference > 0) {
        return `- € ${(priceDifference / numberOfPax).toFixed(2)} p.p.`;
      }
      if (priceDifference == 0) {
        return ``;
      }
    }
  };

  const getRegimePriceDifference = (regimeName: string) => {
    if (localBookingPackageOption && hotel) {
      let oldPrice = 0;
      let newPrice = 0;
      for (var index = 1; index <= localBookingPackageOption.requestRooms.length; index++) {
        const oldLine = first(hotel.rooms.filter((r) => r.isSelected && r.roomIndex == index));

        const newLine =
          hotel.rooms.find((o) => o.accommodationName == oldLine?.accommodationName && o.regimeName == regimeName && o.roomIndex == index) ??
          first(
            orderBy(
              hotel.rooms.filter((r) => r.accommodationName == oldLine?.accommodationName && r.roomIndex == index),
              "price"
            )
          );
        if (oldLine && newLine) {
          oldPrice += oldLine.price;
          newPrice += newLine.price;
        }
      }

      const priceDifference = oldPrice - newPrice;
      if (priceDifference < 0) {
        return `+ € ${((priceDifference * -1) / numberOfPax).toFixed(2)} p.p.`;
      }
      if (priceDifference > 0) {
        return `- € ${(priceDifference / numberOfPax).toFixed(2)} p.p.`;
      }
      if (priceDifference == 0) {
        return ``;
      }
    }
  };

  const applyChanges = () => {
    if (hotel) {
      selectHotel(hotel);
      setFlyInIsOpen(false);
    }
  };

  const calcPriceDifference = () => {
    const priceSelectedHotel = sumBy(
      hotel?.rooms.filter((r) => r.isSelected),
      "price"
    );
    const priceHotelInPackage = sumBy(
      hotelInPackage?.rooms.filter((r) => r.isSelected),
      "price"
    );
    return priceSelectedHotel - priceHotelInPackage;
  };

  return (
    <>
      {selectedHotel && hotel && (
        <div className={`flyin ${flyInIsOpen ? "flyin--active" : ""}`}>
          <div className="flyin__backdrop" onClick={() => setFlyInIsOpen(false)}></div>
          <div className="flyin__shelf">
            <button type="button" onClick={() => setFlyInIsOpen(false)} className="flyin__close-button"></button>
            <div className="flyin__header">
              {parseImages(hotel.imageUrl).length > 1 ? (
                <Carousel
                  slidesToShow={1}
                  renderBottomCenterControls={({ currentSlide, goToSlide }) =>
                    getBottomControls(parseImages(hotel.imageUrl), currentSlide, goToSlide)
                  }
                  defaultControlsConfig={{
                    nextButtonClassName: "carousel__control carousel__control--next",
                    nextButtonText: " ",
                    prevButtonClassName: "carousel__control carousel__control--prev",
                    prevButtonText: " ",
                  }}
                >
                  {parseImages(hotel.imageUrl).map((image, i) => (
                    <img
                      key={i}
                      src={image}
                      alt="Hotel"
                      onError={({ currentTarget }) => {
                        currentTarget.src = currentTarget.src.includes("giata/original")
                          ? getPlaceHolderImage()
                          : image.replace("giata/bigger", "giata/original").replace("giata/xl", "giata/original");
                      }}
                    />
                  ))}
                </Carousel>
              ) : (
                <img
                  src={first(parseImages(hotel.imageUrl))}
                  alt={hotel.name}
                  className="flyin__header-image"
                  onError={({ currentTarget }) => {
                    currentTarget.src = currentTarget.src.includes("giata/original")
                      ? getPlaceHolderImage()
                      : first(parseImages(hotel.imageUrl))
                      ? first(parseImages(hotel.imageUrl))!.replace("giata/xl", "giata/original").replace("giata/bigger", "giata/original")
                      : getPlaceHolderImage();
                  }}
                />
              )}
              <div className="flyin__configure">
                <div className="flyin__configure-header">
                  <ScoreHeading hotel={hotel} />
                  <div className="pricing">
                    {calcPriceDifference() != 0 ? (
                      <>
                        {calcPriceDifference() > 0 ? <>+</> : <>-</>}
                        <span className="pricing__price">
                          &euro; &nbsp;
                          {((calcPriceDifference() < 0 ? calcPriceDifference() * -1 : calcPriceDifference()) / numberOfPax).toFixed(2)}
                        </span>
                        p.p.
                      </>
                    ) : (
                      translate(translations, language, "NO_EXTRA_COST")
                    )}
                  </div>
                </div>
                {flyInFacilities && flyInFacilities.length > 1 && (
                  <div className="flyin__facility">
                    <div className="fontawesome-list">
                      <ul>
                        {flyInFacilities.map((facility, i) => (
                          <li key={i}>
                            <i className={parse(facility.content?.general?.iconFontAwesome)}></i>
                            {facility.content?.general?.title}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
                <div className="flyin__configure-body">
                  <form id="form-configure-accommodation" className="form form--inline">
                    <div className="form__region">
                      <div className="form__row">
                        {localBookingPackageOption?.requestRooms.map((rr, i) => (
                          <div key={i} className="form__group">
                            <h5 className="flyin__configure-heading">
                              {translate(translations, language, "ROOM")} {i + 1}:
                            </h5>
                            <div className="select-themed">
                              <div className="select-themed__select">
                                {hotel.rooms.filter((r) => r.roomIndex === i + 1).length > 1 ? (
                                  <select
                                    disabled={hotel.rooms.filter((r) => r.roomIndex === i + 1).length <= 1}
                                    value={getSelectedValue(hotel, i)}
                                    onChange={(event) => changeSelectedRoom(event, i)}
                                  >
                                    {hotel.rooms
                                      .filter((r) => r.roomIndex === i + 1 && r.regimeName == regime)
                                      .map((room, hotelRoomIndex) => (
                                        <option key={hotelRoomIndex} value={room.accommodationName}>
                                          {mapRoom(room.accommodationName!)}{" "}
                                          {getPriceDifference(i, room.accommodationName, getSelectedValue(hotel, i))}
                                        </option>
                                      ))}
                                  </select>
                                ) : (
                                  <span className="flyin__configure__label">{getSelectedValue(hotel, i)}</span>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="form__row">
                        <div className="form__group">
                          <h5 className="flyin__configure-heading">{translate(translations, language, "REGIME")}:</h5>
                          {possibleRegimes.length > 1 ? (
                            <div className="select-themed">
                              <div className="select-themed__select">
                                <select
                                  disabled={possibleRegimes.length <= 1}
                                  value={getSelectedRegimeValue(hotel)}
                                  onChange={(event) => changeSelectedRegime(event)}
                                >
                                  {possibleRegimes?.map((regime, i) => (
                                    <option key={i} value={regime}>
                                      {mapRegime(regime)} {getRegimePriceDifference(regime)}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          ) : (
                            <span className="flyin__configure__label">{getSelectedRegimeValue(hotel)}</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="flyin__body" dangerouslySetInnerHTML={{ __html: hotel.description }}></div>
            <div className="flyin__footer">
              <form id="form-configure-accommodation-submit" className="form form--inline">
                <div className="form__group form__group--submit">
                  {hotelInPackage == selectedHotel ? (
                    <button type="button" className="cta" title={translate(translations, language, "APPLY")} onClick={() => applyChanges()}>
                      {translate(translations, language, "APPLY")}
                    </button>
                  ) : (
                    <button type="button" className="cta" title={translate(translations, language, "SELECT")} onClick={() => applyChanges()}>
                      {translate(translations, language, "SELECT")}
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Flyin;
