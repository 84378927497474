import React, { useContext, useEffect, useState } from "react";
import GlobalContext, { GlobalContextType } from "../../contexts/global-provider";
import translate from "../../utils/translate";
import translations from "./translations.json";
import BookingContext, { BookingContextType } from "../../contexts/booking-provider";
import { Image, Maybe, TideItemForCategory, TideItemForEvenementLocatie } from "../../types";
import { getChildItemsWithTypename, getSpecificChildItemWithName, parse } from "../../utils/component";
import { isEmpty, orderBy } from "lodash";
import { addWidthParameter } from "../../utils/image-utils";
import { Ticket } from "../../site-types";
import TooltipModal from "../tooltips/tooltip-modal";

interface StadiumSelectorProps {
  venue?: TideItemForEvenementLocatie;
}

const StadiumSelector: React.FC<StadiumSelectorProps> = ({ venue }) => {
  const { language } = useContext<GlobalContextType>(GlobalContext);
  const { bookingPackage, selectTickets, requestRooms, tickets, selectedTicket } = useContext<BookingContextType>(BookingContext);

  const [isbusy, setIsBusy] = useState(true);
  const [ogSVGString, setOgSVGString] = useState("");
  const [svgString, setSvgString] = useState("");
  const [category, setCategory] = useState<TideItemForCategory | undefined>(undefined);
  const [floorPlan, setFloorPlan] = useState<Maybe<Image> | undefined>(venue?.content?.general?.floorPlan);

  // Popper
  const [hovered, setHovered] = useState("");

  const handleTooltipTrigger = (productCode: string) => {
    setHovered(productCode);
  };

  const findDescription = (ticket: Ticket) => {
    let popperCategory = findCategory(ticket);
    if (popperCategory) {
      if (!isEmpty(popperCategory.content?.general?.descriptionVbr)) {
        return popperCategory.content?.general?.descriptionVbr;
      }
      if (!isEmpty(popperCategory.content?.general?.descriptionNl)) {
        return popperCategory.content?.general?.descriptionNl;
      }
      if (!isEmpty(popperCategory.content?.general?.descriptionEng)) {
        return popperCategory.content?.general?.descriptionEng;
      }
    }

    return null;
  };
  // End Popper

  const findCategory = (ticket: Ticket) => {
    const categories = getChildItemsWithTypename("TideItemForCategory", venue) as TideItemForCategory[];
    let result = categories?.find((c) => c.content?.general?.id != null && c.content?.general?.id === ticket.externalAccommodationCode);
    if (result) {
      return result;
    }

    result = categories?.find((c) => c.content?.general?.id != null && c.content?.general?.id === ticket.accommodationCode);
    if (result) {
      return result;
    }

    return getSpecificChildItemWithName(ticket.accommodationName, venue) as TideItemForCategory;
  };

  const handleSelectTickets = (ticket: Ticket) => {
    if (ticket != selectedTicket) {
      selectTickets(ticket, bookingPackage, true);
    }
    setCategory(findCategory(ticket));
  };

  const numberOfPax = requestRooms.flatMap((r) => r.pax).length;

  useEffect(() => {
    if (venue?.content?.general?.id?.endsWith("_vnx")) {
      fetch(`https://cdn.xs2event.com/venues/${venue?.content?.general?.id}.svg`)
        .then(async (response) => {
          if (response.ok) {
            setOgSVGString(await response!.text());
          }
          setIsBusy(false);
        })
        .catch((err) => {
          setIsBusy(false);
        });
    } else {
      setIsBusy(false);
    }
  }, []);

  useEffect(() => {
    setFloorPlan(category?.content?.general?.floorPlan || venue?.content?.general?.floorPlan);
    let categoryId: string | undefined = undefined;
    if (category?.content?.general?.id) {
      categoryId = category?.content?.general?.id;
    }
    if (ogSVGString != "") {
      if (categoryId && categoryId !== "0") {
        var selectedClass = " selected-seat ";
        var searchTerm = `grandstand ${categoryId}`;
        let position = 1;
        let indexes: number[] = [];
        while ((position = ogSVGString.indexOf(searchTerm, position + 1)) !== -1) {
          indexes.push(position);
        }
        if (isEmpty(indexes)) {
          var searchTerm = `${categoryId}`;
          while ((position = ogSVGString.indexOf(searchTerm, position + 1)) !== -1) {
            indexes.push(position);
          }
        }

        indexes = orderBy(indexes, [], ["desc"]);
        let output = ogSVGString;
        indexes.map((index) => {
          output = [output.slice(0, index), selectedClass, output.slice(index)].join("");
        });
        setSvgString(output);
      } else {
        setSvgString(ogSVGString);
      }
    }
  }, [category, ogSVGString]);

  useEffect(() => {
    if (selectedTicket) handleSelectTickets(selectedTicket);
  }, [selectedTicket]);

  return (
    <div className="stadium-selector">
      <h4 className="stadium-selector__heading">{translate(translations, language, "CHOOSE_TICKET")}</h4>
      <div className="stadium-selector__wrapper">
        {!isbusy && (
          <>
            {floorPlan?.url ? (
              <div className="stadium-selector__image">
                <img className="tickets-extras__image" src={addWidthParameter(floorPlan?.url, 450)} alt={parse(floorPlan?.altText)} />
              </div>
            ) : (
              ogSVGString &&
              ogSVGString != "" && (
                <div
                  className="stadium-selector__icon"
                  dangerouslySetInnerHTML={{ __html: svgString && svgString != "" ? svgString : ogSVGString }}
                />
              )
            )}
          </>
        )}
        <div className="stadium-selector__radiobutton-list">
          <div className="radiobutton-list radiobutton-list--toggles">
            {selectedTicket &&
              tickets &&
              tickets.map((ro, i) => (
                <div key={i} className="radiobutton__wrapper">
                  <label className={`radiobutton ${ro.isSelected ? "radiobutton--active" : ""}`}>
                    <input
                      type="radio"
                      className="radiobutton__input"
                      name="stadium-ticket"
                      defaultChecked={ro.isSelected}
                      onChange={(e) => handleSelectTickets(ro)}
                    />
                    <span className="radiobutton__text">
                      {ro.accommodationName}

                      {(selectedTicket?.price as number) - ro.price != 0.0 ? (
                        ((ro.price - selectedTicket?.price) as number) > 0.0 ? (
                          <>
                            <span className="pricing pricing--increase">
                              + &euro; &nbsp;{((ro.price - selectedTicket.price) / numberOfPax).toFixed(2)} p.p.
                            </span>
                          </>
                        ) : (
                          <>
                            <span className="pricing pricing--decrease">
                              - &euro; &nbsp;{(((ro.price - selectedTicket.price) / numberOfPax) * -1).toFixed(2)} p.p.
                            </span>
                          </>
                        )
                      ) : (
                        <></>
                      )}
                    </span>
                  </label>
                  {findDescription(ro) && (
                    <TooltipModal
                      handleHover={handleTooltipTrigger}
                      placement={"bottom"}
                      hovered={hovered}
                      suffix=""
                      title={ro.accommodationName}
                      hideTitle={true}
                      text={parse(findDescription(ro))}
                      Offset={[10, 15]}
                      extraClass="tooltip-modal--downwards"
                    ></TooltipModal>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StadiumSelector;
