import { Hotel } from "../site-types";

interface IndexMap {
  [key: number]: Set<string>;
}

export const hasMultipleAccommodationOptions = (hotel: Hotel) => {
  const indexMap: IndexMap = {};
  hotel.rooms.map(({ roomIndex, accommodationName }) => {
    if (!indexMap[roomIndex]) {
      indexMap[roomIndex] = new Set();
    }
    indexMap[roomIndex].add(accommodationName);
  });

  return Object.values(indexMap).some((accommodationName) => accommodationName.size > 1);
};

export const fetchRegimes = (hotel: Hotel) => {
  const uniqueRegimes: Set<string> = new Set();
  hotel.rooms.map(({ regimeName }) => uniqueRegimes.add(regimeName));
  return Array.from(uniqueRegimes);
};