import React, { useContext } from "react";
import Accommodations from "../../accommodations";
import { TideItemForEvenementLocatie } from "../../../types";

interface BookingStep4Props {
  website?: string;
  venue?: TideItemForEvenementLocatie;
}

const BookingStep4: React.FC<BookingStep4Props> = ({ website, venue }) => {
  return (
    <div className="booking__form-panel">
      <div className="booking__region">
        <div className="booking__card booking__card--transparent">
          <Accommodations venue={venue} />
        </div>
      </div>
    </div>
  );
};

export default BookingStep4;
