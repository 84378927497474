import React, { useContext } from "react";
import ConfigureRooms from "../../configure-rooms";
import GlobalContext, { GlobalContextType } from "../../../contexts/global-provider";
import translate from "../../../utils/translate";
import translations from "../translations.json";
interface BookingStep1Props {
  adultsOnly?: boolean;
}

const BookingStep1: React.FC<BookingStep1Props> = ({ adultsOnly }) => {
  const { language } = useContext<GlobalContextType>(GlobalContext);

  return (
    <div className="booking__form-panel">
      <div className="booking__region">
        <div className="booking__card booking__card--transparent">
          <h2 className="booking__card-heading">{translate(translations, language, "TRAVEL_GROUP")}</h2>
          <ConfigureRooms adultsOnly={adultsOnly} />
        </div>
      </div>
    </div>
  );
};

export default BookingStep1;
