import { BookingPackagePax, BookingPackageRequestRoom } from "@qite/tide-client/build/types";
import { concat, find, first, isEmpty, isNumber, last, orderBy, remove } from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import BookingContext, { BookingContextType } from "../../contexts/booking-provider";
import GlobalContext, { GlobalContextType } from "../../contexts/global-provider";
import translate from "../../utils/translate";
import Icon from "../icon";
import Link from "../link";
import translations from "./translations.json";
import { nlBE } from "date-fns/locale";
import { differenceInCalendarDays, format } from "date-fns";

interface ConfigureRoomsProps {
  adultsOnly?: boolean;
}
interface RoomDistributionError {
  key: string;
  text: string;
}

const ConfigureRooms: React.FC<ConfigureRoomsProps> = ({ adultsOnly }) => {
  const { language } = useContext<GlobalContextType>(GlobalContext);
  const {
    bookingSearch,
    setReFetchPackageDetails,
    priceRecalculating,
    noPackageFound,
    noHotelFound,
    roomDistributionErrors,
    setRoomDistributionErrors,
    setPreNights,
    setPostNights,
    setCheckExternalAvailability,
    requestRooms,
    setRequestRooms,
    bookableDates,
    activeOutwardDate,
    setActiveOutwardDate,
    activeReturnDate,
    setActiveReturnDate,
    setIsDifferentDate,
    packageOnlyTicket,
    packageView,
    resetRooms,
    setResetRooms,
  } = useContext<BookingContextType>(BookingContext);

  const [requestNOfAdults, setRequestNOfAdults] = useState<number>(requestRooms.flatMap((r) => r.pax.filter((p) => p.age! > 12)).length ?? 2);
  const [requestNOfChildren, setRequestNOfChildren] = useState<number>(
    requestRooms.flatMap((r) => r.pax.filter((p) => p.age! <= 12 || p.age == undefined)).length ?? 0
  );

  const [selectedRooms, setSelectedRooms] = useState<BookingPackageRequestRoom[]>(requestRooms);
  const [isEdited, setIsEdited] = useState<boolean>(false);

  const lastRoomRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (noHotelFound) {
      setRoomDistributionErrors([
        {
          key: "noAvailability",
          text: "Voor dit arrangement is het niet mogelijk een 3 persoonskamer te boeken. Je dient jouw reisgezelschap te verdelen over meerdere 2- en 1 persoonskamers.",
        },
      ]);
    } else if (noPackageFound) {
      setRoomDistributionErrors([{ key: "noAvailability", text: "Geen pakket gevonden voor dit reisgezelschap en datum" }]);
    } else {
      setRoomDistributionErrors(roomDistributionErrors.filter((x) => x.key != "noAvailability"));
    }
  }, [noPackageFound, noHotelFound, priceRecalculating]);

  useEffect(() => {
    if (resetRooms) {
      setIsEdited(true);
      setRequestNOfAdults(requestRooms.flatMap((r) => r.pax.filter((p) => p.age! > 12)).length ?? 2);
      setRequestNOfChildren(requestRooms.flatMap((r) => r.pax.filter((p) => p.age! <= 12 || p.age == undefined)).length ?? 0);
      setSelectedRooms(requestRooms);
      setResetRooms(false);
    }
  }, [resetRooms]);

  useEffect(() => {
    let numberOfAdultsInPackage = requestRooms.flatMap((r) => r.pax.filter((p) => p.age! > 12)).length ?? 2;
    let numberOfChildrenInPackage = requestRooms.flatMap((r) => r.pax.filter((p) => p.age! <= 12 || p.age == undefined)).length ?? 0;
    let numberOfAdultsInSelectedRooms = selectedRooms.flatMap((r) => r.pax.filter((p) => p.age! > 12)).length;
    let numberOfChildrenInSelectedRooms = selectedRooms.flatMap((r) => r.pax.filter((p) => p.age! <= 12 || p.age == undefined)).length;

    let errors = [] as RoomDistributionError[];
    if (requestNOfAdults + requestNOfChildren > 8) {
      errors.push({ key: "maxPax", text: `Je kan maximum voor 8 personen boeken. Neem contact op voor een reis op maat/groepsreis.` });
    }
    if (requestNOfAdults != numberOfAdultsInSelectedRooms) {
      errors.push({
        key: "adultsError",
        text: `De kamerverdeling is niet correct. Er zijn ${numberOfAdultsInPackage} volwassenen geselecteerd en er zijn ${numberOfAdultsInSelectedRooms} volwassenen ingedeeld in kamers.`,
      });
    }
    if (requestNOfChildren != numberOfChildrenInSelectedRooms) {
      errors.push({
        key: "childrenError",
        text: `De kamerverdeling is niet correct. Er zijn ${numberOfChildrenInPackage} kinderen geselecteerd en er zijn ${numberOfChildrenInSelectedRooms} kinderen ingedeeld in kamers.`,
      });
    }
    if (selectedRooms.filter((r) => r.pax.filter((p) => p.age! <= 12).length > 0 && r.pax.filter((p) => p.age! > 12).length == 0).length > 0) {
      errors.push({ key: "noAdults", text: "Er zijn één of meerdere kamers aanwezig zonder volwassenen." });
    }
    if (selectedRooms.filter((r) => r.pax.length == 0).length > 0) {
      errors.push({ key: "noPax", text: "Er zijn één of meerdere kamers aanwezig zonder reizigers." });
    }
    if (selectedRooms.flatMap((rr) => rr.pax).filter((p) => p.age == null || p.age == undefined).length > 0) {
      errors.push({ key: "PaxAge", text: "Gelieve de leeftijd van de kinderen in te vullen." });
    }

    if (isEdited && isEmpty(errors)) {
      setRequestRooms(selectedRooms);
      setCheckExternalAvailability(true);
      setReFetchPackageDetails(true);
    }

    if (selectedRooms.filter((r) => r.pax.length == 1).length > 0) {
      errors.push({ key: "singleRooms", text: "Je hebt één of meerdere Single rooms geselecteerd. Hiervoor geldt een toeslag." });
    }

    setRoomDistributionErrors(errors);
    setIsEdited(false);
  }, [selectedRooms]);

  const reconfigureRoomDistribution = (adults: number, children: number) => {
    let rooms = [] as BookingPackageRequestRoom[];
    let roomIndex = 1;

    if (adults >= 3) {
      do {
        // create room with 3 adults
        let room = { index: roomIndex, pax: [] as BookingPackagePax[] } as BookingPackageRequestRoom;
        for (let i = 1; i <= 3; i++) {
          room.pax.push({
            id: -1,
            dateOfBirth: "",
            age: 30,
            firstName: "",
            lastName: "",
            gender: 0,
            isMainBooker: false,
            initials: "",
            email: "",
            phone: "",
            mobilePhone: "",
            preferredLanguageId: 1,
          } as BookingPackagePax);
        }
        if (children >= 3) {
          for (let i = 1; i <= 3; i++) {
            room.pax.push({
              id: -i,
              dateOfBirth: "",
              age: 8,
              firstName: "",
              lastName: "",
              gender: 0,
              isMainBooker: false,
              initials: "",
              email: "",
              phone: "",
              mobilePhone: "",
              preferredLanguageId: 1,
            } as BookingPackagePax);
          }
          children -= 3;
        } else {
          for (let i = 1; i <= children; i++) {
            room.pax.push({
              id: -i,
              dateOfBirth: "",
              age: 8,
              firstName: "",
              lastName: "",
              gender: 0,
              isMainBooker: false,
              initials: "",
              email: "",
              phone: "",
              mobilePhone: "",
              preferredLanguageId: 1,
            } as BookingPackagePax);
          }
          children -= children;
        }
        rooms.push(room);
        roomIndex += 1;
        adults -= 3;
      } while (adults / 3 >= 1);
    }
    // check the rest of adults and put them in a room;
    if (adults > 0) {
      let room = { index: roomIndex, pax: [] as BookingPackagePax[] } as BookingPackageRequestRoom;
      for (let i = 1; i <= adults; i++) {
        room.pax.push({
          id: -1,
          dateOfBirth: "",
          age: 30,
          firstName: "",
          lastName: "",
          gender: 0,
          isMainBooker: false,
          initials: "",
          email: "",
          phone: "",
          mobilePhone: "",
          preferredLanguageId: 1,
        } as BookingPackagePax);
      }
      if (children > 0) {
        for (let i = 1; i <= (children <= 3 ? children : 3); i++) {
          room.pax.push({
            id: -i,
            dateOfBirth: "",
            age: 8,
            firstName: "",
            lastName: "",
            gender: 0,
            isMainBooker: false,
            initials: "",
            email: "",
            phone: "",
            mobilePhone: "",
            preferredLanguageId: 1,
          } as BookingPackagePax);
        }
        children = children <= 3 ? children - children : children - 3;
      }
      rooms.push(room);
      roomIndex += 1;
    }

    if (children > 0) {
      let room = { index: roomIndex, pax: [] as BookingPackagePax[] } as BookingPackageRequestRoom;
      for (let i = 1; i <= children; i++) {
        room.pax.push({
          id: -i,
          dateOfBirth: "",
          age: 8,
          firstName: "",
          lastName: "",
          gender: 0,
          isMainBooker: false,
          initials: "",
          email: "",
          phone: "",
          mobilePhone: "",
          preferredLanguageId: 1,
        } as BookingPackagePax);
      }
      children = children;
      rooms.push(room);
      roomIndex += 1;
    }

    rooms = optimize(rooms);
    setSelectedRooms(rooms);
  };
  const optimize = (rooms: BookingPackageRequestRoom[]) => {
    let roomsWithOnePaxOnly = rooms.filter((x) => x.pax.length == 1);
    if (roomsWithOnePaxOnly.length > 0) {
      roomsWithOnePaxOnly.forEach((room) => {
        let previousRoom = rooms[room.index - 2];
        if (previousRoom) {
          var paxToMove = remove(previousRoom.pax, function (n) {
            return n == first(previousRoom.pax);
          });
          if (first(paxToMove)) {
            room.pax.push(first(paxToMove)!);
          }
        }
      });
    }

    return setMainBooker(rooms);
  };

  const setMainBooker = (rooms: BookingPackageRequestRoom[]) => {
    rooms.forEach((room) => {
      room.pax = orderBy(room.pax, ["age"], ["desc"]);
    });

    const firstAdult = first(rooms.flatMap((r) => r.pax.filter((x) => x.age! > 12)));
    if (firstAdult) {
      firstAdult.isMainBooker = true;
    }

    return rooms;
  };

  const handleNumberOfAdults = (desiredNOfAdults: number) => {
    setIsEdited(true);
    reconfigureRoomDistribution(desiredNOfAdults, requestNOfChildren);
    setRequestNOfAdults(desiredNOfAdults);
  };

  const handleNumberOfChildren = (desiredNOfChildren: number) => {
    setIsEdited(true);
    reconfigureRoomDistribution(requestNOfAdults, desiredNOfChildren);
    setRequestNOfChildren(desiredNOfChildren);
  };

  const handleNumberOfAdultsInRoom = (roomIndex: number, desiredNOfAdults: number) => {
    var rooms = [...selectedRooms];
    var room = { ...rooms[roomIndex - 1] };

    var pax = [] as BookingPackagePax[];
    for (let i = 1; i <= desiredNOfAdults; i++) {
      pax.push({
        id: -1,
        dateOfBirth: "",
        age: 30,
        firstName: "",
        lastName: "",
        gender: 0,
        isMainBooker: false,
        initials: "",
        email: "",
        phone: "",
        mobilePhone: "",
        preferredLanguageId: 1,
      } as BookingPackagePax);
    }
    var children = room.pax.filter((p) => p.age! <= 12 || p.age == undefined);

    room.pax = concat(pax, children);
    rooms[roomIndex - 1] = room;

    setSelectedRooms(setMainBooker(rooms));
    setIsEdited(true);
  };

  const handleNumberOfChildrenInRoom = (roomIndex: number, desiredNOfChildren: number) => {
    var rooms = [...selectedRooms];
    var room = { ...rooms[roomIndex - 1] };

    var pax = [] as BookingPackagePax[];
    for (let i = 1; i <= desiredNOfChildren; i++) {
      pax.push({
        id: -i,
        dateOfBirth: "",
        age: 8,
        firstName: "",
        lastName: "",
        gender: 0,
        isMainBooker: false,
        initials: "",
        email: "",
        phone: "",
        mobilePhone: "",
        preferredLanguageId: 1,
      } as BookingPackagePax);
    }
    var adults = room.pax.filter((p) => p.age! > 12);

    room.pax = concat(pax, adults);
    rooms[roomIndex - 1] = room;

    setSelectedRooms(setMainBooker(rooms));
    setIsEdited(true);
  };

  const handleChildrensAge = (roomIndex: number, child: BookingPackagePax, age: number | string) => {
    var rooms = [...selectedRooms];
    var room = { ...rooms[roomIndex - 1] };

    var pax = room.pax.find((p) => p == child);
    if (pax) {
      pax.age = isNumber(age) ? age : undefined;
    }

    rooms[roomIndex - 1] = room;
    setSelectedRooms(rooms);
    setIsEdited(true);
  };

  const addRoom = () => {
    var rooms = [...selectedRooms];

    rooms.push({
      index: rooms.length + 1,
      pax: [],
    } as BookingPackageRequestRoom);

    setSelectedRooms(rooms);
    setIsEdited(true);

    if (typeof window !== "undefined" && lastRoomRef != null) {
      window.scrollTo({
        top: lastRoomRef.current?.offsetTop,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const removeRoom = (index: number) => {
    var rooms = [...selectedRooms];
    rooms = rooms.filter((r) => r.index != index);

    setSelectedRooms(rooms);
    setIsEdited(true);

    if (typeof window !== "undefined" && lastRoomRef != null) {
      window.scrollTo({
        top: lastRoomRef.current?.offsetTop,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const handlePreNightsOnClick = (date: string) => {
    const eventDate = bookingSearch?.allotment.startDate;
    if (eventDate) {
      const difference = differenceInCalendarDays(new Date(eventDate), new Date(date));
      setPreNights(difference);
      setActiveOutwardDate(date);
      setReFetchPackageDetails(true);
      setIsDifferentDate(true);
    }
  };
  const handlePostNightsOnClick = (date: string) => {
    const eventDate = bookingSearch?.allotment.endDate;
    if (eventDate) {
      const difference = differenceInCalendarDays(new Date(date), new Date(eventDate));
      setPostNights(difference);
      setActiveReturnDate(date);
      setReFetchPackageDetails(true);
      setIsDifferentDate(true);
    }
  };

  const hasError = (room: BookingPackageRequestRoom) => {
    if (room.pax.length == 0) return true;
    return false;
  };

  const getSelectAge = (age: number | undefined): number | string => {
    if (age) {
      return age;
    }
    return "";
  };

  return (
    <div className={`configure-rooms ${priceRecalculating !== "" ? "configure-rooms--disabled" : ""}`}>
      <div className="configure-rooms__row">
        <div className="configure-rooms__label">{translate(translations, language, "NUMBER_OF_PAX")}:</div>
        <div className="configure-rooms__form">
          <div className="decrement-increment">
            <div className="select-themed">
              <div className="select-themed__select">
                <select value={requestNOfAdults} onChange={(event) => handleNumberOfAdults(Number(find(event.target.options, "selected")?.value))}>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                  <option>7</option>
                  <option>8</option>
                </select>
              </div>
            </div>
            {translate(translations, language, "ADULTS")}
          </div>

          {!adultsOnly && (
            <div className="decrement-increment">
              <div className="select-themed">
                <div className="select-themed__select">
                  <select
                    value={requestNOfChildren}
                    onChange={(event) => handleNumberOfChildren(Number(find(event.target.options, "selected")?.value))}
                  >
                    <option>0</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                  </select>
                </div>
              </div>
              {translate(translations, language, "CHILDREN_UP_TO_12")}
            </div>
          )}
        </div>
      </div>
      {!packageOnlyTicket && (
        <>
          <div className="configure-rooms__row">
            <div className="configure-rooms__label">{translate(translations, language, "ROOM_LAYOUT")}:</div>
            <div className="configure-rooms__form">
              <div className="room-cards">
                {selectedRooms &&
                  selectedRooms.map((room, i) => (
                    <div
                      key={i}
                      className={`room-card ${hasError(room) ? "room-card--error" : ""}`}
                      ref={room == last(selectedRooms) ? lastRoomRef : null}
                    >
                      <div className="room-card__header">
                        <Icon name="bed-outline" />
                        {translate(translations, language, "ROOM")} {i + 1}
                        {room.index > 1 && (
                          <div className="room-card__actions">
                            <button type="button" onClick={() => removeRoom(room.index)} className="room-card__delete-button"></button>
                          </div>
                        )}
                      </div>
                      <div className="room-card__body">
                        <div className="room-card__persons">
                          <div className="decrement-increment">
                            <div className="select-themed">
                              <div className="select-themed__select">
                                <select
                                  value={room.pax.filter((p) => p.age! > 12).length}
                                  onChange={(event) => handleNumberOfAdultsInRoom(room.index, Number(find(event.target.options, "selected")?.value))}
                                >
                                  <option disabled>0</option>
                                  <option>1</option>
                                  <option>2</option>
                                  <option>3</option>
                                </select>
                              </div>
                            </div>
                            {translate(translations, language, "ADULTS")}
                          </div>
                          {!adultsOnly && (
                            <div className="decrement-increment">
                              <div className="select-themed">
                                <div className="select-themed__select">
                                  <select
                                    value={room.pax.filter((p) => p.age! <= 12 || p.age == undefined).length}
                                    onChange={(event) =>
                                      handleNumberOfChildrenInRoom(room.index, Number(find(event.target.options, "selected")?.value))
                                    }
                                  >
                                    <option>0</option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                  </select>
                                </div>
                              </div>
                              {translate(translations, language, "CHILDREN")}
                            </div>
                          )}
                        </div>
                        {room.pax.filter((p) => p.age! <= 12 || p.age == undefined).length > 0 && (
                          <div className="room-card__children">
                            <p className="room-card__children-label">{translate(translations, language, "CHILDRENS_AGE")}</p>
                            <div className="decrement-increment decrement-increment--narrow">
                              {room.pax
                                .filter((p) => p.age! <= 12 || p.age == undefined)
                                .map((child: BookingPackagePax, i: number) => (
                                  <div key={i} className="select-themed">
                                    <div className="select-themed__select">
                                      <select
                                        value={getSelectAge(child.age)}
                                        onChange={(event) =>
                                          handleChildrensAge(room.index, child, Number(find(event.target.options, "selected")?.value))
                                        }
                                      >
                                        <option value={""}>-</option>
                                        <option value={0}>0</option>
                                        <option value={1}>1</option>
                                        <option value={2}>2</option>
                                        <option value={3}>3</option>
                                        <option value={4}>4</option>
                                        <option value={5}>5</option>
                                        <option value={6}>6</option>
                                        <option value={7}>7</option>
                                        <option value={8}>8</option>
                                        <option value={9}>9</option>
                                        <option value={10}>10</option>
                                        <option value={11}>11</option>
                                      </select>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                <button type="button" className="cta cta--add--room" title="Kamer toevoegen" onClick={() => addRoom()}>
                  <Icon name="plus-circle"></Icon>
                  Kamer toevoegen
                </button>
              </div>
            </div>
          </div>

          {roomDistributionErrors && roomDistributionErrors.length > 0 && (
            <div className="configure-rooms__row">
              <div className="form">
                <div className="form__errors">
                  <h2 className="form__errors-heading">{translate(translations, language, "ATTENTION")}</h2>
                  {roomDistributionErrors.map((error, i) => (
                    <p key={i}>
                      {error.text}{" "}
                      {error.key == "maxPax" && (
                        <Link path="/groepsreizen" className="" title="Klik hier voor het contactformulier">
                          Klik hier voor het contactformulier.
                        </Link>
                      )}
                    </p>
                  ))}
                  <br />
                </div>
              </div>
            </div>
          )}
          {!packageView && (
            <>
              <div className="configure-rooms__row configure-rooms__row--border">
                <div className="configure-rooms__label">{translate(translations, language, "OUTWARD_DATE")}:</div>
                <div className="configure-rooms__form">
                  <div className="toggles-grid">
                    <div className="toggles">
                      <div className="toggles__container">
                        {bookableDates ? (
                          bookableDates.outward.map((date: string, i: number) => (
                            <button
                              key={i}
                              type="button"
                              className={`toggles__toggle ${date == activeOutwardDate ? "toggles__toggle--active" : ""}`}
                              title={format(new Date(date), "EEEEEE d MMM yyyy", { locale: nlBE })}
                              onClick={() => handlePreNightsOnClick(date)}
                            >
                              <h5 className="toggles__toggle-heading">{format(new Date(date), "EEEEEE d MMM yy", { locale: nlBE })}</h5>
                            </button>
                          ))
                        ) : (
                          <div className="preloader-spinner preloader-spinner--vertical-placement">
                            <div className="preloader__icon">
                              <Icon name="spinner" />
                            </div>
                            <div className="preloader__label">
                              <div dangerouslySetInnerHTML={{ __html: "Datums worden geladen" }}></div>
                              <div className="preloader__dots">
                                <div className="preloader__dot-1">.</div>
                                <div className="preloader__dot-2">.</div>
                                <div className="preloader__dot-3">.</div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="configure-rooms__row configure-rooms__row--border">
                <div className="configure-rooms__label">{translate(translations, language, "RETURN_DATE")}:</div>
                <div className="configure-rooms__form">
                  <div className="toggles-grid">
                    <div className="toggles">
                      <div className="toggles__container">
                        {bookableDates ? (
                          bookableDates.return.map((date: string, i: number) => (
                            <button
                              key={i}
                              type="button"
                              className={`toggles__toggle ${date == activeReturnDate ? "toggles__toggle--active" : ""}`}
                              title={format(new Date(date), "EEEEEE d MMM yyyy", { locale: nlBE })}
                              onClick={() => handlePostNightsOnClick(date)}
                            >
                              <h5 className="toggles__toggle-heading">{format(new Date(date), "EEEEEE d MMM yy", { locale: nlBE })}</h5>
                            </button>
                          ))
                        ) : (
                          <div className="preloader-spinner preloader-spinner--vertical-placement">
                            <div className="preloader__icon">
                              <Icon name="spinner" />
                            </div>
                            <div className="preloader__label">
                              <div dangerouslySetInnerHTML={{ __html: "Datums worden geladen" }}></div>
                              <div className="preloader__dots">
                                <div className="preloader__dot-1">.</div>
                                <div className="preloader__dot-2">.</div>
                                <div className="preloader__dot-3">.</div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ConfigureRooms;
