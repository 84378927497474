import { first, last, uniq } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import BookingContext, { BookingContextType } from "../../contexts/booking-provider";
import GlobalContext, { GlobalContextType } from "../../contexts/global-provider";
import { getDateOnlyString, ticksToDateTime } from "../../utils/date-utils";
import { addWidthParameter } from "../../utils/image-utils";
import translate from "../../utils/translate";
import Icon from "../icon";
import translations from "./translations.json";
import { CombinedFlight, Flight } from "../../site-types";

interface FlightsProps {}

const Flights: React.FC<FlightsProps> = () => {
  const [isExpanded, setIsExpanded] = useState<string[]>([]);
  const [departureAirports, setDepartureAirports] = useState<string[]>();
  const [activeAirport, setActiveAirport] = useState<string>("");

  const { language } = useContext<GlobalContextType>(GlobalContext);
  const { selectFlight, flightsLoaded, cachedFlightPool, requestRooms } = useContext<BookingContextType>(BookingContext);
  const numberOfPax = requestRooms.flatMap((r) => r.pax).length;

  useEffect(() => {
    const departureAirports = uniq(
      cachedFlightPool?.outwardFlights.filter((f) => f.flightMetaData).flatMap((f) => f.flightMetaData.flightLines[0]?.departureAirportDescription)
    );
    if (departureAirports) {
      setDepartureAirports(departureAirports);
      setActiveAirport(
        departureAirports.find(
          (d) => d == cachedFlightPool?.outwardFlights.find((f) => f.isSelected)?.flightMetaData.flightLines[0]?.departureAirportDescription
        ) || departureAirports[0]
      );
    }
  }, [flightsLoaded]);

  useEffect(() => {
    if (departureAirports && cachedFlightPool?.outwardFlights) {
      let initialExpandedFlights: string[] = [];

      for (const departureAirport of departureAirports) {
        const flightsForAirport = flights
          .filter((f) => f.outwardFlight.flightMetaData.flightLines[0].departureAirportDescription === departureAirport)
          .slice(0, 5);

        initialExpandedFlights = [
          ...initialExpandedFlights,
          ...flightsForAirport.map(
            (f) => `${f.outwardFlight.code}-${f.returnFlight.code}-${f.outwardFlight.externalGuid}-${f.returnFlight.externalGuid}`
          ),
        ];
      }

      setIsExpanded(initialExpandedFlights);
    }
  }, [departureAirports, cachedFlightPool]);

  let flights = [] as CombinedFlight[];
  const selectedOutwardFlight = cachedFlightPool?.outwardFlights.find((f) => f.isSelected);
  const selectedReturnFlight = cachedFlightPool?.returnFlights.find((f) => f.isSelected);

  if (cachedFlightPool?.outwardFlights) {
    for (const outwardFlight of cachedFlightPool?.outwardFlights) {
      let matchingReturnFlight = cachedFlightPool.returnFlights.find((rf) => rf.externalGuid == outwardFlight.externalGuid);
      if (matchingReturnFlight) {
        flights.push({
          outwardFlight: outwardFlight,
          returnFlight: matchingReturnFlight,
        });
      }
    }
  }

  const calcFlightDuration = (flight: Flight | undefined) => {
    if (flight) {
      const flightDuration = ticksToDateTime(flight.flightMetaData.durationInTicks);
      if (flightDuration) {
        return `${((flightDuration.getDate() - 1) * 24 + flightDuration.getHours()).toString(10).padStart(2, "0")}:${flightDuration
          .getMinutes()
          .toString(10)
          .padStart(2, "0")}`;
      }
    }
  };

  const priceDifference = (airport: string) => {
    if (flights) {
      const cheapestAirportFlight = first(
        flights.filter((f) => f.outwardFlight.flightMetaData.flightLines[0].departureAirportDescription == airport)
      );
      if (cheapestAirportFlight?.outwardFlight.price && cheapestAirportFlight?.returnFlight.price) {
        const price = cheapestAirportFlight.outwardFlight.price + cheapestAirportFlight.returnFlight.price;
        const currentPrice = selectedOutwardFlight?.price! + selectedReturnFlight?.price!;
        return price - currentPrice;
      }
    }
    return 0;
  };

  const numberOfFlightsInAirport = (airport: string) => {
    if (flights) {
      const numberOfFlights = flights.filter((f) => f.outwardFlight.flightMetaData.flightLines[0].departureAirportDescription == airport);
      return numberOfFlights.length;
    }
    return 0;
  };

  const selectNewAirport = (airport: string) => {
    if (flights) {
      const flightsForAirport = flights.filter(
        (x) => x.outwardFlight.flightMetaData && x.outwardFlight.flightMetaData.flightLines[0].departureAirportDescription == airport
      );
      const firstFlightForAirport = first(flightsForAirport);
      if (firstFlightForAirport) {
        selectFlight(firstFlightForAirport);
      }
    }
    setActiveAirport(airport);
  };

  const handleSelectFlight = (flight: CombinedFlight, index: number) => {
    selectFlight(flight);

    const flightIdentifier = `${flight.outwardFlight.externalGuid}-${flight.returnFlight.externalGuid}-${flight.outwardFlight.externalGuid}-${flight.returnFlight.externalGuid}`;
    if (!isExpanded.includes(flightIdentifier)) {
      setIsExpanded((prevExpanded) => [...prevExpanded, flightIdentifier]);
    }
  };

  const toggleExpanded = (flight: CombinedFlight) => {
    const flightIdentifier = `${flight.outwardFlight.code}-${flight.returnFlight.code}-${flight.outwardFlight.externalGuid}-${flight.returnFlight.externalGuid}`;
    setIsExpanded((prevExpanded) => {
      return prevExpanded.includes(flightIdentifier) ? prevExpanded.filter((item) => item !== flightIdentifier) : [...prevExpanded, flightIdentifier];
    }
    );
  };

  return (
    <>
      {flightsLoaded ? (
        <div className="flights-grouped">
          <div className="flights">
            <div className="flights__airports">
              <div className="toggles-grid">
                <div className="toggles">
                  <div className="toggles__container">
                    {departureAirports &&
                      departureAirports.map((airport, i) => (
                        <button
                          key={i}
                          onClick={() => selectNewAirport(airport)}
                          type="button"
                          className={`toggles__toggle ${airport == activeAirport ? "toggles__toggle--active" : ""}`}
                          title={airport}
                        >
                          <h5 className="toggles__toggle-heading">{airport}</h5>
                          {priceDifference(airport) != 0 &&
                            (priceDifference(airport) > 0 ? (
                              <div className="pricing pricing--increase">+ &euro; {(priceDifference(airport) / numberOfPax).toFixed(2)} p.p.</div>
                            ) : (
                              <div className="pricing pricing--decrease">
                                - &euro; {((priceDifference(airport) / numberOfPax) * -1).toFixed(2)} p.p.
                              </div>
                            ))}
                        </button>
                      ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="flights__columns">
              <div className="flights__column">
                <div className="flights__column-header">
                  <h2 className="flights__column-heading">
                    {numberOfFlightsInAirport(activeAirport)}{" "}
                    {numberOfFlightsInAirport(activeAirport) == 1
                      ? translate(translations, language, "FLIGHT_FOUND")
                      : translate(translations, language, "FLIGHTS_FOUND")}
                    :
                  </h2>
                </div>
                <div className="flights__cards">
                  {flights
                    .filter(
                      (x) =>
                        x.outwardFlight.flightMetaData && x.outwardFlight.flightMetaData.flightLines[0].departureAirportDescription == activeAirport
                    )
                    .map((flight, i) => {
                      const flightIdentifier = `${flight.outwardFlight.code}-${flight.returnFlight.code}-${flight.outwardFlight.externalGuid}-${flight.returnFlight.externalGuid}`;
                      return (
                        <div key={i} className={`flights__card ${flight.outwardFlight == selectedOutwardFlight ? "flights__card--active" : ""}`}>
                          <div
                            className={`flights__card-header ${isExpanded.includes(flightIdentifier) ||
                                (flight.outwardFlight == selectedOutwardFlight && flight.returnFlight == selectedReturnFlight)
                                ? "flights__card-header--expanded"
                                : ""
                              }`}
                            onClick={() => toggleExpanded(flight)}
                          >
                            <img
                              src={addWidthParameter(
                                `https://media${process.env.GATSBY_ACTIVE_ENV?.includes("production") ? "" : "-acceptance"
                                }.tidesoftware.be/media/2/Airlines/${last(flight.outwardFlight?.code.split("/"))}.png?quality=100`,
                                181
                              )}
                              alt={last(flight.outwardFlight?.code.split("/"))}
                              className="flights__airline-logo"
                            />
                            <i
                              className={`fa fa-chevron-down flights__dropdown ${isExpanded.includes(flightIdentifier) ||
                                  (flight.outwardFlight == selectedOutwardFlight && flight.returnFlight == selectedReturnFlight)
                                  ? "flights__dropdown--expanded"
                                  : ""
                                }`}
                            ></i>
                          </div>
                          <div
                            className={`flights__card-body ${isExpanded.includes(flightIdentifier) ||
                                (flight.outwardFlight == selectedOutwardFlight && flight.returnFlight == selectedReturnFlight)
                                ? "flights__card-body--expanded"
                                : ""
                              }`}
                          >
                            <div className="flights__flight-columns">
                              <div className="flights__flight-column">
                                <div className="flights__row">
                                  <h3 className="flights__flight-heading">Heenvlucht:</h3>
                                  <p className="flights__flight-date">{getDateOnlyString(flight.outwardFlight.startDateTime)}</p>
                                </div>

                                <div className="flights__timeline">
                                  <div className="timeline">
                                    <div className="timeline__start">
                                      <strong
                                        className={`timeline__departure ${last(flight.outwardFlight.flightMetaData?.flightLines)?.departureTime !=
                                            last(selectedOutwardFlight?.flightMetaData?.flightLines)?.departureTime
                                            ? "timeline__departure--highlight"
                                            : ""
                                          }`}
                                      >
                                        {first(flight.outwardFlight.flightMetaData?.flightLines)?.departureTime}{" "}
                                        {first(flight.outwardFlight.flightMetaData?.flightLines)?.departureAirport}
                                      </strong>
                                      <span className="flights__timezone">lokale tijd</span>
                                    </div>
                                    <div className="timeline__end">
                                      <strong
                                        className={`timeline__departure ${last(flight.outwardFlight.flightMetaData?.flightLines)?.arrivalTime !=
                                            last(selectedOutwardFlight?.flightMetaData?.flightLines)?.arrivalTime
                                            ? "timeline__departure--highlight"
                                            : ""
                                          }`}
                                      >
                                        {last(flight.outwardFlight.flightMetaData?.flightLines)?.arrivalTime}{" "}
                                        {last(flight.outwardFlight.flightMetaData?.flightLines)?.arrivalAirport}
                                      </strong>
                                      <span className="flights__timezone">lokale tijd</span>
                                    </div>
                                    <div className="flights__timeline__flight">
                                      {flight.outwardFlight.flightMetaData.flightLines.length > 1 ? (
                                        <div className="flights__timeline__flight__connecting">
                                          <span>{flight.outwardFlight.flightMetaData.flightLines.length} tussenstop(s) </span>
                                          <div className="tooltips__tooltip">
                                            <Icon name="info-circle" />
                                            <div className="tooltips__label tooltips__label--down">
                                              <div className="flights__timeline__flight__pop-up">
                                                <div className="flight-legs-pop-up__title">
                                                  {translate(translations, language, "STOPOVER", {
                                                    departure: first(flight.outwardFlight.flightMetaData.flightLines)?.departureAirport,
                                                    arrival: last(flight.outwardFlight.flightMetaData.flightLines)?.arrivalAirport,
                                                    stops: flight.outwardFlight.flightMetaData.flightLines.length,
                                                  })}
                                                </div>
                                                <div className="flights__timeline__flight__pop-up__legs">
                                                  {flight.outwardFlight.flightMetaData.flightLines.map((leg, index) => (
                                                    <div key={index} className="flights__timeline__flight__pop-up__leg">
                                                      <div className="flights__timeline__flight__pop-up__leg__departure">
                                                        <span>
                                                          {leg.departureTime} {leg.departureAirport ?? leg.departureAirportDescription}
                                                        </span>
                                                      </div>

                                                      <div className="flights__timeline__flight__pop-up__leg__spacer"></div>

                                                      <div className="flights__timeline__flight__pop-up__leg__arrival">
                                                        <span>
                                                          {leg.arrivalTime} {leg.arrivalAirport ?? leg.arrivalAirportDescription}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  ))}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <p>directe vlucht</p>
                                      )}
                                      <p>{calcFlightDuration(flight.outwardFlight)}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="flights__flight-column">
                                <div className="flights__row">
                                  <h3 className="flights__flight-heading">Terugvlucht:</h3>
                                  <p className="flights__flight-date">{getDateOnlyString(flight.returnFlight.startDateTime)}</p>
                                </div>

                                <div className="flights__timeline">
                                  <div className="timeline">
                                    <div className="timeline__start">
                                      <strong
                                        className={`timeline__departure ${last(flight.returnFlight.flightMetaData?.flightLines)?.departureTime !=
                                            last(selectedReturnFlight?.flightMetaData?.flightLines)?.departureTime
                                            ? "timeline__departure--highlight"
                                            : ""
                                          }`}
                                      >
                                        {first(flight.returnFlight.flightMetaData?.flightLines)?.departureTime}{" "}
                                        {first(flight.returnFlight.flightMetaData?.flightLines)?.departureAirport}
                                      </strong>
                                      <span className="flights__timezone">lokale tijd</span>
                                    </div>
                                    <div className="timeline__end">
                                      <strong
                                        className={`timeline__departure ${last(flight.returnFlight.flightMetaData?.flightLines)?.arrivalTime !=
                                            last(selectedReturnFlight?.flightMetaData?.flightLines)?.arrivalTime
                                            ? "timeline__departure--highlight"
                                            : ""
                                          }`}
                                      >
                                        {last(flight.returnFlight.flightMetaData?.flightLines)?.arrivalTime}{" "}
                                        {last(flight.returnFlight.flightMetaData?.flightLines)?.arrivalAirport}
                                      </strong>
                                      <span className="flights__timezone">lokale tijd</span>
                                    </div>
                                    <div className="flights__timeline__flight">
                                      {flight.returnFlight.flightMetaData.flightLines.length > 1 ? (
                                        <div className="flights__timeline__flight__connecting">
                                          <span>{flight.returnFlight.flightMetaData.flightLines.length} tussenstop(s) </span>
                                          <div className="tooltips__tooltip">
                                            <Icon name="info-circle" />
                                            <div className="tooltips__label tooltips__label--down">
                                              <div className="flights__timeline__flight__pop-up">
                                                <div className="flight-legs-pop-up__title">
                                                  {translate(translations, language, "STOPOVER", {
                                                    departure: first(flight.returnFlight.flightMetaData.flightLines)?.departureAirport,
                                                    arrival: last(flight.returnFlight.flightMetaData.flightLines)?.arrivalAirport,
                                                    stops: flight.returnFlight.flightMetaData.flightLines.length,
                                                  })}
                                                </div>
                                                <div className="flights__timeline__flight__pop-up__legs">
                                                  {flight.returnFlight.flightMetaData.flightLines.map((leg, index) => (
                                                    <div key={index} className="flights__timeline__flight__pop-up__leg">
                                                      <div className="flights__timeline__flight__pop-up__leg__departure">
                                                        <span>
                                                          {leg.departureTime} {leg.departureAirport ?? leg.departureAirportDescription}
                                                        </span>
                                                      </div>

                                                      <div className="flights__timeline__flight__pop-up__leg__spacer"></div>
                                                      <div className="flights__timeline__flight__pop-up__leg__spacer"></div>

                                                      <div className="flights__timeline__flight__pop-up__leg__arrival">
                                                        <span>
                                                          {leg.arrivalTime} {leg.arrivalAirport ?? leg.arrivalAirportDescription}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  ))}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <p>directe vlucht</p>
                                      )}
                                      <p>{calcFlightDuration(flight.returnFlight)}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flights__card-footer">
                            {flight.outwardFlight.price +
                              flight.returnFlight.price -
                              (selectedOutwardFlight?.price! + selectedReturnFlight?.price!) !=
                              0 ? (
                              flight.outwardFlight.price +
                                flight.returnFlight.price -
                                (selectedOutwardFlight?.price! + selectedReturnFlight?.price!) >
                                0 ? (
                                <div className="pricing pricing--increase">
                                  + &euro;{" "}
                                  {(
                                    (flight.outwardFlight.price +
                                      flight.returnFlight.price -
                                      (selectedOutwardFlight?.price! + selectedReturnFlight?.price!)) /
                                    numberOfPax
                                  ).toFixed(2)}{" "}
                                  p.p.
                                </div>
                              ) : (
                                <div className="pricing pricing--decrease">
                                  - &euro;{" "}
                                  {(
                                    ((flight.outwardFlight.price +
                                      flight.returnFlight.price -
                                      (selectedOutwardFlight?.price! + selectedReturnFlight?.price!)) /
                                      numberOfPax) *
                                    -1
                                  ).toFixed(2)}{" "}
                                  p.p.
                                </div>
                              )
                            ) : (
                              <div className="pricing">{translate(translations, language, "NO_EXTRA_COST")}</div>
                            )}
                            <button type="button" className="flights__card-select" onClick={() => handleSelectFlight(flight, i)}>
                              {flight.outwardFlight == selectedOutwardFlight
                                ? translate(translations, language, "SELECTED")
                                : translate(translations, language, "SELECT")}
                            </button>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="preloader-spinner preloader-spinner--vertical-placement">
            <div className="preloader__icon">
              <Icon name="spinner" />
            </div>
            <div className="preloader__label">
              Vluchten worden geladen
              <div className="preloader__dots">
                <div className="preloader__dot-1">.</div>
                <div className="preloader__dot-2">.</div>
                <div className="preloader__dot-3">.</div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Flights;
