import React, { useContext } from "react";
import Icon from "../../icon";
import { navigate } from "gatsby";
import BookingContext, { BookingContextType } from "../../../contexts/booking-provider";

interface BookingStep7Props {
  bookingNr?: string;
  bookingAllotmentName?: string;
}

const BookingStep7: React.FC<BookingStep7Props> = ({ bookingNr, bookingAllotmentName }) => {
  const { bookingPackageDossier } = useContext<BookingContextType>(BookingContext);

  const HandleNavigation: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    navigate(`/`);
  };
  const HandleRefresh: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    if (typeof window !== "undefined") {
      window.location.reload();
    }
  };

  return (
    <div className="booking__form-panel">
      <div className="booking__region">
        <div className="booking__card">
          <div className="payment-message">
            {process.env.WEBSITE === "Sportreizen" ? <Icon name="booking-check" /> : <Icon name="booking-check-voetbalreizen" />}
            <div className="heading-seperator">
              <h1 className="payment-message__heading">Bedankt voor je boeking!</h1>
            </div>
            <p> Binnen 3 werkdagen ontvang je een bevestiging van deze boeking per e-mail.</p>
            <button type="button" title="Naar de homepage" className="cta" onClick={HandleNavigation}>
              Naar de homepage
            </button>
          </div>
          <div className="payment-message">
            <Icon name="booking-error-voetbalreizen" />
            <div className="heading-seperator">
              <h1 className="payment-message__heading">Er liep iets mis.</h1>
            </div>
            <p>
              Tijdens het bevestigen van uw reis is er iets misgelopen.
              <br />
              Klik hieronder om nog eens te proberen.
            </p>
            <p>
              Blijft het misgaan?{" "}
              <a href="mailto:info@sportreizen.be?subject=Sportreizen%20-%20boeking%20foutcode" title="info@sportreizen.be">
                Laat het ons dan zeker weten.
              </a>
            </p>
            <button type="button" title="Opnieuw betalen" className="cta" onClick={HandleRefresh}>
              Probeer opnieuw te boeken
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingStep7;
